export interface IEnvironment {
  production: boolean;
  database: any;
  primeApiUrl: string;
  pubQueueApiUrl: string;
  adminAppUrl: string;
  posUrl: string;
  kdsUrl: string;
}
export var appVersion = "2.63.82";


export class Coupon {
  code: string
  key?: string
  validFor: DeliveryPlacesEnum
  discountPercent: number
}
export class DeliveryPlacesEnum {
  "0": boolean //Takeaway
  "1": boolean //Bordsservering
  "-1": boolean //Hämtas vid baren
  "-2": boolean //Utkörning
  "-3": boolean //Utkörning till leveransplats
}
export class Statistics {
  dayNet = 0;
  dayVat = 0;
  day = 0;
  totalAmount = 0;
  totalNet = 0;
  totalVat = 0;
  totalReceipts = 0;
  totalReceipts_web = 0;
  totalReceipts_app = 0;
  totalReceipts_pos = 0;
  totalReceipts_minimarket = 0;
  highestPurchase = 0;
  highestDiscount = 0;
  totalItemsQuantitySold = 0;
  largestOrder = 0;
  medianReceipt = 0;
  products = null;
  highestPurchaseReceiptNo = ""
  highestDiscountReceiptNo = ""
  largestOrderReceiptNo = "";
  totalReceipts_app_card = new StatisticsLine();
  totalReceipts_app_swish = new StatisticsLine();
  totalReceipts_app_vipps = new StatisticsLine();
  totalReceipts_app_mobilewallet = new StatisticsLine();
  totalReceipts_app_invoice = new StatisticsLine();
  totalReceipts_app_bonuspoints = new StatisticsLine();
  totalReceipts_web_card = new StatisticsLine();
  totalReceipts_web_swish = new StatisticsLine();
  totalReceipts_web_vipps = new StatisticsLine();
  totalReceipts_pos_card = new StatisticsLine();
  totalReceipts_pos_swish = new StatisticsLine();
  totalReceipts_pos_vipps = new StatisticsLine();
  totalReceipts_unknown = new StatisticsLine();
  calculation: any = {};
  constructor(statObject?: Partial<Statistics>) {
    if (statObject) {
      for (const key in statObject) {
        if (Object.prototype.hasOwnProperty.call(statObject, key)) {
          this[key] = statObject[key];
        }
      }
    }
  }
  getChannels() {
    return Object.keys(Channel).filter((v) => isNaN(Number(v)));
  }
  getPaymentOptions() {
    return Object.keys(PaymentOption).filter((v) => isNaN(Number(v)));
  }
  getTotalsForChannel(option: Channel): StatisticsLine {
    let paymentOptions = Object.keys(PaymentOption).filter((v) => isNaN(Number(v)));
    let channel = Channel[option];
    let totals = new StatisticsLine();
    for (let index = 0; index < paymentOptions.length; index++) {
      const paymentOption = paymentOptions[index];
      let path = `totalReceipts_${channel}_${paymentOption}`;
      totals.amount += this[path] ? this[path].amount : 0;
      totals.quantity += this[path] ? this[path].quantity : 0;
    }
    return totals;
  }
  getTotalsForPaymentOption(option: PaymentOption): StatisticsLine {
    let channels = Object.keys(Channel).filter((v) => isNaN(Number(v)));
    let paymentOption = PaymentOption[option];
    let totals = new StatisticsLine();
    for (let index = 0; index < channels.length; index++) {
      const channel = channels[index];
      let path = `totalReceipts_${channel}_${paymentOption}`;
      if (!this[path])
        this[path] = new StatisticsLine();
      totals.amount += this[path].amount;
      totals.quantity += this[path].quantity;
    }
    return totals;
  }
  calculatePaymentTotals() {
    let channels = Object.keys(Channel).filter((v) => isNaN(Number(v)));
    let paymentOptions = Object.keys(PaymentOption).filter((v) => isNaN(Number(v)));
    let obj = {};
    try {

      for (let index = 0; index < channels.length; index++) {
        const channel = channels[index];
        obj[channel] = { total: this.getTotalsForChannel(Channel[channel]) };
        for (let j = 0; j < paymentOptions.length; j++) {
          const paymentOption = paymentOptions[j];
          let path = `totalReceipts_${channel}_${paymentOption}`;
          if (!this[path])
            this[path] = new StatisticsLine();
          obj[channel][paymentOption] = this[path];
        }
      }
      for (let index = 0; index < paymentOptions.length; index++) {
        const paymentOption = paymentOptions[index];
        obj[paymentOption] = { total: this.getTotalsForPaymentOption(PaymentOption[paymentOption]) };
      }
      this.calculation = obj;
      return obj;
    } catch (error) {
      console.error(error.message);
    }
  }

}
export class StatisticsLine {
  quantity = 0;
  amount = 0;
}
export enum PaymentOption {
  card,
  swish,
  vipps,
  mobilewallet,
  invoice,
  bonuspoints
}
export enum Channel {
  app,
  pos,
  web,
  minimarket

}
