import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LanguagePipe } from "../../../pipes/language.pipe";
import { ArticleAccounting } from "../../../services/article-accounting.service";
import { LanguageProvider } from "../../../services/language/language.provider";
@Component({
    templateUrl: "exception.component.html",
    styleUrls: ["exception.component.scss"]
})

export class ExceptiopnComponent {
    @Input() exception: { vatInfo: { articleAccountId: string }, id: string, reason: string};
    @Input() articleAccountings: ArticleAccounting[]
    editedException: { vatInfo: { articleAccountId: string }, id: string, reason: string};


    constructor(private modalController: ModalController, public languageProvider:LanguageProvider
    ) {

    }

    ngOnInit() {
        this.editedException = JSON.parse(JSON.stringify(this.exception));
    }

    confirm() {
        this.modalController.dismiss(this.editedException, "confirm");
    }

    dismiss() {
        this.modalController.dismiss(null, "cancel");
    }

    onSelectedArticleAccount($event){
        this.editedException.vatInfo.articleAccountId = $event.detail.value;
    }
}