
export class Addons {
  constructor(private data, private saveCallback: (addon) => void) {
  }

  getAddon(key: string) {
    let filtered = this.data.filter(x => x.key == key);
    if(filtered.length != 1)
      throw `Missing addon ${key}`;
    
    return filtered[0];
  }

  pushSave(addon) {
    this.saveCallback(addon);
  }
}