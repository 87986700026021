import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from "../../environments/environment"
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class SettingService {
  routeName: any;
  public bongCategories = [];
  public productCategories = [];
  constructor(private http: HttpClient, private userService: UserService) {
    this.routeName = this.userService.selectedUnit.routeName;
  }
  async getProductCategories() {
    let body = {
      routeName: this.routeName
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/getProductCategories`;
    var result = await this.http.post<ProductTypeChild[]>(url, body).toPromise();
    this.productCategories = result;
    return result;
  }

  async getBongCategories() {
    let body = {
      routeName: this.routeName
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/getBongCategories`;
    var result = await this.http.post<ProductTypeChild[]>(url, body).toPromise();
    this.bongCategories = result;
    return result;
  }


  async updateCategory(category: ProductTypeChild, type: ("Product" | "Bong")) {
    let body = {
      routeName: this.routeName,
      category: category
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/update${type}Category`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }

  async addCategory(category: ProductTypeChild, type: string) {
    let body = {
      routeName: this.routeName,
      category: category
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/add${type}Category`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }

  async deleteCategory(category: ProductTypeChild, type: string) {
    let body = {
      routeName: this.routeName,
      category: category
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/delete${type}Category`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }


  async createPosUser(user: PosUser) {
    let body = {
      routeName: this.routeName,
      user: user
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/posUsers/create`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }

  async updatePosUser(user: PosUser) {
    let body = {
      routeName: this.routeName,
      user: user
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/posUsers/update`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }
  async deletePosUser(user: PosUser) {
    let body = {
      routeName: this.routeName,
      user: user
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/posUsers/delete`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }

  async getPosUsers() {
    let body = {
      routeName: this.routeName,
    }
    let url = `${environment.pubQueueApiUrl}/settings/v1/posUsers/getActiveUsers`;
    var result = await this.http.post<any>(url, body).toPromise();
    return result;
  }

}


export interface ProductTypeChild {
  label: string;
  id: string;
  externalId: string;
  productTypeId: string;
  dbId: string;
}

export interface PosUser {
  id: number,
  name: string,
  pinCode: string,
  active: boolean,
  phoneNumber: string,
  email: string,
  employeeId: string,
  permissions: {
    refund: boolean
  }
}