import { environment } from '../../../../environments/environment';
import { Component } from "@angular/core";
import { Addons } from "../addons";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AlertController } from '@ionic/angular';
import { LanguagePipe } from '../../../pipes/language.pipe';
import { LanguageProvider } from '../../../services/language/language.provider';

@Component({
  selector: 'fortnox-component',
  templateUrl: './fortnox.component.html'
})
export class FortnoxIntegrationComponent {
  public addon;

  constructor(
    private addons: Addons,
    private alertController: AlertController,
    private http: HttpClient,
    private languagePipe:LanguagePipe,
    public languageProvider:LanguageProvider) {
    this.addon = addons.getAddon("fortnox");
    if (!this.addon.roundingAccountNo) {
      this.addon.roundingAccountNo = "3740";
    }
  }
  async registerFortnox() {
    try {
      let response: any = await this.http.get(`${environment.primeApiUrl}/fortnoxauthenticate`, {
        headers: {
          "Content-Type": "application/json",
          "Accept": "application/json",
          "AuthorizationCode": this.addon.authorizationCode
        }
      }).toPromise();

      console.log("Authorized!");
      this.addon.accessToken = response;
      this.addon.authorized = true;
      this.addons.pushSave(this.addon);
    }
    catch (error) {
      console.log(error);
    }
  }
  async reset() {
    let alert = await this.alertController.create({
      header: this.languagePipe.transform("Återställ Fortnox-koppling?",'RESET_FORTNOX',this.languageProvider.currentLanguage),
      message: this.languagePipe.transform("Är du säker att du vill ta bort Fortnox-kopplingen och återställa så att det går att skapa en ny koppling?",'RESET_FORTNOX_TEXT',this.languageProvider.currentLanguage),
      buttons: [
        {
          text: this.languagePipe.transform("Nej",'NO',this.languageProvider.currentLanguage),
          role: "cancel"

        },
        {
          text: this.languagePipe.transform("Ja",'YES',this.languageProvider.currentLanguage),
          role: "confirm"
        }
      ]
    });
    alert.present();

    let result = await alert.onDidDismiss();
    if (result.role === "confirm") {
      this.addon.accessToken = "";
      this.addon.authorized = false;
      this.addons.pushSave(this.addon);
    }
  }
}