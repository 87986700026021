import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../user.service';
import { LoginService } from '../login.service';
@Injectable()
export class AuthGuard implements CanActivate {
    user;
    constructor(private afAuth: AngularFireAuth, private router: Router, private userService: UserService, private loginService: LoginService) {

    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        var promise = new Promise<boolean>((resolve, reject) => {
            this.afAuth.user.subscribe(x => {
                if (x)
                    resolve(true)
                else {
                    this.router.navigate(["login"], {
                        queryParams: { returnUrl: state.url }
                    });
                    resolve(false);
                }
            });

        })
        return promise;

    }
}