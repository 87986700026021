import { Injectable } from '@angular/core';
import { Unit, UserService } from './user.service';
import { AngularFireDatabase } from '@angular/fire/database';

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {

  private apiKeyPromise: Promise<string>;
  private selectedUnit: Unit;
  private clientGroupIdPromise: Promise<string>;
  private salesLocationIdPromise: Promise<string>;
  defaultPrinter: Printer;
  defaultPrinterPromise: Promise<Printer>;
  printers: Printer[];

  constructor(private afDatabase: AngularFireDatabase, private userService: UserService, private http: HttpClient) {
   
    this.userService.selectedUnitObs.subscribe(async x => {
      if (!x) return;
      this.selectedUnit = x;
      let snapshot = await this.afDatabase.database.ref(`adminApp/clients/${this.selectedUnit.routeName}/settings/receiptApi`).once("value");
      let receiptApi = snapshot.val();
      this.salesLocationIdPromise = new Promise<string>((resolve, reject) => resolve(receiptApi.salesLocationId));
      this.clientGroupIdPromise = new Promise<string>((resolve, reject) => resolve(receiptApi.clientGroupId));;
      this.apiKeyPromise = new Promise<string>((resolve, reject) => resolve(receiptApi.apiKey));;
    });
  } 

  async getPrinters() {
    var promise = new Promise<Printer[]>(async (resolve, reject) => {
      var headers = new HttpHeaders({
        "Content-Type": "application/json",
        "Authorization": await this.apiKeyPromise
      });

      this.http.get<Printer[]>(`${environment.primeApiUrl}/getPrinters?clientId=${await this.clientGroupIdPromise}&salesLocationId=${await this.salesLocationIdPromise}`,
        { headers: headers })
        .subscribe(x => {
          this.printers = x;
          resolve(x);
        });
    });
    return promise;
  }
  addPrinter(name: string, printerMac: string): Promise<any> {
    var promise = new Promise<Printer>(async (resolve, reject) => {
      let printer = {
        printerMac: printerMac,
        name: name
      };
      let headers = { "Authorization": await this.apiKeyPromise, "Content-Type": "application/json" }
      this.http.post<Printer>(`${environment.primeApiUrl}/addPrinter?clientId=${await this.clientGroupIdPromise}&salesLocationId=${await this.salesLocationIdPromise}`, printer,
        { headers: headers })
        .subscribe(x => {
          resolve(x);
        });
    });
    return promise;
  }
  deletePrinter(printer: Printer): Promise<Printer> {
    var promise = new Promise<Printer>(async (resolve, reject) => {
      let headers = { "Authorization": await this.apiKeyPromise, "Content-Type": "application/json" }
      this.http.post<Printer>(`${environment.primeApiUrl}/deletePrinter?clientId=${await this.clientGroupIdPromise}&salesLocationId=${await this.salesLocationIdPromise}`, printer,
        { headers: headers })
        .subscribe(x => {
          resolve(x);
        });
    });
    return promise;
  }
  printJob(data: string, options?: { printerMac?: string, contentType?: string }) {
    let promise = new Promise(async (resolve, reject) => {
      try {
        let printerMacToPrintOn = options.printerMac ? options.printerMac : await this.getDefaultPrinter();
        let request = {
          "printerMac": printerMacToPrintOn,
          "contentType": options.contentType ? options.contentType : "image/png",
          "printData": data
        }
        this.http.post<Printer>(`${environment.primeApiUrl}/doPrintJob?clientId=${await this.clientGroupIdPromise}&salesLocationId=${await this.salesLocationIdPromise}`, request,
          { headers: { "Authorization": await this.apiKeyPromise, "Content-Type": "application/json" } }).subscribe(x => {
            resolve(x);
          });
      }
      catch (err) {
        reject(err);
      }
    });
    return promise;
  }
  printHex(hexString: string) {
    return this.printJob(hexString, { contentType: "text/plain" });
  }
  
  getDefaultPrinter() {
    return this.defaultPrinterPromise.then(printer => {
      if (printer)
        return printer.printerMac;
      else
        throw "No default printer has been set. Go to settings";
    });



  }  
  
  
  
}

export class Printer {
  id: string;
  printerMac: string;
  name: string;
  timestamp: string;
  createdUtc: string;
}