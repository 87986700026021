import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyProvider } from '../services/currency/currency.provider';

@Pipe({
    name: 'currency'
})
export class CurrencyPipe implements PipeTransform {
    
    constructor(private currencyProvider: CurrencyProvider ) {

    }

    transform(value: any, currencyCode?: string) {
        let currencySettings = null;
        
        // Check if any currencyCode has been provided otherwise use code from provider.
        if(currencyCode !== undefined && currencyCode !== null){
            currencySettings = this.currencyProvider.getCurrency(currencyCode)
        }else
            currencySettings = this.currencyProvider.getCurrency(this.currencyProvider.currentCurrency);
        
        // If no currency settings found use default currency    
        if(!currencySettings)
            return value + " kr"

        // Check if symbol should be infront of value or not
        if(currencySettings.infront){
            return currencySettings.symbol + currencySettings.space + value;
        }else{
            return value + currencySettings.space + currencySettings.symbol;
        }
        
    }
}