export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'CARD_ADDED_FOR': return `'Card created at ${parameters[0]}`;
            case 'REMOVE_NAME': return `'Remove ${parameters[0]} ?`;
            case 'REMOVED_IMAGE': return `'Removed ${parameters[0]}`;
            case 'PRODUCTS_UPDATED': return `'${parameters[0]} products updated`;
            case 'SAVING_MENU': return `'Saving menu for ${parameters[0]}`;
            case 'REMOVE_MENU': return `'Remove ${parameters[0]} ?`;
            case 'SAVING_GALLERY': return `'Saving gallery for ${parameters[0]}`;
            case 'REMOVED_IMAGE': return `'Removed ${parameters[0]}`;
            case 'NUMBER_ARTICLES': return `'${parameters[0]} articles`;
            case 'UPDATE_ITEM': return `'Updated ${parameters[0]}`;
            case 'PRODUCT_ADDED': return `'${parameters[0]} added`;
            case 'PRODUCT_REMOVED': return `' ${parameters[0]} removed`;
            case 'REMOVE_PRODUCT_CATEGORY': return `'Do you want to remove ${parameters[0]} ?`;
            case 'REMOVE_BONG_CATEGORY': return `'Do you want to remove ${parameters[0]} ?`;
            case 'SHARE_NEWS_WITH': return `'Share news with ${parameters[0]} ?`;
            case 'NEWS_SHARED': return `'Split ${parameters[0]}`;
            case 'TIMELINE_SAVED_FOR': return `'Saving timeline for ${parameters[0]}`;
            case 'SHARING_OFFER_WITH': return `'Sharing offer with ${parameters[0]}`;
            case 'OFFER_SHARED': return `'Spiltted ${parameters[0]}`;
            case 'REMOVE_COUPON': return `'Remove ${parameters[0]} ?`;
            case 'LINKED_PRODUCT': return `'Linked ${parameters[0]}`;
            case 'COPY_FROM': return `'Copy from ${parameters[0]} ?`;
            case 'REMOVE': return `'Remove ${parameters[0]} ?`;
            case 'REMOVING': return `'Removed ${parameters[0]}`;
            case 'ADDED': return `'Added ${parameters[0]}`;
            case 'PRODUCT_SAVED': return `'${parameters[0]} saved`;
            case 'SAVING_PRODUCT': return `'Saving product for ${parameters[0]}`;
            case 'CONFIRM_REMOVE_ACCOUNTING': return `'Are you sure about removing ${parameters[0]} ?`;
            case 'REMOVE_ACCOUNTING': return `'${parameters[0]} removed`;
            case 'SAVED': return `'Saved ${parameters[0]}`;
            case 'RECEIPTS_AMOUNT': return `Totalt: ${parameters[0]} kvitton & ${parameters[1]}`;//translate
            default: return '';
        }
    },
    ALCOHOL_STRINGS: ["vin", "shot", "drink", "sprit", "cognac", "öl", "cider", "whisk", "whiskey", "whisky", "rom", "likör", "vodka", "limoncello", "alkoläsk"],
    AMOUNT_MIN_MOBILE_WALLET : 'Minimum amount',
    AMOUNT_1_MOBILE_WALLET : 'Amount 1',
    AMOUNT_2_MOBILE_WALLET : 'Amount 2',
    STRINGS: ["Öl", "Vin", "Alkoholfritt", "Mat", "Cider", "Drink", "Sprit", "Dricks", "Presentkort"],
    DRINK_LIST: ["dricka", "dryck"],
    WEEL_LIST: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    CHOSE_PRINTER: 'Choose printer',
    SAVE_AS_PDF: 'Save as PDF',
    MY_ACCOUNT: 'My account',
    USER_ID: 'UserID',
    LOG_OUT: 'Log out',
    HANDLE_PERSONNELCARD: 'Get staffcard',
    CREATE_NEW_CARD: 'Create new card',
    PERSONNELCARD: 'Staffcard',
    NAME: 'Name',
    TEXT_ON_CARD: 'Text on card',
    AVAILABLE_THROUGH: 'Valid until',
    URL_TO_BACKGROUND: 'URL for background',
    REMOVE: 'Remove',
    SAVE: 'Save',
    SEARCH: 'Search',
    USER_WITH_CARD: 'Users with card',
    ID: 'ID',
    CARD: 'Card',
    JANUARY: 'January',
    FEBRUARY: 'February',
    MARS: 'March',
    APRIL: 'April',
    MAY: 'May',
    JUNE: 'June',
    JULY: 'July',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'October',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    JAN3: 'Jan',
    FEB3: 'Feb',
    MAR3: 'Mar',
    APR3: 'Apr',
    MAY3: 'May',
    JUN3: 'Jun',
    JUL3: 'Jul',
    AUG3: 'Aug',
    SEP3: 'Sep',
    OCT3: 'Oct',
    NOV3: 'Nov',
    DEC3: 'Dec',
    OK: 'OK',
    CANCEL: 'Cancel',
    SORTED_ON_PRODUCT: 'Sort by product',
    DAY: 'Day',
    TOTAL: 'Total',
    ORDERS_TODAY: 'Orders today',
    ORDERS_THIS_MONTH: 'Orders this mount',
    ACTIVITY: 'Activity',
    COUPONS_IN_APP: 'Coupons just in app',
    PER_PRODUCT: 'Per product',
    USERS_WITH_CODES: 'Users with discount codes',
    RECEIPTNO: 'Reciept no.',
    TIME: 'Time',
    CODE: 'Code',
    ORDERCODE: 'Order code',
    NAVIGATE_HERE: 'Navigate to here or use menu in upper left corner',
    UPLOADING: 'Charging',
    UPLOAD: 'Charge…',
    GALLERY: 'Gallery',
    ARE_YOU_SURE: 'Are you sure ?',
    UPDATING_IMAGE_QUICK: "Wait… we're updating your picture shortly",
    SYNC_PRODUCTS: 'Synchronize products',
    CLEAR_EXISTING_PROUCTS: 'Cleanup existing products ?',
    UPDATING_PRODUCTS: 'Updating products',
    PRODUCT_PAGE_VERIFY: 'Go to product page to verify',
    FROM: 'From',
    TO: 'To',
    ORDERVALUE_COUPON: 'Order value with coupon code',
    SUM_RECEPITS_COUPON: 'Sum of reciepts where guest used coupon code',
    CODE_CAPS: 'CODE',
    DISCOUNT_CAPS: 'DISCOUNT',
    NETTO_CAPS: 'NET',
    VAT_CAPS: 'VAT',
    TOTAL_CAPS: 'TOTAL',
    TODAY_CAPS: 'TODAY',
    CLOSE_CAPS: 'CLOSE',
    RESET_FORTNOX: 'Reset Fortnox-connection',
    RESET_FORTNOX_TEXT: 'Are you sure that you want to remove Fortnox connection and reset to create a new connection ?',
    NO: 'No',
    YES: 'Yes',
    AUTHORIZATIONCODE: 'Authorization code',
    AUTHORIZED: 'AUTHORIZED',
    VOUCHER_SERIES: 'Verification serie',
    ROUNDING_ACCOUNT: 'Account for penny reconciliation',
    ACTIVATE: 'Activate',
    INTEGRATIONS: 'integration',
    PASSWORD: 'Password',
    FORWARD: 'Continue',
    FORGOT_PASSWORD: 'Forgot your password ?',
    RESET_EMAIL_HEAD: 'Email for reset',
    RESET_EMAIL_TXT: 'Fill in your email address',
    NEW_MENU: 'New menu',
    COPY: 'copy',
    SURE: 'Are you sure ?',
    MENU_SHARED: 'Splitted menu !',
    SOMETHING_WRONG: 'Something went wrong !',
    ACTIVATE_BEFORE_REMOVE: 'You must activate a different menu before remove this',
    REMOVE_FOR_ALL: 'Remove for all ?',
    REMOVE_EVEN_OTHERS: 'Do you want to remove post even if existing in other restaurants ?',
    REMOVED_FROM_ALL: 'Removed from all restaurants ',
    MONDAY: 'Monday',
    TUESDAY: 'Tuesday',
    WEDNESDAY: 'Wednesday',
    THURSDAY: 'Thursday',
    FRIDAY: 'Friday',
    SATURDAY: 'Saturday',
    SUNDAY: 'Sunday',
    FAILED: 'Failed',
    SAVED: 'Saved !',
    MENUS: 'Menus',
    VISIBLE_IN_APP: 'Visible in app',
    WEEKLY_MENU: 'Weekly menu',
    SHOW_BUTTON: "Show the button Weekly menu on apps startpage. Update the text every week so user can see what's served",
    UPLOAD_PROGRESS: 'Charging',
    GALLERY_SHARED: 'Shared gallery !',
    USE_IMAGE: 'Use picture',
    UPDATING_IMAGE: "Wait… we're updating your picture shortly",
    COMPRESSING: 'Compressing',
    HELP: 'Help',
    IN_STOCK: 'On stock',
    OUT_OF_STOCK: 'End',
    CATEGORIES: 'Categories',
    FOOD: 'Food',
    PRODUCTS: 'Products',
    ADD: 'Add',
    UPDATE: 'Update',
    CONFIRM: 'OK',
    PRODUCT_CATEGORIES: 'Product categories',
    BONG_CATEGORIES: 'Ticket categories',
    NEW_PRODUCT_CATEGORY: 'New product category',
    REMOVE_PRODUCT_CATEGORY: 'Remove product category !',
    EDIT_PRODUCT_CATEGORY: 'Change product category',
    NEW_BONG_CATEGORY: 'New ticket category',
    REMOVE_BONG_CATEGORY: 'Remove ticket category !',
    EDIT_BONG_CATEGORY: 'Change ticket category',
    TIMELINE: 'Timeline',
    NEW_POST: 'New post',
    SHARE: 'Share',
    CHOOSE_IMAGE: 'Choose picture…',
    TITLE: 'Title',
    TEXT: 'Text',
    OFFER: 'Offer',
    PRODUCT: 'Product',
    URL: 'URL',
    STATIC: 'Static',
    APP_TAB: 'Tab i the app that product is under',
    OFFER_INFO: 'Info about offer',
    WARNING: 'Warning',
    ALREADY_EDITING: 'You are already editing another post. Are you sure that you want to discard data ?',
    ABORT_Q: 'Cancel ?',
    CONFIRM_CHANGES: 'Are you sure that you want to cancel ? Changes will be lost',
    FILL_ALL_FIELDS: 'You must fill out all fields besides title and text',
    SAVE_NEWS: 'Saving news',
    MAIN_TEXT: 'Main text',
    NEW_TITLE: 'New title',
    REMOVE_POST: 'Remove post ?',
    REMOVE_POST_CONFIRM: 'Are you sure that you want to remove post ?',
    REMOVE_FOR_OTHERS: 'Do you want to remove post even if existing in other restaurants ?',
    TIMELINE_SHARED: 'Shared timeline !',
    TYPE: 'Type',
    SERVING_METHOD: 'Serving method',
    APPLIES_FOR: 'Valid for',
    PRODUCT_TYPES: 'Product types',
    DISCOUNT_PERCENTAGE: 'Procent of discount',
    AUTOMATIC_ACTIVATION: 'Activate automatically for users',
    CREATING_OFFER: 'Create offer',
    OFFER_CREATED: 'Offer created !',
    UPDATING_OFFER: 'Updating offer',
    OFFER_UPDATED: 'Updated offer',
    REMOVING: 'Removing',
    OFFER_REMOVED: 'Removed offer',
    NEW_BUTTON: 'New button ',
    CREATE_NEW_BUTTON: 'Create new button',
    CONNECT_BUTTON: 'Connect the button to ?',
    CHOOSE_BG_IMAGE: 'Choose background picture',
    BUTTON_LAYER: 'Button stock',
    FREE_TEXT: 'Free text',
    ACTIVATE_IN_POS: 'ACTIVATE IN CASH REGISTER',
    ALLERGY_INFO_SAVED: 'Info about allergens saved and visible in app',
    MINIMUM_BONUS_SAVED: 'Minimum bonus saved',
    PAYMENT_OPTIONS_SAVED: 'Alternativ payment saved',
    DELIVERY_OPTIONS_SAVED: 'Serving settings saved',
    NOTIFICATION_SAVED: 'Notice saved',
    INSTAGRAM_SAVED: ' Instagram saved',
    FACEBOOK_SAVED: ' Facebook saved',
    MENU_STYLE_SAVED: 'Menu layout saved',
    FOOD_SETTINGS_SAVED: 'Food settings saved',
    DRINK_SETTINGS_SAVED: 'Drinks setting saved',
    DISCOUNT_SETTINGS_SAVED: 'Discount settings saved',
    COUPON_NAME: 'Name of coupon',
    PERCENTAGE_IN_DISOUNT: 'Discount by percent',
    TAKEAWAY: 'Takeaway',
    TABLE_SERVING: 'Serv at table',
    COLLECT_IN_BAR: 'Pickup at bar',
    DELIVERY: 'Delivery',
    SENDING_PUSH: 'Sending pushnote',
    PUSH_SENT: 'Pushnote sent !',
    GENERAL: 'Generally',
    GENERATE_REPORT: 'Generate report',
    PUSH_NOTIFICATIONS: 'Pushnotes',
    OFFERS: 'Offers',
    DELIVERY_OPTIONS: 'Alternative serving',
    PAYMENT_OPTIONS: 'Alternative payment',
    USE_SAVE_BUTTON: 'Changes on this page affects mobileapp and the Save button must be used to submit changes',
    OPEN_FOR_SALE: 'Open for sales',
    MESSAGE_WHEN_CLOSED: 'Notification to customer when tab is closed',
    FOOD_TAB: 'Food tab',
    DRINK_TAB: 'Drinks tab',
    NOTIFICATION_IN_APP: 'Ticket in tab',
    SHOW_NOTIFICATION: 'Show notification',
    NOTIFICATION_TEXT: 'Text for notification',
    ALLERGY_INFO: 'Allergen info',
    PHONE_NUMBER: 'Phone no.',
    OPENING: 'Open at',
    CLOSING: 'Closing at',
    SHOW_FACEBOOK: 'Show Facebook icon on startpage',
    INSTAGRAM: 'Instagram',
    SHOW_INSTAGRAM: 'Show Instagram icon on startpage',
    WHICH_ACTIVE: 'Which are to be activated in the app ?',
    SWISH: 'Swish',
    TAB: 'Table ticket',
    TABLENUMBER_FROM: 'Table no. from',
    TABLENUMBER_TO: 'Table no. to',
    AREA_1: 'Area 1',
    AREA_2: 'Area 2',
    AREA_3: 'Area 3',
    AREA_4: 'Area 4',
    DELIVERY_RADIUS: 'Delivery radius in meters',
    DELIVERY_RADIUS_COST: 'Price for delivery',
    BONUSSYSTEM: 'Bonussystem',
    LEAST_AMOUNT_BONUS: 'Least number of points to be able to purchase with points',
    GLOBAL_OFFERS: 'Global offers',
    APPLIES_TO_ALL: 'Valid for all in app',
    ONETIME: 'Single / Disposible ????',
    USER_GROUPS: 'User groups',
    ADD_NEW: 'Add new',
    GROUP_NAME: 'Group name',
    DISCOUNT_CODES: 'Discount codes ',
    DISCOUNTPERCENT: 'Discount percentage',
    APPLY_AUTOMATICALLY: 'Apply discount code automatically to customers in app',
    SEND_PUSH: 'Send pushnote',
    SEND_PUSH_TEXT: 'Message immediately goes out to all locale users ',
    SEND: 'Send',
    ALTERNATIVES: 'Alternative',
    HEADER_EX: 'Heading (eg. Degree of frying)',
    OPTION_TYPE: 'Type of add-on',
    CHECKBOX: 'Tickbox',
    MULTIPLE: 'More options',
    PRICE: 'Price',
    OPTIONS: 'Add-on',
    COPY_FROM: 'Copy from',
    USING_STEP_BY_STEP: 'Using step-by-step flow',
    HEADER: 'Heading',
    SUBHEADER: 'Subheading',
    LINK_WITH: 'Linked with',
    EDIT_EXCEPTION: 'Change exceptions',
    REASON: 'Reason',
    REASON_DETAILS: 'Details of reason',
    ACCOUNTING: 'Accounting',
    SEARCH_PRODUCT: 'Search product',
    DELIVERY_TYPES: 'Way of delivery',
    REMOVE_EXCEPTION_CONFIRM: 'Do you want to remove exception ?',
    REMOVE_EXCEPTION: 'Remove exception',
    SAVE_WITHOUT_ACCOUNTING: 'You cannot save product without accounting infomation and product type ',
    REMOVE_PRODUCT_RESTURANTS: 'Do you want to remove product from more restaurants',
    PRODUCT_SHARED: 'Shared the product !',
    PRODUCT_INFORMATION: 'Product information',
    BASIC_INFO: 'Basic information',
    ACTIVE: 'Active',
    PRODUCT_ACTIVE: 'Product active',
    PRICE_KR: 'Price in.',
    BONUS_CONVERT: 'Percentage that converts into bonuspoints when purchasing product',
    BONUS_CONVERT_WALLET:'Percentage that converts into bonuspoints when refilling mobile wallet',
    NOT_ALLOWED_TO_BY_WITH_BONUS: 'Not allowed to be purchased with bonus',
    DESCRIPTION_SHORT: 'Description (short)',
    DESCRIPTION_LONG: 'Description (long)',
    CATEGORY_BONG_INFO: 'Categoty- and ticketinformation',
    NAME_ON_BONG: 'Name on ticket (optional)',
    PRODUCT_TYPE: 'Producttype',
    PRODUCT_CATEGORY: 'Product category',
    BONG_CATEGORY: 'Ticket category',
    ACCOUNTING_VAT: 'Accounting and VAT',
    EXCEPTION: 'Exception',
    CONTAINS_ALCOHOL: 'Product contains alcohol ',
    AGE_LIMIT: 'Age limit 18+',
    PURCHASE: 'Purchase',
    COST: 'Cost',
    MARGIN: 'Margin',
    TAGS: 'Tagging',
    NEW_TAG: 'New tag',
    EXTERNAL_ID: 'External ID',
    STOCK_BALANCE: 'Stock balance',
    COUNT: 'Quantity',
    ACOUNTINGS: 'Accountings',
    VAT_RATE: 'VAT rate',
    VAT_RATES: 'VAT rates',
    ACCOUNTING_ACCOUNT: 'Account',
    NO_ACCOUNT: 'No account',
    ACCOUNTING_CREATED: 'Accounting created',
    PAYMENT_TYPES: 'Payment method',
    ACTIVATED_PAYMENT_TYPES: 'Activated payment method',
    ADITIONAL_PAYMENT_TYPES: 'More payment methods',
    NO_PAYMENT_TYPES: 'has no payment methods activated. Tap More payment methods to activate one',
    ADDED: 'Added',
    PAYMENT_FEES: 'Some payment methods are associated with fees. Here you may choose accounts for these ',
    VAT: 'VAT',
    HOME: 'Home',
    REPORTS: 'Reports',
    ADMIN: 'Administration',
    NEWS: 'News flow',
    APP: 'App',
    POS: 'TILL',
    STAFF_CARD: 'Staff card',
    MISC: 'Misc.',
    SETTINGS: 'Settings',
    RESTURANT_INFO: 'Restaurant information',
    USERS: 'Users',
    SEE_ALL: 'See all…',
    STATISTICS: 'Statistics',
    DARK_MODE: 'Dark layout',
    SELECT_UNIT: 'Choose unit',
    OPENING_H_SAVED: 'Opening hours saved',
    OPENING_H: 'Opening hours',
    CLOSED: 'Closed',
    OPENS: 'Opening',
    CLOSES: 'Closing',
    DEVIATIONS: 'Descrepancies',
    SELECT_LOGO: 'Choose logo…',
    ORG_NO: 'Company number',
    ADRESS: 'Address',
    ZIP_CODE: 'Post code',
    POST_ADR: 'City',
    PHONE: 'Phone no.',
    EMAIL: 'Email',
    ABOUT_US: 'About us',
    ADD_USER: 'Add users',
    ADD_DOMAIN_AUTO: 'Add User Domain automatically for this User Group when registering',
    DOMAIN_EXAMPLE: '(eg. only pubq.se, not @pubq.se)',
    LOAD: 'Charge',

    CHOOSE: 'Select...',
    LINK_TO_PRODUCT: 'Link to product or website',
    START_WITH_HTTPS: 'must begin with https://',
    WHERE_IS_PRODUCT: 'Where is the product?',
    OFFER_INFO_PLACEHODLER: 'Info about offer...',
    OPTION_DESCRIPTION: 'Description of offer',
    SELECT: 'Select',
    DISCOUNT_PERCENTAGE_PLACEHOLDER: 'Discount in percent',
    WRITE: 'Write...',
    SEARCH_PRODUCT_OR_TAG: 'Search product or tag',
    STEP_PLACEHOLDER: 'Step for step-by-step product',
    ABOUT_US_PLACEHOLDER: 'About us',
    DOMAIN_EX: 'Domain i.e. pubq.se',
    TIME_CONTROL_MENU: 'Set a schedule for menu',
    SET: 'Set',
    SCHEDULE: 'Schema',
    ACTIVATION_TIME: 'Activation time',
    ACTIVATE_MENU: 'Activate menu',
    WEEK: 'Week',
    SELECT_Z_REPORT: 'Select Z-reports',
    FROM_TO: 'From - to',
    SEND_END_TO_FORTNOX: 'Send End Of Day to Fortnox ?',
    IMPORT_SUCCESSFUL: 'Import succesful',
    IMPORT_FAILED: 'Import failed',
    WHICH_EMAIL: 'Which mail address to send to ?',
    SUMD_Z_REPORTS: 'Summed Z-reports',
    Z_REPORT: 'Z-reports',
    MAIL: 'Mail',
    PREVIOUS_MONTH: 'Previous month',
    COMPILATION: 'Compilation',
    JOURNALNR: 'Journal no.',
    CREATED: 'Created',
    RECEIPTNO_FROM_TO: 'Receipt from - to',
    SUM: 'Sum',
    INTEGRATIONS_HEADER: 'Integrations',
    BUY_REBUY: 'Buy and rebuy',
    WRONG_HIT: 'Wrong hit',
    CANCELD: 'Cancelled',
    PROFORMA: 'Proforma',
    CREATING_ENDOFDAY: 'Creating End-OF-Day',
    NOT_ENOUGH: "You haven't had sufficient turnover",
    END_OF_DAY_100: 'End-Of-Day needed to contain more than 100.',
    Z_ENDOFDAY: 'End-Of-Day',
    PREL_X_REPORT: 'Preliminary X-report',
    PAID: 'Paid',
    SUM_PAYMENTS: 'Sum of payments',
    CHANGE_IN: 'Change IN',
    CHANGE_OUT: 'Change OUT',
    ACCOUNTING_REPORTS: 'Accounting reports',
    STARTED: 'Started',
    FINISHED: 'Finished',
    ACCOUNTS: 'Accounts',
    ROWS: 'Rows',
    SALES: 'Sales',
    PAYMENTS: 'Payments',
    RECEIPTS: 'Receipts',
    ACCOUNTING_ACCOUNTS: 'Accounting accounts',
    TODAY:'Today',
    YESTERDAY:'Yesterday',
    LAST_WEEK:'Last week',
    THIS_MONTH:'This month',
    LAST_MONTH:'Last month',
    USER:'User',
    ORDERS:'Orders',
    END_OF_DAY:'End of day',
    HISTORY:'History',
    TIP_REPORT: 'Tip report',
    DESC_GENERATE_REPORT: 'The report is generated into a PDF file that will be automatically downloaded.',
    GENERATING_REPORT: 'Generates the report',
    ERROR_TIP_REPORT: 'Something went wrong in the generation of the tip report! Try again.',
    COMPANY_NAME: 'Company name',
    EMAIL_NOT_VALID: "The emailadress is not valid"
}