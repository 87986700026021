import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import {
  AngularFireList,
  AngularFireDatabase,
  AngularFireObject
} from "@angular/fire/database";
import { Observable } from "rxjs";
import { UserService } from "./user.service";
import { OrderService } from "./order.service";
@Injectable({
  providedIn: "root"
})
export class PaymentOptionAccountingService {
  private paymentOptionAccountingsRef: AngularFireList<any>;
  private paymentOptionAccountingsObjectRef: AngularFireObject<any>;
  paymentOptionAccoutings: Observable<PaymentOptionAccounting[]>;
  private routeName: string;
  constructor(
    private afDatabase: AngularFireDatabase,
    private userService: UserService,
    private orderService: OrderService
  ) {
    this.userService.selectedUnitObs.subscribe(x => {
      if (!x) {
        this.paymentOptionAccountingsObjectRef = null;
        this.paymentOptionAccountingsRef = null;
        return;
      }
      this.routeName = x.routeName;
      this.paymentOptionAccountingsRef = this.afDatabase.list(
        `adminApp/clients/${x.routeName}/settings/vat/paymentOptionAccountings`
      );
      this.paymentOptionAccountingsObjectRef = this.afDatabase.object(
        `adminApp/clients/${x.routeName}/settings/vat/paymentOptionAccountings`
      );
      this.paymentOptionAccoutings = this.paymentOptionAccountingsRef
        .snapshotChanges()
        .pipe(
          map(changes =>
            changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
          )
        );
    });
  }
  removePaymentOption(paymentOption: any) {
    this.paymentOptionAccountingsRef.remove(paymentOption.key);
  }

  async addMobileWallet() {
    if (this.routeName) {
      let mw = (await this.afDatabase.database.ref(
        `Clients/${this.routeName}/AppInfo/Context/paymentOptions/mobileWallet`
      ).once("value")).val();
      if (!mw) {
        await this.afDatabase.database.ref(
          `Clients/${this.routeName}/AppInfo/Context/paymentOptions/mobileWallet`
        ).set({
          allowUserToAddAnyAmount: false,
          allowUserToAddMoney: false,
          amounts: [0, 0],
          enabled: false,
          minimumAmount: 300,
          percentageMadeIntoBonus: 0
        });
      }

      let walletProduct = (await this.afDatabase.database.ref(
        `Clients/${this.routeName}/menuItems/mobileWalletAddToBalanceX`).once("value")).val();

      if (!walletProduct) {
        const walletProductTemplate = (await this.afDatabase.database.ref(
          `walletTemplate/product/mobileWalletAddToBalanceX`).once("value")).val();

        await this.afDatabase.database.ref(
          `Clients/${this.routeName}/menuItems/mobileWalletAddToBalanceX`).set(walletProductTemplate);
      }

      let walletAccounting = (await this.afDatabase.database.ref(
        `adminApp/clients/${this.routeName}/settings/vat/articleAccountings/wallet`
      ).once("value")).val();

      if (!walletAccounting) {
        const walletAccountingTemplate = (await this.afDatabase.database.ref(
          `walletTemplate/accounting/wallet`).once("value")).val();

        await this.afDatabase.database.ref(
          `adminApp/clients/${this.routeName}/settings/vat/articleAccountings/wallet`).set(walletAccountingTemplate);
      }
    }
  }

  async addPaymentOption(paymentOption) {
    if (paymentOption.name === 'Mobile Wallet in App') {
      await this.addMobileWallet();
    }
    if (paymentOption.name === 'Invoice in App') {
      if (this.routeName) {
        let iv = (await this.afDatabase.database.ref(
          `Clients/${this.routeName}/AppInfo/Context/paymentOptions/invoice`
        ).once("value")).val();
        if (!iv) {
          await this.afDatabase.database.ref(
            `Clients/${this.routeName}/AppInfo/Context/paymentOptions/invoice`).set({ enabled: false })
        }
      }
    }

    return this.paymentOptionAccountingsRef.push(paymentOption);
  }
  getAvailablePaymentOptions(): Observable<PaymentOptionAccounting[]> {
    return Observable.create(observer => {
      this.afDatabase.database
        .ref("primeConfig/paymentOptions")
        .once("value")
        .then(x => {
          observer.next(x.val());
        });
    });
  }
  async saveAccountings(accounts: PaymentOptionAccounting[]) {
    await this.paymentOptionAccountingsObjectRef.set(accounts);
    return true;
  }
  updateArticleAccounting(paymentOptionAccounting: PaymentOptionAccounting) {
    this.paymentOptionAccountingsRef.update(
      paymentOptionAccounting.key,
      paymentOptionAccounting
    );
  }
  deleteArticleAccounting(paymentOptionAccounting: PaymentOptionAccounting) {
    this.paymentOptionAccountingsRef.remove(paymentOptionAccounting.key);
  }
}
export class PaymentOptionAccounting {
  key: string;
  name: string;
  imageUrl: string;
  accountNo: string;
}
