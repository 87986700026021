import { Component } from "@angular/core";
import { EditCategoryComponent } from "./categoryModal/edit-category.component"


import { ModalController, ToastController, AlertController } from "@ionic/angular";
import { UserService } from "../../services/user.service";
import { ProductTypeChild, SettingService } from "../../services/settings.service";
import { LanguagePipe } from "../../pipes/language.pipe";
import { LanguageProvider } from "../../services/language/language.provider";

@Component({
  selector: "jo-misc",
  templateUrl: "./misc.component.html",
  styleUrls: ["./misc.component.scss"]
})
export class MiscComponent {
  private productCategories;
  private bongCategories;
  loading;

  constructor(private modalController: ModalController, private alertController: AlertController, private settingService: SettingService,
    private languagePipe:LanguagePipe,public languageProvider:LanguageProvider) {
    this.loading = true;


  }
  async ngOnInit() {
    this.productCategories = await this.settingService.getProductCategories();
    this.bongCategories = await this.settingService.getBongCategories();

    console.dir(this.productCategories)

    this.loading = false;
  }

  generateInternalId(categoryList, category){
    let ids:number[] = [];
    categoryList.forEach(element => {
      ids.push(parseInt(element.id));
    });
    if(ids.length===0){
      return "0";
    }
    ids.sort(function(a, b){return a-b});
    return (ids[ids.length-1]+1).toString();
  }


  async newProductCategory() {
    let category:ProductTypeChild = {label:"",id:"0",externalId:"",productTypeId:null,dbId:null};
    let modal = await this.modalController.create({
      component: EditCategoryComponent,
      componentProps: { header: this.languagePipe.transform("Ny Produktkategori",'NEW_PRODUCT_CATEGORY',this.languageProvider.currentLanguage), category: category },
    })
    modal.present()
    let result = await modal.onDidDismiss()
    if (result.role == "confirm") {
      category = result.data;
      category.id = this.generateInternalId(this.productCategories,category);
      this.settingService.addCategory(category, "Product").then((result) => {
        category.id = result.id;
        this.productCategories.push(category);
      })
    }
  }

  async deleteProductCategory(category) {
    const alert = await this.alertController.create({
      header: this.languagePipe.transform('Ta bort produktkategori!','REMOVE_PRODUCT_CATEGORY',this.languageProvider.currentLanguage),
      message: this.languagePipe.transform(`Vill du ta bort <strong>${category.name}</strong>?`,'REMOVE_PRODUCT_CATEGORY',this.languageProvider.currentLanguage,[category.name]),
      buttons: [
        {
          text: this.languagePipe.transform('Nej','NO',this.languageProvider.currentLanguage),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('nej');
          }
        }, {
          text: this.languagePipe.transform('Ja','NO',this.languageProvider.currentLanguage),
          handler: () => {
            this.productCategories = this.productCategories.filter(x => x !== category);
            this.settingService.deleteCategory(category, "Product");
          }
        }
      ]
    });
    await alert.present();
  }

  async editProductCategory(category) {
    let modal = await this.modalController.create({
      component: EditCategoryComponent,
      componentProps: { header: this.languagePipe.transform("Ändra Produktkategori",'EDIT_PRODUCT_CATEGORY',this.languageProvider.currentLanguage), category: category },
    })
    modal.present()
    let result = await modal.onDidDismiss();
    if (result.role == "confirm") {
      category = result.data;
      this.settingService.updateCategory(category, "Product")
    }
  }


  async newBongCategory() {
    let category:ProductTypeChild = {label:"",id:"0",externalId:"",productTypeId:null,dbId:null};
    let modal = await this.modalController.create({
      component: EditCategoryComponent,
      componentProps: { header: this.languagePipe.transform("Ny Bongkategori",'NEW_BONG_CATEGORY',this.languageProvider.currentLanguage), category: category },
    })
    modal.present()
    let result = await modal.onDidDismiss()
    if (result.role == "confirm") {
      category = result.data;
      category.id = this.generateInternalId(this.bongCategories,category);
      this.settingService.addCategory(category, "Bong").then((result) => {
        category.id = result.id;
        this.bongCategories.push(category);
      })
    }
  }

  async deleteBongCategory(category) {
    const alert = await this.alertController.create({
      header: this.languagePipe.transform('Ta bort bongkategori!','REMOVE_BONG_CATEGORY',this.languageProvider.currentLanguage),
      message: this.languagePipe.transform(`Vill du ta bort <strong>${category.name}</strong>?`,'REMOVE_BONG_CATEGORY',this.languageProvider.currentLanguage,[category.name]),
      buttons: [
        {
          text: this.languagePipe.transform('Nej','NO',this.languageProvider.currentLanguage),
          role: 'cancel',
          cssClass: 'secondary',
          handler: () => {
            console.log('nej');
          }
        }, {
          text: this.languagePipe.transform('Ja','YES',this.languageProvider.currentLanguage),
          handler: () => {
            this.bongCategories = this.bongCategories.filter(x => x !== category);
            this.settingService.deleteCategory(category, "Bong");
          }
        }
      ]
    });
    await alert.present();
  }

  async editBongCategory(category) {
    let modal = await this.modalController.create({
      component: EditCategoryComponent,
      componentProps: { header: this.languagePipe.transform("Ändra Bongkategori",'EDIT_BONG_CATEGORY',this.languageProvider.currentLanguage), category: category },
    })
    modal.present()
    let result = await modal.onDidDismiss();
    if (result.role == "confirm") {
      this.settingService.updateCategory(category, "Bong")
    }
  }


} 