import { Injectable } from "@angular/core"
import { AngularFireDatabase } from "@angular/fire/database"
import { UserService, Unit } from "./user.service"
import { map } from "rxjs/operators"
import { HttpClient, HttpHeaders } from "@angular/common/http"
import { environment } from "../../environments/environment"

@Injectable({
    providedIn: "root",
})
export class UnitService {
    selectedUnit: Unit
    createUserApiUrl = `${environment.primeApiUrl}/createUser`
    routeNamesMoved = [
        'demorino',
        'prod_salesdemo',
        'compassno_regjeringskvartalet',
        'compassno_regjeringskvartaleta64',
        'compassno_regjeringskvartaletgrubbegata',
        'compassno_regjeringskvartaletkirkegata',
        'compassno_regjeringskvartaletkongen',
        'compassno_regjeringskvartaletkongen18',
        'compassno_regjeringskvartaletkronprinsen',
        'compassno_regjeringskvartaletr6',
        'compassno_regjeringskvartaletvictoria'
    ]

    constructor(
        private afDatabase: AngularFireDatabase,
        private userService: UserService,
        private http: HttpClient
    ) {
    }


    async getUserGroups(routeName) {
        let franchiseId = await this.getFranchiseId(routeName);
        return await this.http
            .get<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${franchiseId}`).toPromise();
    }
    async updateUserGruop(group) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/usergroup/${group.id}`, { Code: group.Code }).toPromise();
    }
    async getUserGroupCoupons(routeName) {
        let franchiseId = await this.getFranchiseId(routeName);
        return await this.http
            .get<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/coupons/${franchiseId}`).toPromise();
    }
    async getUsersForUserGroup(groupId) {
        return await this.http
            .get<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/users`).toPromise();
    }
    async addUserToUserGroup(user, routeName, groupId) {
        let franchiseId = await this.getFranchiseId(routeName);
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}`, { user, franchiseId }).toPromise();
    }
    async linkCouponToGroup(id: any, couponId: any) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/usergroups/linkCoupon`, { usergoupId: id, couponId: couponId }).toPromise();
    }
    async createInvoiceImplementation(invoice: any, groupId) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/invoice/create`, { invoice: invoice }).toPromise();
    }
    async updateInvoiceImplementation(invoice: any, groupId) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/invoice/update`, { invoice: invoice }).toPromise();
    }
    async saveInvoiceForCoupon(previousInvoice: any, newInvoice: any, coupon: any) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/coupons/updateInvoiceIntegrations`, { previousInvoice, newInvoice, coupon }).toPromise();
    }
    async getDomainsForUserGroup(groupId) {
        return await this.http.get<string[]>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/domains`).toPromise();
    }
    async addDomainToUserGroup(groupId, domain) {
        let body = { domain };
        return await this.http.post(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/domains`, body).toPromise();
    }
    async removeDomainFromUserGroup(groupId, domain) {
        let body = { domain };
        return await this.http.post(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/domains/delete`, body).toPromise();
    }
    async deleteUserFromUserGroup(groupId, userId) {
        return await this.http.delete<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/users/${userId}`).toPromise();
    }
    async getCouponsForUserGroup(groupId) {
        return await this.http
            .get<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/coupons`).toPromise();
    }
    async getInvoiceIntegraionsForUserGroup(groupId) {
        return await this.http
            .get<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/invoices`).toPromise();
    }
    async createCouponForUserGroup(groupId, coupon) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/coupons`, { coupon }).toPromise();
    }
    async deleteCouponFromUserGroup(groupId, couponId) {
        return await this.http.delete<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${groupId}/coupons/${couponId}`).toPromise();
    }
    async searchUser(routeName, email) {
        let foundUsers = [];
        let body = {
            routeName: routeName,
            email: email
        }

        if (this.routeNamesMoved.includes(routeName)) {
            foundUsers = await this.http
                .post<any>(`${environment.pubQueueApiUrl}/searchEuUser`, body).toPromise();
        } else {
            foundUsers = await this.http
                .post<any>(`${environment.pubQueueApiUrl}/searchUserInUG`, body).toPromise();
        }
        return foundUsers;

    }
    async updateUserGroupUser(user) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/user/update`, { user }).toPromise();
    }
    async createUserGroup(name, routeName) {
        let franchiseId = await this.getFranchiseId(routeName);
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups`, { franchiseId, userGroupName: name }).toPromise();
    }
    async deleteUserGroup(id) {
        return await this.http.delete<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/${id}`).toPromise();
    }
    async deleteInvoiceFromUser(userId: any, invoiceId: any) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/user/removeInvoice`, { userId: userId, invoiceId: invoiceId }).toPromise();
    }
    async addInvoiceToUser(userId: any, invoiceId: any) {
        return await this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/usergroups/user/addInvoice`, { userId: userId, invoiceId: invoiceId }).toPromise();
    }
    async getFranchiseId(routeName) {
        return (await this.afDatabase.database.ref(`adminApp/clients/${routeName}/settings/receiptApi/voucherFranchiseId`).once("value")).val();
    }
    async setFranchiseId(routeName) {
        this.selectedUnit.franchiseId = await this.getFranchiseId(routeName);
        return this.selectedUnit.franchiseId;
    }
    async getUsers(): Promise<any> {
        let promise = new Promise((resolve, reject) => {
            let sub = this.userService.selectedUnitObs.subscribe((x) => {
                if (!x) return
                this.selectedUnit = x
                this.afDatabase
                    .list<any>(
                        `adminApp/clients/${this.selectedUnit.routeName}/users`
                    )
                    .snapshotChanges()
                    .pipe(
                        map((changes) =>
                            changes.map((c) => ({
                                key: c.payload.key,
                                ...c.payload.val(),
                            }))
                        )
                    )
                    .subscribe((userList) => {
                        var promises = []
                        userList.forEach((user: any) => {
                            let promise = this.afDatabase
                                .object(`usersAdminApp/${user.key}/info`)
                                .query.once("value")
                            promises.push(promise)
                        })
                        let users = []
                        Promise.all(promises).then((snapshots) => {
                            snapshots.forEach((snapshot) => {
                                users.push({
                                    key: snapshot.ref.parent.key,
                                    ...snapshot.val(),
                                })
                            })
                            resolve(users)
                        })
                    })
                setTimeout(() => {
                    sub.unsubscribe()
                }, 100);

            })
        })
        return promise;
    }
    updateUser(user) {
        return this.afDatabase.object(`usersAdminApp/${user.key}/info`).update({
            name: user.name,
            email: user.email,
        })
    }
    async removeUser(user) {
        var promise = new Promise((resolve, reject) => {
            this.afDatabase.database
                .ref(
                    `adminApp/clients/${this.selectedUnit.routeName}/users/${user.key}`
                )
                .set(null)
            this.afDatabase.database
                .ref(`usersAdminApp/${user.key}/clientGroups/0`)
                .once("value")
                .then((snapshot) => {
                    let clientGroup = snapshot.val()
                    for (
                        let index = clientGroup.units.length - 1;
                        index >= 0;
                        index--
                    ) {
                        const element = clientGroup.units[index]
                        if (element.routeName === this.selectedUnit.routeName)
                            clientGroup.units.splice(index, 1)
                    }
                    snapshot.ref.update(clientGroup).then((done) => {
                        resolve(true)
                    })
                })
        })
        return promise
    }
    async createUser(user) {
        var promise = new Promise((resolve, reject) => {
            this.http
                .post<any>(this.createUserApiUrl, {
                    user: {
                        name: user.name,
                        email: user.email,
                        password: user.password,
                    },
                })
                .subscribe((x) => {
                    this.afDatabase.database
                        .ref(
                            `adminApp/clients/${this.selectedUnit.routeName}/users/${x.data}`
                        )
                        .set(true)
                    this.afDatabase.database
                        .ref(`usersAdminApp/${x.data}/clientGroups/0`)
                        .once("value")
                        .then((snapshot) => {
                            let clientGroup = snapshot.val()
                            if (clientGroup && clientGroup.units) {
                                clientGroup.units.push(this.selectedUnit)
                                snapshot.ref
                                    .update(clientGroup)
                                    .then((done) => {
                                        resolve(x.data)
                                    })
                            } else {
                                let newGroup = {
                                    id: this.userService.userClientGroupId,
                                    units: [this.selectedUnit],
                                }
                                snapshot.ref.update(newGroup).then((done) => {
                                    resolve(x.data)
                                })
                            }
                        })
                })
        })
        return promise
    }
    async saveOpeningHours(hours, deviations, routeName) {
        return Promise.all([
            this.afDatabase.database
                .ref(
                    `adminApp/clients/${routeName}/settings/openingHours/weekdays`
                )
                .set(hours),
            this.afDatabase.database
                .ref(
                    `adminApp/clients/${routeName}/settings/openingHours/deviations`
                )
                .set(deviations),
        ])
    }
    getAboutUs(routeName) {
        return this.afDatabase
            .object<string>(`Clients/${routeName}/AppInfo/Context/aboutUsDescription`)
            .valueChanges();
    }
    saveAboutUs(routeName, text) {
        this.afDatabase
            .object<string>(`Clients/${routeName}/AppInfo/Context/aboutUsDescription`).set(text)
    }
    getOpeningHours(routeName) {
        return this.afDatabase
            .object(`adminApp/clients/${routeName}/settings/openingHours`)
            .valueChanges()
    }
    async setToggleFlags(routeName) {
        let flags = (
            await this.afDatabase
                .object(`adminApp/clients/${routeName}/settings/flags`)
                .query.once("value")
        ).val()
        for (const key in flags) {
            if (Object.prototype.hasOwnProperty.call(flags, key)) {
                const element = flags[key]
                toggleFlags[key] = element
            }
        }
    }
    async createOffer(offer, routeName) {
        return this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/voucher/create`, {
                offer,
                userId: this.userService.user.value.id,
                routeName: routeName
            }).toPromise();

    }
    async deleteOffer(offer, routeName) {
        return this.http
            .post<any>(`${environment.pubQueueApiUrl}/api/v1/voucher/delete`, {
                offer,
                userId: this.userService.user.value.id,
                routeName: routeName
            }).toPromise();

    }
    async sendPushToTopic(pushData: { title: string, body: string }, routeName: string) {
        let projectId = (await this.afDatabase.database.ref(`adminApp/clients/${routeName}/settings/firebaseProjectId`).once("value")).val();
        if (!projectId)
            projectId = "default";
        let data = {
            title: pushData.title,
            body: pushData.body,
            projectId: projectId,
            routeName: routeName
        };
        let url = `${environment.pubQueueApiUrl}/api/v1/unit/push`;
        return await this.http.post<any>(url, data).toPromise();
    }
    async updateInfo(unit: Unit) {
        await this.afDatabase.database.ref(`adminApp/clients/${unit.routeName}/info/public`).update(unit.info);
        return true;
    }
    async getInfo(unit: Unit) {
        return (await this.afDatabase.database.ref(`adminApp/clients/${unit.routeName}/info/public`).once("value")).val();
    }
}

class Flags { phoneFrame: boolean; minimumFeeOnDelivery: boolean; configureProductBonuses: boolean; }
var toggleFlags = new Flags()
export { toggleFlags }
