import { Component, OnInit } from '@angular/core';
import { PrinterService, Printer } from '../services/printer.service';
import { ModalController } from '@ionic/angular';
import { LanguageProvider } from '../services/language/language.provider';

@Component({
  selector: 'app-choose-printer',
  templateUrl: './choose-printer.component.html',
  styleUrls: ['./choose-printer.component.scss']
})
export class ChoosePrinterComponent implements OnInit {
  printers:Printer[] = [];
  loading = false;
  constructor(private printerService:PrinterService, private modalController:ModalController,public languageProvider:LanguageProvider) {

   }

  ngOnInit() {
    this.loading = true;
    this.printerService.getPrinters().then(x => {
      this.printers = x;
      this.loading = false;
    });
  }
  choosePrinter(printer){
    this.modalController.dismiss(printer);
  }
  dismiss(){
    this.modalController.dismiss();
  }

}
