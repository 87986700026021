import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService, Unit, User } from "../../services/user.service";
import { MenuController, PopoverController } from "@ionic/angular";
import { appVersion } from "../../../environments/models";
import { toggleFlags, UnitService } from "../../services/unit.service";
import { AccountMenuComponent } from "../account/account-menu/account-menu.component";
import { LanguagePipe } from "../../pipes/language.pipe";
import { LanguageProvider } from "../../services/language/language.provider";
import { Title } from "@angular/platform-browser";



@Component({
    selector: "header-menu",
    templateUrl: "./header-menu.component.html",
    styleUrls: ["./header-menu.component.scss"]
})
export class HeaderMenuComponent implements OnInit {
    flags: any = toggleFlags;
    appVersion = appVersion;
    menuGroups: any;
    selectedUnit: Unit;
    user: User;
    darkModeSetting = false;
    selectedMainMenu: any;
    selectedSubMenu: any;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private popoverController: PopoverController, private menuController: MenuController,
        private unitService: UnitService, private languagePipe: LanguagePipe, public languageProvider: LanguageProvider, private titleService: Title) {
        this.userService.selectedUnitObs.subscribe(x => {
            this.selectedUnit = x;
            if (x && x.info && x.info.name)
                this.titleService.setTitle(x.info.name)

        });
        this.userService.user.subscribe(x => {
            this.user = x;
            if (x) this.languageProvider.setLanguage(x.languageCode);
        })
    }
    toggleDarkTheme(ev?) {
        document.body.classList.toggle("theme-dark");
        if (ev)
            window.localStorage.setItem("theme-dark", ev.detail.checked.toString())
    }
    goto(ref) {
        if (typeof (ref) === "undefined")
            return;

        this.router.navigateByUrl(
            `unit/${this.userService.selectedUnit.routeName}${ref}`
        );
    }
    ngOnInit(): void {
        let wantsDark = window.localStorage.getItem("theme-dark");
        if (wantsDark === "true") {
            this.toggleDarkTheme()
            this.darkModeSetting = true;
        }
    }
    mainMenuChanged($event) {
        this.selectedMainMenu = $event.detail.value;

        switch (this.selectedMainMenu) {
            case 'home':
                this.router.navigateByUrl(
                    `unit/${this.userService.selectedUnit.routeName}`
                );
                break;
            case 'reports':
                this.router.navigateByUrl(
                    `unit/${this.userService.selectedUnit.routeName}/settlements`
                );
                break;
            case 'statistics':
                this.router.navigateByUrl(
                    `unit/${this.userService.selectedUnit.routeName}/reports`
                );
                break;
            default:
                break;
        }
    }
    subMenuChanged($event) {
        if (this.userService && this.userService.selectedUnit && this.userService.selectedUnit.routeName) {
            this.selectedSubMenu = $event.detail.value;
            switch (this.selectedSubMenu) {
                case 'news':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/news-stream`
                    );
                    break;
                case 'app':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/app`
                    );
                    break;
                case 'menus':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/menus/(active:active-menu)`
                    );
                    break;
                case 'products':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/products`
                    );
                    break;
                case 'pos':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/possettings`
                    );
                    break;
                case 'staff':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/staff`
                    );
                    break;
                case 'categories':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/misc`
                    );
                    break;
                case 'gallery':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/gallery`
                    );
                    break;
                case 'articleAccounting':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/article-accounting`
                    );
                    break;
                case 'vatAccounting':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/vat-accounting`
                    );
                    break;
                case 'paymentMethods':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/payment-option-accounting`
                    );
                    break;
                case 'info':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/info`
                    );
                    break;
                case 'users':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/settings/users`
                    );
                    break;
                case 'integrations':
                    this.router.navigateByUrl(
                        `unit/${this.userService.selectedUnit.routeName}/addons`
                    );
                    break;
                default:
                    break;
            }
        }
    }
    onSelectedUnit($event) {

        if ($event.detail.value === "all") {
            this.userService.selectedUnitObs.next(undefined);
            this.router.navigateByUrl("unit-selection");
        }
        else {
            this.userService.selectedUnitObs.next(undefined);
            this.router.navigateByUrl(`unit/${$event.detail.value}`);

        }
    }

    async openAccountMenu($event) {
        const popover = await this.popoverController.create({
            component: AccountMenuComponent,
            event: $event,
            translucent: true,
            animated: true
        });
        popover.present();
    }
}
