import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import {
  AngularFireList,
  AngularFireObject,
  AngularFireDatabase
} from "@angular/fire/database";
import { map } from "rxjs/operators";
import { encode } from "blurhash";
import { UnitService } from "./unit.service";
import * as moment from "moment";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../environments/environment"
@Injectable()
export class MenuService {
  clientRef: any;
  private productTypes: ProductType[];
  private productInfoTypes: { bongCategoryTypes: ProductTypeChild[], productCategoryTypes: ProductTypeChild[] };
  private productsRef: AngularFireList<IMenuItem>;
  private customMenusRef: AngularFireList<IMenu>;
  private activeMenuRef: AngularFireObject<IMenu>;
  private currentClientUnitRoute: string;
  products: Observable<IMenuItem[]>;
  customMenus: Observable<IMenu[]>;
  activeMenu = new BehaviorSubject<IMenu>(undefined);
  constructor(private afDatabase: AngularFireDatabase, private unitService: UnitService, private http: HttpClient) {

  }
  generateProductKey() {
    return this.afDatabase.createPushId();
  }
  refsSet(): boolean {
    return typeof (this.productsRef) !== "undefined" ||
      typeof (this.customMenusRef) !== "undefined" ||
      typeof (this.activeMenuRef) !== "undefined";
  }
  getProductTypes(): Observable<ProductType[]> {
    let observable = Observable.create((observer) => {
      if (this.productTypes) {
        observer.next(this.productTypes);
        observer.complete();
      }
      else
        this.afDatabase.database.ref("productTypes").once("value").then(x => {
          this.productTypes = x.val();
          observer.next(this.productTypes);
          observer.complete;
        });
    });
    return observable;
  }
  async getBongCategoryTypes() {
    if (!this.productInfoTypes) {
      this.productInfoTypes = (await this.afDatabase.database.ref("types").once("value")).val();
      return this.productInfoTypes.bongCategoryTypes;
    }
    else
      return this.productInfoTypes.bongCategoryTypes;
  }
  async getProductCategoryTypes() {
    if (!this.productInfoTypes) {
      this.productInfoTypes = (await this.afDatabase.database.ref("types").once("value")).val();
      return this.productInfoTypes.productCategoryTypes;
    }
    else
      return this.productInfoTypes.productCategoryTypes;
  }
  subscribeToCustomMenu(key) {
    return this.afDatabase.object<IMenu>(
      `Clients/${this.currentClientUnitRoute}/customMenus/${key}`
    ).snapshotChanges()
      .pipe(
        map(c => ({ key: c.payload.key, ...c.payload.val() })
        )
      );
  }
  setupDbRefsForRoute(routeName: string) {
    if (routeName === this.currentClientUnitRoute && this.refsSet()) {
      return;
    }
    this.currentClientUnitRoute = routeName;
    this.productsRef = this.afDatabase.list<IMenuItem>(
      `Clients/${routeName}/menuItems`
    );
    this.products = this.productsRef
      .snapshotChanges()
      .pipe(
        map(changes => changes.map(c => (
          { key: c.payload.key, ...c.payload.val() }
        ))
        ));
    this.customMenusRef = this.afDatabase.list<IMenu>(
      `Clients/${routeName}/customMenus`
    );
    this.customMenus = this.customMenusRef
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        )
      );
    this.activeMenuRef = this.afDatabase.object<IMenu>(
      `Clients/${routeName}/activeMenu`
    );
    this.activeMenuRef.valueChanges().subscribe(x => {
      this.activeMenu.next(x);
    });
    this.unitService.setToggleFlags(routeName);
  }
  getProduct(id: string, routeName: string): any {
    return this.afDatabase.object<IMenuItem>(`Clients/${routeName}/menuItems/${id}`)
      .snapshotChanges();
  }
  async addProduct(item, key?): Promise<any> {
    if (key) {
      await this.afDatabase.database.ref(`Clients/${this.currentClientUnitRoute}/menuItems/${key}`).set(item);
      return key;
    }
    return this.productsRef.push(item);
  }
  updateProduct(item, options?: { key, routeName }) {
    if (options) {
      if (!options.key || !options.routeName) {
        alert("Key and Routename needs to be set as options. Please contact support");
      }
      this.afDatabase.database.ref(`Clients/${options.routeName}/menuItems/${options.key}`).set(item);
    }
    else
      return this.productsRef.update(item.key, item);
  }
  async updateProductArray(items: IMenuItem[], routeName) {
    let promises = [];
    for (let index = 0; index < items.length; index++) {
      const element = items[index];
      promises.push(this.afDatabase.database.ref(`Clients/${routeName}/menuItems/${element.key}`).set(element));
    }
    await Promise.all(promises);
    return true;
  }


  async updateActiveMenuItemEuDb(routeName, item, itemIndex, categoryIndex) {
    let body = {
      routeName: routeName,
      item: item,
      itemIndex: itemIndex,
      categoryIndex: categoryIndex
    }
    let url = `${environment.pubQueueApiUrl}/updateActiveMenuItemForRoute`;
    return await this.http.post<any[]>(url, body).toPromise();
  }

  async activateEuMenu(routeName, menu, options) {
    let body = {
      routeName: routeName,
      customMenu: menu,
      options: options
    }
    let url = `${environment.pubQueueApiUrl}/setActiveMenuForRoute`;
    return await this.http.post<any[]>(url, body).toPromise();
  }


  setActiveMenu(routeName, customMenu: IMenu, options?: { key, routeName }) {
    for (var category of customMenu.categories) {
      for (var item of category.items) {
        if (item.ImageUrl === "img/meny/")
          item.ImageUrl =
            "https://firebasestorage.googleapis.com/v0/b/pq-dev.appspot.com/o/menuPlaceholder.jpg?alt=media";
      }
    }
    if (options) {
      if (!options.key || !options.routeName) {
        alert("Not enough options. please contact support");
        return;
      }
      this.activateEuMenu(routeName, customMenu, options);
      this.afDatabase.database.ref(`Clients/${options.routeName}/activeMenu`).set(customMenu);
    }
    else {
      this.activateEuMenu(routeName, customMenu, options);
      return this.activeMenuRef.set(customMenu);
    }
  }
  updateActiveMenuItem(itemProps, itemIndex, categoryIndex: number | string) {
    this.updateActiveMenuItemEuDb(this.currentClientUnitRoute, itemProps, itemIndex, categoryIndex)
    return this.afDatabase.database.ref(`Clients/${this.currentClientUnitRoute}/activeMenu/categories/${categoryIndex}/items/${itemIndex}`).update(itemProps).then(x => {
      return x;
    }).catch(err => {
      throw err;
    });
  }

  saveCustomMenu(menu): Promise<any> {
    var promise = new Promise((resolve, reject) => {
      this.customMenusRef.set(menu.key, menu).then(x => {
        resolve(true);
      });
    });
    return promise;
  }

  async createCustomMenu(menu, options?: { key, routeName }): Promise<any> {
    if (options) {
      if (!options.key || !options.routeName) { alert("Not enough options. please contact support"); return; }
      return await this.afDatabase.database.ref(`Clients/${options.routeName}/customMenus/${options.key}`).set(menu);
    }
    else
      return await this.customMenusRef.push(menu)
  }
  async copyCustomMenu(menu) {
    let newMenu = JSON.parse(JSON.stringify(menu));
    newMenu.name = `${newMenu.name} Kopia ${moment().format("YYYY-MM-DD kk:mm")}`
    delete newMenu.key;
    const ref = this.customMenusRef.push(newMenu);
    newMenu.key = ref.key;
  }
  deleteCustomMenu(menu, options?: { key, routeName }) {
    if (options) {
      return this.afDatabase.database.ref(`Clients/${options.routeName}/customMenus/${options.key}`).set(null);
    }
    else
      return this.customMenusRef.remove(menu.key);
  }
  deleteProduct(key) {
    this.productsRef.remove(key);
  }
  async deleteAllProducts() {
    return this.productsRef.remove();
  }
  getCategories() {
    return [
      {
        name: "Mat",
        id: "0"
      },
      {
        name: "Öl",
        id: "3"
      },
      {
        name: "Vin",
        id: "4"
      },
      {
        name: "Alkoholfri dryck",
        id: "1"
      },
      {
        name: "Cider",
        id: "2"
      },
      {
        name: "Alkoläsk",
        id: "6"
      },
      {
        name: "Sprit",
        id: "5"
      },
      {
        name: "Övrigt",
        id: "9"
      }
    ];
  }
  getCategoryTypes() {
    return ["Mat", "Dryck"];
  }
  loadImage = async src =>
    new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "Anonymous";
      img.onload = () => resolve(img);
      img.onerror = (...args) => reject(args);
      img.src = src;
    });

  getImageData = image => {
    const canvas = document.createElement("canvas");

    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext("2d");
    context.drawImage(image, 0, 0);

    return context.getImageData(0, 0, image.width, image.height);
  };
  encodeImageToBlurhash = async imageUrl => {
    if (!imageUrl) {
      console.log("no imgurl");
      return;

    }
    const image = await this.loadImage(imageUrl);
    const imageData = this.getImageData(image);
    return encode(imageData.data, imageData.width, imageData.height, 4, 4);
  };
  async saveWeeklyMenuDescription(menuDescription, enabled: boolean) {
    let routeName = this.currentClientUnitRoute;
    return await this.afDatabase.database.ref(`Clients/${routeName}/AppInfo/Context/weeklyMenu`).update({ enabled, content: menuDescription });

  }
  async getWeeklyMenuDescription() {
    let routeName = this.currentClientUnitRoute;
    return (await this.afDatabase.database.ref(`Clients/${routeName}/AppInfo/Context/weeklyMenu`).once("value")).val();
  }
}

export interface IMenu {
  key: string;
  isActive: boolean;
  name: string;
  categories: IMenuCategory[];
  timeSchedule: ITimeSchedule;
  deliveryOptions?: any;
}

export interface ITimeSchedule {
  weekdays: any[];
  activationTime: string;
  active: boolean;
}


export interface IMenuCategory {
  name: string;
  type: "Mat" | "Dryck";
  items: IMenuItem[];
}
export interface IMenuItem {
  key: string;
  Category?: string;
  Name: string;
  NameInternal: string;
  Description: string;
  DescriptionLong: string;
  Cost: number;
  Volume: number;
  ImageUrl: string;
  blurHash?: string;
  tags?: string[];
  isUsingBuildABurger?: boolean;
  steps?: any[];
  options?: IMenuItemOption[];
  vatInfo: {
    articleAccountId: string, articleAccountings?: { percent: number, id: string }[],
    exceptions?: { vatInfo: { articleAccountId: string }, id: string, reason: string }[]
  };
  type: { productTypeId: number, containsAlcohol: boolean, bongCategoryType?: ProductTypeChild, productCategoryType?: ProductTypeChild };
  purchaseCost?: number;
  externalOrigin?: { key: string, externalId: string, vatPercentage?: number };
  preferredMenuCategory?: string;
  useStockBalance: boolean;
  showStockBalance: boolean;
  enabled: boolean;
  disabledReason: string;
  stockBalance: number;
  isCombinedProduct: boolean;
  combinedVatInfo: { productId: string, percent: number }[];
}
export interface ProductTypeChild {
  id: string;
  productTypeId: string;
  label: string;
}
export interface IMenuItemOption {
  choices: IOptionChoice[];
  cost: number;
  text: string;
  type: string;
  step?: string
  linkedProduct?: IMenuItem;
}
export interface IOptionChoice {
  cost: number;
  text: string;
  linkedProduct: IMenuItem;
}

export class ProductType {
  id: number;
  name: string;
}