import { Component, OnInit } from '@angular/core';
import { UserService, Unit, User } from '../../services/user.service';
import { NewsService } from '../../services/news.service';
import { ModalController, AlertController, LoadingController, ToastController } from '@ionic/angular';
import { ImageGalleryComponent } from '../image-gallery/image-gallery.component';
import * as moment from "moment";
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { IMenuItem, MenuService } from '../../services/menu.service';
import { startWith, map } from 'rxjs/operators';
import { LanguagePipe } from '../../pipes/language.pipe';
import { LanguageProvider } from '../../services/language/language.provider';

@Component({
  selector: 'app-news-stream',
  templateUrl: './news-stream.component.html',
  styleUrls: ['./news-stream.component.scss'],
})
export class NewsStreamComponent implements OnInit {
  stateCtrl = new FormControl();
  filteredProducts: Observable<IMenuItem[]>;
  news = [];
  unit: Unit;
  user: User;
  oldNewsItem;
  currentlyEditingNews;
  generalMenuItems: IMenuItem[];
  constructor(private toastController: ToastController, private alertController: AlertController, private userService: UserService,
    private newsService: NewsService, private modalController: ModalController, private menuService: MenuService, private loadingController: LoadingController,
    private languagePipe: LanguagePipe, public languageProvider: LanguageProvider) {
  }

  async edit(news) {
    if (this.oldNewsItem && news.key === this.oldNewsItem.key) {
      news.editMode = false;
      this.currentlyEditingNews = false;
      this.oldNewsItem = null;
      return;
    };
    if (this.oldNewsItem) {
      let alert = await this.alertController.create({
        header: this.languagePipe.transform("Varning", 'WARNING', this.languageProvider.currentLanguage),
        message: this.languagePipe.transform("Du redigerar redan en annan post. Är du säker på att du vill förlora data?", 'ALREADY_EDITING', this.languageProvider.currentLanguage),
        buttons: [{
          role: "cancel",
          text: this.languagePipe.transform("Avbryt", '', this.languageProvider.currentLanguage)
        },
        { role: "confirm", text: this.languagePipe.transform("Ok", 'OK', this.languageProvider.currentLanguage) }]
      });
      alert.present();
      let result = await alert.onDidDismiss();
      if (result.role === "cancel")
        return;
      let tempNews = JSON.parse(JSON.stringify(this.oldNewsItem));
      this.currentlyEditingNews.imageUrl = tempNews.imageUrl;
      this.currentlyEditingNews.title = tempNews.title;
      this.currentlyEditingNews.text = tempNews.text;
      this.currentlyEditingNews.editMode = false;
    }
    this.currentlyEditingNews = news;
    this.oldNewsItem = JSON.parse(JSON.stringify(news));
    if (news.displayProduct) {
      let product = this.generalMenuItems.find(x => x.key === news.displayProduct.productId);
      this.stateCtrl.setValue(product);
    }
    news.editMode = true;
  }
  async abort(news) {
    let alert = await this.alertController.create({
      header: this.languagePipe.transform("Avbryt?", 'ABORT_Q', this.languageProvider.currentLanguage),
      message: this.languagePipe.transform("Är du säker på att du vill avbryta? Ändringar kommer att gå förlorade", 'CONFIRM_CHANGES', this.languageProvider.currentLanguage),
      buttons: [{
        role: "cancel",
        text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage)
      },
      { role: "confirm", text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage) }]
    });
    alert.present();
    let result = await alert.onDidDismiss();
    if (result.role === "cancel")
      return;
    let tempNews = JSON.parse(JSON.stringify(this.oldNewsItem));
    news.imageUrl = tempNews.imageUrl;
    news.title = tempNews.title;
    news.text = tempNews.text;
    news.editMode = false;
    this.oldNewsItem = null;
    this.stateCtrl.setValue(null);
  }
  async save(news) {
    if (!this.newsIsValid(news)) {
      let toast = await this.toastController.create({
        color: "danger",
        message: this.languagePipe.transform("Du måste fylla i alla fält förutom titel och text", 'FILL_ALL_FIELDS', this.languageProvider.currentLanguage),
        duration: 2500,
        position: "top",
        animated: true
      });
      toast.present();
      return;
    }
    this.oldNewsItem = null;
    news.editMode = false;
    let result = await this.newsService.saveNews(this.unit.routeName, news);
    console.log(result);
    this.stateCtrl.setValue(null);
  }
  async saveAllNews() {
    let loading = await this.loadingController.create({
      message: this.languagePipe.transform("Sparar nyheter", 'SAVE_NEWS', this.languageProvider.currentLanguage),
      spinner: "bubbles",
      duration: 20000
    });
    loading.present();
    try {
      await this.newsService.saveArray(this.unit.routeName, this.news);
      loading.dismiss();
      let toast = await this.toastController.create({
        color: "success",
        animated: true,
        message: this.languagePipe.transform("Sparat!", 'SAVED', this.languageProvider.currentLanguage),
        duration: 2000,
        position: "top"
      });
      toast.present();
    } catch (error) {
      loading.dismiss();
      let toast = await this.toastController.create({
        color: "danger",
        animated: true,
        message: this.languagePipe.transform("Något gick fel!", 'SOMETHING_WRONG', this.languageProvider.currentLanguage),
        duration: 2000,
        position: "top"
      });
      toast.present();
    }

  }
  newsIsValid(news) {
    switch (news.type) {
      case "offer":
        return news.displayProduct.tab && news.displayProduct.productId && news.displayProduct.additionalDescription;
      case "product":
        return news.displayProduct.tab && news.displayProduct.productId;
      case "url":
        return news.url && news.url.startsWith("http");
      case "article":
        return news.displayProduct.additionalDescription;
      default:
        return true;
    }
  }
  async publish(news) {
    news.published = true;
    let result = await this.newsService.saveNews(this.unit.routeName, news);
    console.log(result);
  }
  addNewsItem() {
    this.news.unshift({
      key: this.newsService.getKey(),
      imageUrl: "",
      text: this.languagePipe.transform("Huvudtext", 'MAIN_TEXT', this.languageProvider.currentLanguage),
      title: this.languagePipe.transform("Ny titel", 'NEW_TITLE', this.languageProvider.currentLanguage),
      type: "static",
      displayProduct: {},
      createdUTC: moment().utc().unix(),
      editMode: false
    });
  }
  async remove(news) {
    let alert = await this.alertController.create({
      header: this.languagePipe.transform("Ta bort inlägg?", 'REMOVE_POST', this.languageProvider.currentLanguage),
      backdropDismiss: false,
      message: this.languagePipe.transform("Är du säker på att du vill ta bort inlägget?", 'REMOVE_POST_CONFIRM', this.languageProvider.currentLanguage),
      buttons: [{
        role: "cancel",
        text: this.languagePipe.transform("Nej", 'NO', this.languageProvider.currentLanguage)
      },
      { role: "confirm", text: this.languagePipe.transform("Ja", 'YES', this.languageProvider.currentLanguage) }]
    });
    alert.present();
    let result = await alert.onDidDismiss();
    if (result.role === "cancel")
      return;

    this.newsService.removeNews(this.unit.routeName, news);
    if (this.user.units.length > 1) {
      let removeAllAlert = await this.alertController.create({
        header: this.languagePipe.transform("Ta bort för alla?", 'REMOVE_FOR_ALL', this.languageProvider.currentLanguage),
        backdropDismiss: false,
        message: this.languagePipe.transform("Vill du även ta bort inlägget om det existerar på andra restaurangenheter?", 'REMOVE_FOR_OTHERS', this.languageProvider.currentLanguage),
        buttons: [{
          role: "cancel",
          text: this.languagePipe.transform("Nej", 'No', this.languageProvider.currentLanguage)
        },
        { role: "confirm", text: this.languagePipe.transform("Ja", 'Yes', this.languageProvider.currentLanguage) }]
      });
      removeAllAlert.present();
      let result = await removeAllAlert.onDidDismiss();
      if (result.role === "confirm") {
        let toast = await this.toastController.create({
          duration: 2500,
          position: "top"
        });
        try {
          await this.user.units.forEach(async x => {
            await this.newsService.removeNews(x.routeName, news);
          });
          toast.color = "success";
          toast.message = this.languagePipe.transform("Tog bort från alla restauranger", 'REMOVED_FROM_ALL', this.languageProvider.currentLanguage);
        } catch (error) {
          toast.color = "danger";
          toast.message = this.languagePipe.transform("Något gick fel", 'SOMETHING_WRONG', this.languageProvider.currentLanguage);
        }
        finally {
          toast.present();
          this.refreshNews();
        }

      };

    }

    this.oldNewsItem = null;
    this.currentlyEditingNews = null;
    this.refreshNews();
  }
  refreshNews() {
    let sub = this.newsService.getNews(this.unit.routeName).subscribe(news => {
      this.news = news;
      this.news.sort((a, b) => {
        if (typeof (a.index) === "undefined" || typeof (b.index) === "undefined")
          return -1;
        return a.index - b.index;
      })
      setTimeout(() => {
        sub.unsubscribe();
      }, 100);
    });
  }
  ngOnInit() {
    let sub = this.menuService.products.subscribe(x => {
      this.generalMenuItems = x;
      sub.unsubscribe();
    })
    this.filteredProducts = this.stateCtrl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
    this.userService.selectedUnitObs.subscribe(x => {
      if (!x) return;
      this.unit = x;
      this.user = this.userService.user.value;
      this.refreshNews();
    });

  }
  async onSearchResultSelected(event, news) {
    console.log(event.option.value);
    news.displayProduct.productId = event.option.value.key;
  }
  displayFn(item?: IMenuItem): string | undefined {
    return item ? item.Name : undefined;
  }
  additionalDescriptionChanged(ev, newsItem) {
    newsItem.displayProduct.additionalDescription = ev.detail.value;
  }
  private _filter(value: string): IMenuItem[] {
    if (typeof value !== "string" || !this.generalMenuItems) return;
    const filterValue = value.toLowerCase();

    return this.generalMenuItems.filter(option =>
      option.Name.toLowerCase().includes(filterValue)
    );
  }
  async selectFromGallery(news) {
    const modal = await this.modalController.create({
      component: ImageGalleryComponent,
      componentProps: { asModal: true }
    });
    modal.present();
    let result = await modal.onDidDismiss();
    if (result.data) {
      news.imageUrl = result.data.imageUrl;
      news.blurHash = result.data.blurHash
    }
  }
  async share(newsItem) {
    newsItem.editMode = false;
    this.oldNewsItem = null;
    let units = await this.userService.showUnitsSelectAlert(this.user.units);
    if (units.length > 0) {
      let loading = await this.loadingController.create({
        spinner: "bubbles",
        message: ``
      });
      loading.present();
      try {
        for (let index = 0; index < units.length; index++) {
          const element = units[index];
          loading.message = this.languagePipe.transform(`Delar nyheten med ${element.info.name}`, 'SHARE_NEWS_WITH', this.languageProvider.currentLanguage, [element.info.name]);
          await this.newsService.addNews(element.routeName, newsItem, newsItem.key);
        }
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "success",
          message: this.languagePipe.transform(`Delade ${newsItem.title ? newsItem.title : 'nyheten'}`, 'NEWS_SHARED', this.languageProvider.currentLanguage, [newsItem.title]),
          duration: 2500,
          position: "top"
        });
        toast.present();
      } catch (error) {
        alert(JSON.stringify(error));
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "danger",
          message: this.languagePipe.transform("Något gick fel", 'SOMETHING_WRONG', this.languageProvider.currentLanguage),
          duration: 1500,
          position: "top"
        });
        toast.present();
      }
    }
  }
  async shareNews() {
    let units = await this.userService.showUnitsSelectAlert(this.user.units);
    if (units.length > 0) {
      let loading = await this.loadingController.create({
        spinner: "bubbles",
        message: ``
      });
      loading.present();
      try {
        for (let index = 0; index < units.length; index++) {
          const element = units[index];
          loading.message = this.languagePipe.transform(`Sparar tidslinjen för ${element.info.name}`, 'TIMELINE_SAVED_FOR', this.languageProvider.currentLanguage, [element.info.name]);
          await this.newsService.saveArray(element.routeName, this.news);
        }
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "success",
          message: this.languagePipe.transform("Delade tidslinjen!", 'TIMELINE_SHARED', this.languageProvider.currentLanguage),
          duration: 2500,
          position: "top"
        });
        toast.present();
      } catch (error) {
        alert(JSON.stringify(error));
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "danger",
          message: this.languagePipe.transform("Något gick fel", 'SOMETHING_WRONG', this.languageProvider.currentLanguage),
          duration: 1500,
          position: "top"
        });
        toast.present();
      }
    }
  }
  textChanged($event, news) {
    news.text = $event.detail.value;
  }
  typeChanged(event, news) {
    if (!news.displayProduct)
      news.displayProduct = {};
    news.type = event.detail.value;
  }
  displayProductTabChanged(event, news) {
    if (!news.displayProduct)
      news.displayProduct = {};
    news.displayProduct.tab = event.detail.value;

  }
  async onDropped(ev) {
    this.swap(ev.detail.from, ev.detail.to);
    ev.detail.complete();
  }
  swap(index1, index2) {
    var temp = this.news[index1];
    this.news.splice(index1, 1);
    this.news.splice(index2, 0, temp);
  }




}
