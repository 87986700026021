import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';

@Injectable()
export class OrderService {
    constructor(private afDatabase:AngularFireDatabase,){
    }
    
    ngOnInit(){
        console.log("orderservice init");
    }

    clearAllActiveOrdersInDb(){
        var ref = this.afDatabase.database.ref("Users");
        ref.orderByChild("activeOrders").startAt("").once("value", (data) => {
            var s = data.val();            

            for (const key in s) {
                if (s.hasOwnProperty(key)) {
                    const element = s[key];
                    this.afDatabase.database.ref(`Users/${key}/activeOrders`).set(null, (err) => {
                        
                    })
                }
            }
        },err => {
            throw err;
        }
        );
    }
}