import { Component, OnInit } from "@angular/core"
import { Router, ActivatedRoute } from "@angular/router"

import { UserService } from "../../services/user.service"
import { HttpClient } from "@angular/common/http"
import { environment } from "../../../environments/environment"
import { AngularFireAuth } from "@angular/fire/auth"
import { AlertController, LoadingController } from "@ionic/angular"
import { LanguagePipe } from "../../pipes/language.pipe"
import { LanguageProvider } from "../../services/language/language.provider"

@Component({
    selector: "app-login",
    templateUrl: "./login.page.html",
    styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {
    title = "Login to joboffice-admin"
    returnUrl = ""
    userFetched = false
    userEmail = ""
    userPassword = ""
    userPhoneNumber = ""
    userPhoneNumberPrefix = "+46"
    signInIsInProgress = false
    phoneVerificationNumber = ""
    clientWindow: any
    sliderLoginIndex = 0
    sliderEnterPhoneNumberIndex = 1
    sliderVerifyPhoneNumberIndex = 2
    sliderSignupIndex = 3
    accesstoken: string

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private afAuth: AngularFireAuth,
        private userService: UserService,
        private http: HttpClient,
        private alertController: AlertController,
        private languagePipe: LanguagePipe,
        public languageProvider: LanguageProvider,
        private loadingCtrl: LoadingController
    ) { }
    verifyAccessTokenAndLogin(token: string) {
        this.signInIsInProgress = true
        this.http
            .post<any>(`${environment.primeApiUrl}/verifyToken`, {
                token: token,
            })
            .subscribe((x) => {
                this.afAuth.signInWithCustomToken(x.data).then((p) => {
                    setTimeout(() => {
                        this.signInIsInProgress = false
                    }, 500)
                })
            })
    }
    ionViewWillEnter() {
        this.userService.hideSidebar = true;
    }
    ngOnInit() {
        this.activatedRoute.queryParams.subscribe((x) => {
            this.returnUrl = x["returnUrl"]
            this.accesstoken = x["t"]
            if (this.accesstoken)
                this.verifyAccessTokenAndLogin(this.accesstoken)
        })
        let sub = this.userService.user.subscribe((x) => {
            if (typeof x !== "undefined") this.userFetched = true
            if (x && this.userFetched) {
                this.redirect()
                sub.unsubscribe()
            }
            if (!x && this.userFetched) {
                this.signInIsInProgress = false
                sub.unsubscribe()
            }
        })
        this.signInIsInProgress = true
        let afSub = this.afAuth.user.subscribe((x) => {
            if (!x) this.signInIsInProgress = false
            afSub.unsubscribe()
        })
    }
    onKey($event) {
        if ($event.code === "Enter" || $event.code === "NumpadEnter")
            this.login()
    }
    async resetPassword() {
        let alert = await this.alertController.create({
            header: this.languagePipe.transform("Email för återställande", 'RESET_EMAIL_HEAD', this.languageProvider.currentLanguage),
            subHeader: this.languagePipe.transform("Fyll i din email", 'RESET_EMAIL_TXT', this.languageProvider.currentLanguage),
            inputs: [{ name: "email", placeholder: "Email" }],
            buttons: [
                {
                    text: "OK",
                    role: "confirm",
                },
                {
                    text: this.languagePipe.transform("Avbryt", 'CANCEL', this.languageProvider.currentLanguage),
                    role: "cancel",
                },
            ],
        })
        alert.present()
        let result = await alert.onDidDismiss()
        if (
            result.role === "confirm" &&
            result.data &&
            result.data.values &&
            result.data.values.email
        ) {
            let loading = await this.loadingCtrl.create({
                spinner: "crescent",
                duration: 10000
            });
            loading.present();
            try {
                //await this.afAuth.auth.sendPasswordResetEmail(result.data.values.email);
                //loading.dismiss();
            } catch (error) {
                loading.dismiss();
            }


        }
    }
    redirect() {
        if (this.returnUrl === "/" || !this.returnUrl) {
            this.router.navigateByUrl("/unit-selection")
            this.resetState()
        } else this.router.navigateByUrl(this.returnUrl)
    }
    async login() {
        this.signInIsInProgress = true
        var success = false
        try {
            var ctx = await this.afAuth.signInWithEmailAndPassword(
                this.userEmail,
                this.userPassword
            )
            success = true
            this.redirect()
            this.signInIsInProgress = false
        } catch (err) {
            alert(err)
            this.signInIsInProgress = false
        }
    }
    // loginWithGoogle() {
    //   this.signInIsInProgress = true;
    //   this.afAuth.auth.signInWithPopup(new auth.GoogleAuthProvider()).then(x => {
    //     this.signInIsInProgress = false;
    //     this.redirect();
    //   });
    // }
    focus(event) {
        event.target.focus()
    }

    signup() { }
    gotoLoginSlide() { }
    async registerEmail() {
        var createSuccess = false
        var signInsuccess = false
        try {
            var userCtx = await this.afAuth.createUserWithEmailAndPassword(
                this.userEmail,
                this.userPassword
            )
            createSuccess = true
        } catch (e) {
            alert(e)
        }
        if (createSuccess) {
            try {
                var signedIn = await this.afAuth.signInWithEmailAndPassword(
                    this.userEmail,
                    this.userPassword
                )
                signInsuccess = true
            } catch (er) {
                alert(er)
            }
        }
    }
    resetState() {
        this.userEmail = ""
        this.userPassword = ""
        this.returnUrl = ""
        // this.slider.slideTo(this.sliderLoginIndex);
        this.signInIsInProgress = false
    }
}
