import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { UserService, Unit, User } from "../../services/user.service";
import { MenuController, PopoverController } from "@ionic/angular";
import { appVersion } from "../../../environments/models";
import { toggleFlags, UnitService } from "../../services/unit.service";
import { AccountMenuComponent } from "../account/account-menu/account-menu.component";
import { LanguagePipe } from "../../pipes/language.pipe";
import { LanguageProvider } from "../../services/language/language.provider";



@Component({
    selector: "pos-sidebar",
    templateUrl: "./sidebar.component.html",
    styleUrls: ["./sidebar.component.scss"]
})
export class SidebarComponent implements OnInit {
    flags: any = toggleFlags;
    appVersion = appVersion;
    menuGroups: any;
    selectedUnit: Unit;
    user: User;
    darkModeSetting = false;
    constructor(private router: Router, private activatedRoute: ActivatedRoute, private userService: UserService, private popoverController: PopoverController, private menuController: MenuController,
        private unitService: UnitService, private languagePipe: LanguagePipe, public languageProvider: LanguageProvider) {
        this.createMenuGroups();
        this.userService.selectedUnitObs.subscribe(x => {
            this.selectedUnit = x;
        });
        this.userService.user.subscribe(x => {
            this.user = x;
            if (x) this.languageProvider.setLanguage(x.languageCode);
            this.createMenuGroups();
        })
        this.languageProvider.currentLanguage
    }
    toggleDarkTheme(ev?) {

        document.body.classList.toggle("theme-dark");
        if (ev)
            window.localStorage.setItem("theme-dark", ev.detail.checked.toString())
    }
    goto(ref) {
        if (typeof (ref) === "undefined")
            return;

        this.router.navigateByUrl(
            `unit/${this.userService.selectedUnit.routeName}${ref}`
        );
    }
    ngOnInit(): void {
        let wantsDark = window.localStorage.getItem("theme-dark");
        if (wantsDark === "true") {
            this.toggleDarkTheme()
            this.darkModeSetting = true;
        }
    }
    onSelectedUnit($event) {

        if ($event.detail.value === "all") {
            this.userService.selectedUnitObs.next(undefined);
            this.router.navigateByUrl("unit-selection");
        }
        else {
            this.userService.selectedUnitObs.next(undefined);
            this.router.navigateByUrl(`unit/${$event.detail.value}`);

        }
    }
    goToAddons() {

        this.router.navigateByUrl(
            `unit/${this.userService.selectedUnit.routeName}/addons`
        );
    }
    goToReports() {

        this.router.navigateByUrl(
            `unit/${this.userService.selectedUnit.routeName}/reports`
        );
    }
    goToGallery() {

        this.router.navigateByUrl(
            `unit/${this.userService.selectedUnit.routeName}/gallery`
        );
    }
    async createMenuGroups() {
        this.menuGroups = [
            {
                translationKey: "menuGroupName_home",
                name: this.languagePipe.transform("Hem", 'HOME', this.languageProvider.currentLanguage),
                icon: "home",
                route: "",
                sortIndex: 0,
                items: [
                ]
            },
            {
                translationKey: "menuGroupName_economy",
                name: this.languagePipe.transform("Rapporter", 'REPORTS', this.languageProvider.currentLanguage),
                icon: "clipboard",
                route: "/settlements",
                sortIndex: 0,
                items: []
            },
            {
                translationKey: "menuGroupName_administration",
                name: this.languagePipe.transform("Administration", 'ADMIN', this.languageProvider.currentLanguage),
                sortIndex: 1,
                icon: "construct",
                items: [
                    {
                        translationKey: "menuGroup_itemName_newsstream",
                        name: this.languagePipe.transform("Nyhetsflöde", 'NEWS', this.languageProvider.currentLanguage),
                        route: "/news-stream",
                        sortIndex: 1
                    },
                    {
                        translationKey: "menuGroup_itemName_menu",
                        name: this.languagePipe.transform("App", 'APP', this.languageProvider.currentLanguage),
                        route: "/settings/app",
                        sortIndex: 1
                    },
                    {
                        translationKey: "menuGroup_itemName_menu",
                        name: this.languagePipe.transform("Menyer", 'MENUS', this.languageProvider.currentLanguage),
                        route: "/menus/(active:active-menu)",
                        sortIndex: 1
                    },
                    {
                        translationKey: "menuGroup_itemName_products",
                        name: this.languagePipe.transform("Produkter", 'PRODUCTS', this.languageProvider.currentLanguage),
                        route: "/products",
                        sortIndex: 0
                    },

                    {
                        translationKey: "menuGroup_itemName_pos",
                        name: this.languagePipe.transform("Kassa", 'POS', this.languageProvider.currentLanguage),
                        route: "/possettings",
                        sortIndex: 2
                    },
                    {
                        translationKey: "menuGroup_itemName_staff",
                        name: this.languagePipe.transform("Personalkort", 'STAFF_CARD', this.languageProvider.currentLanguage),
                        route: "/staff",
                        sortIndex: 2
                    },
                    {
                        translationKey: "menuGroup_itemName_misc",
                        name: this.languagePipe.transform("Misc.", '', this.languageProvider.currentLanguage),
                        route: "/misc",
                        sortIndex: 2
                    }
                ]
            },
            {
                translationKey: "menuGroupName_settings",
                name: this.languagePipe.transform("Inställningar", 'SETTINGS', this.languageProvider.currentLanguage),
                sortIndex: 2,
                icon: "settings",
                items: [
                    {
                        translationKey: "menuGroup_itemName_articleAccountings",
                        name: this.languagePipe.transform("Konteringar", 'ACOUNTINGS', this.languageProvider.currentLanguage),
                        route: "/settings/article-accounting",
                        sortIndex: 3
                        // icon: "bookmarks"
                    },
                    {
                        translationKey: "menuGroup_itemName_articleAccountings",
                        name: this.languagePipe.transform("Momssatser", 'VAT_RATES', this.languageProvider.currentLanguage),
                        route: "/settings/vat-accounting",
                        sortIndex: 3
                        // icon: "bookmarks"
                    },
                    {
                        translationKey: "menuGroup_itemName_articleAccountings",
                        name: this.languagePipe.transform("Betalsätt", 'PAYMENT_TYPES', this.languageProvider.currentLanguage),
                        route: "/settings/payment-option-accounting",
                        sortIndex: 3
                        // icon: "bookmarks"
                    },
                    {
                        translationKey: "menuGroup_itemName_articleAccountings",
                        name: this.languagePipe.transform("Restauranginformation", 'RESTURANT_INFO', this.languageProvider.currentLanguage),
                        route: "/settings/info",
                        sortIndex: 3
                        // icon: "bookmarks"
                    },
                    {
                        translationKey: "menuGroup_itemName_articleAccountings",
                        name: this.languagePipe.transform("Användare", 'USERS', this.languageProvider.currentLanguage),
                        route: "/settings/users",
                        sortIndex: 3
                        // icon: "bookmarks"
                    }
                ]
            }
        ];
    }
    async openAccountMenu($event) {
        const popover = await this.popoverController.create({
            component: AccountMenuComponent,
            event: $event,
            translucent: true,
            animated: true
        });
        popover.present();
    }
}
