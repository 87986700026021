export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'CARD_ADDED_FOR': return `'Kort lagt til ${parameters[0]}`;
            case 'REMOVE_NAME': return `'Slette ${parameters[0]}?`;
            case 'REMOVED_IMAGE': return `'Fjernet ${parameters[0]}`;
            case 'PRODUCTS_UPDATED': return `'${parameters[0]}-produkter oppdatert`;
            case 'SAVING_MENU': return `'Lagrer menyen for ${parameters[0]}`;
            case 'REMOVE_MENU': return `'Slette ${parameters[0]}?`;
            case 'SAVING_GALLERY': return `'Lagrer i bilder for ${parameters[0]}`;
            case 'REMOVED_IMAGE': return `'Fjernet ${parameters[0]}`;
            case 'NUMBER_ARTICLES': return `'${parameters[0]} artikler`;
            case 'UPDATE_ITEM': return `'Oppdatert ${parameters[0]}`;
            case 'PRODUCT_ADDED': return `'${parameters[0]} lagt til`;
            case 'PRODUCT_REMOVED': return `'${parameters[0]} fjernet`;
            case 'REMOVE_PRODUCT_CATEGORY': return `'Vil du slette ${parameters[0]}?`;
            case 'REMOVE_BONG_CATEGORY': return `'Vil du slette ${parameters[0]}?`;
            case 'SHARE_NEWS_WITH': return `'Deler nyhetene med ${parameters[0]}`;
            case 'NEWS_SHARED': return `'Delt ${parameters[0]}`;
            case 'TIMELINE_SAVED_FOR': return `'Lagrer tidslinjen for ${parameters[0]}`;
            case 'SHARING_OFFER_WITH': return `'Deler tilbudet med ${parameters[0]}`;
            case 'OFFER_SHARED': return `'Delt ${parameters[0]}`;
            case 'REMOVE_COUPON': return `'Slette ${parameters[0]}?`;
            case 'LINKED_PRODUCT': return `'Koblet ${parameters[0]}`;
            case 'COPY_FROM': return `'Kopier fra ${parameters[0]}?`;
            case 'REMOVE': return `'Slette ${parameters[0]}?`;
            case 'REMOVING': return `'Slette ${parameters[0]}?`;
            case 'ADDED': return `'Lagt til ${parameters[0]}`;
            case 'PRODUCT_SAVED': return `'${parameters[0]} ble reddet`;
            case 'SAVING_PRODUCT': return `'Lagrer produkt for ${parameters[0]}`;
            case 'CONFIRM_REMOVE_ACCOUNTING': return `'Er du sikker på at du vil slette ${parameters[0]}?`;
            case 'REMOVE_ACCOUNTING': return `'${parameters[0]} fjernet`;
            case 'SAVED': return `'Lagret ${parameters[0]}`;
            default: return '';
        }
    },
    AMOUNT_MIN_MOBILE_WALLET: 'Minimumsbeløpet',
    AMOUNT_1_MOBILE_WALLET: 'Beløp 1',
    AMOUNT_2_MOBILE_WALLET: 'Beløp 2',
    CHOSE_PRINTER: 'Velg skriver',
    SAVE_AS_PDF: 'Lagre som PDF',
    MY_ACCOUNT: 'Min konto',
    USER_ID: 'BrukerID',
    LOG_OUT: 'Logg ut',
    HANDLE_PERSONNELCARD: 'Administrer personalkort',
    CREATE_NEW_CARD: 'Lag et nytt kort',
    PERSONNELCARD: 'Personalkort',
    NAME: 'Navn',
    TEXT_ON_CARD: 'Tekst på kort',
    AVAILABLE_THROUGH: 'Gyldig til',
    URL_TO_BACKGROUND: 'Bakgrunns-URL',
    REMOVE: 'Ta vekk',
    SAVE: 'Lagre',
    SEARCH: 'Søk',
    USER_WITH_CARD: 'Brukere med kort',
    ID: 'Id',
    CARD: 'Kort',
    JANUARY: 'Januar',
    FEBRUARY: 'Februar',
    MARS: 'Mars',
    APRIL: 'April',
    MAY: 'Mai',
    JUNE: 'Juni',
    JULY: 'Juli',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'Oktober',
    NOVEMBER: 'November',
    DECEMBER: 'Desember',
    JAN3: 'Jan',
    FEB3: 'Feb',
    MAR3: 'Mar',
    APR3: 'Apr',
    MAY3: 'Mai',
    JUN3: 'Jun',
    JUL3: 'Jul',
    AUG3: 'Aug',
    SEP3: 'Sep',
    OCT3: 'Okt',
    NOV3: 'Nov',
    DEC3: 'Des',
    OK: 'OK',
    CANCEL: 'Avbryt',
    SORTED_ON_PRODUCT: 'Sortert etter produkt',
    DAY: 'Dag',
    TOTAL: 'Totalt',
    ORDERS_TODAY: 'Bestillinger i dag',
    ORDERS_THIS_MONTH: 'Bestillinger denne måneden',
    ACTIVITY: 'Aktivitet',
    COUPONS_IN_APP: 'Kuponger brukt i appen',
    PER_PRODUCT: 'Pr. produkt',
    USERS_WITH_CODES: 'Brukere med rabattkoder',
    RECEIPTNO: 'Kvitteringsnr',
    TIME: 'Tid',
    CODE: 'Kode',
    ORDERCODE: 'Bestillingskode',
    NAVIGATE_HERE: 'Naviger hit eller bruk menyen øverst til venstre',
    UPLOADING: 'Laster opp',
    UPLOAD: 'Laste opp...',
    GALLERY: 'Bilder',
    ARE_YOU_SURE: 'Er du sikker?',
    UPDATING_IMAGE_QUICK: 'Vent ... vi oppdaterer bildet ditt snart',
    SYNC_PRODUCTS: 'Synkroniser produkter',
    CLEAR_EXISTING_PROUCTS: 'Fjern eksisterende produkter?',
    UPDATING_PRODUCTS: 'Oppdaterer produkter',
    PRODUCT_PAGE_VERIFY: 'Gå til produktsiden for å bekrefte',
    FROM: 'Fra',
    TO: 'Til',
    ORDERVALUE_COUPON: 'Bestillingsverdi med  rabattkode',
    SUM_RECEPITS_COUPON: 'Summen av kvitteringer der gjesten handlet med en rabattkode',
    CODE_CAPS: 'KODE',
    DISCOUNT_CAPS: 'RABATT',
    NETTO_CAPS: 'NETTO',
    VAT_CAPS: 'MVA',
    TOTAL_CAPS: 'TOTALT',
    TODAY_CAPS: 'I DAG',
    CLOSE_CAPS: 'STENG',
    RESET_FORTNOX: 'Gjenopprette Fortnox-tilkoblingen?',
    RESET_FORTNOX_TEXT: 'Er du sikker på at du vil fjerne Fortnox-kontakten og gjenopprette den slik at en ny kontakt kan opprettes?',
    NO: 'Nei',
    YES: 'Ja',
    AUTHORIZATIONCODE: 'Godkjennelseskoden',
    AUTHORIZED: 'GODKJENT',
    VOUCHER_SERIES: 'Verifiserings',
    ROUNDING_ACCOUNT: 'Konto for øreavrunding',
    ACTIVATE: 'Aktiver',
    INTEGRATIONS: 'integrasjoner',
    PASSWORD: 'Passord',
    FORWARD: 'Videre',
    FORGOT_PASSWORD: 'Glemt passord?',
    RESET_EMAIL_HEAD: 'E-post for tilbakestilling',
    RESET_EMAIL_TXT: 'Fyll ut e-postadressen din',
    NEW_MENU: 'Ny meny',
    COPY: 'kopiere',
    SURE: 'Er du sikker?',
    MENU_SHARED: 'Delt meny!',
    SOMETHING_WRONG: 'Noe gikk galt!',
    ACTIVATE_BEFORE_REMOVE: 'Du må aktivere en annen meny før du kan slette denne',
    REMOVE_FOR_ALL: 'Vil du slette for alle?',
    REMOVE_EVEN_OTHERS: 'Vil du også slette innlegget hvis det finnes på andre restauranter?',
    REMOVED_FROM_ALL: 'Fjernet fra alle restauranter',
    MONDAY: 'Mandag',
    TUESDAY: 'Tirsdag',
    WEDNESDAY: 'Onsdag',
    THURSDAY: 'Torsdag',
    FRIDAY: 'Fredag',
    SATURDAY: 'Lørdag',
    SUNDAY: 'Søndag',
    FAILED: 'Misslykkes',
    SAVED: 'Lagret!',
    MENUS: 'Menyer',
    VISIBLE_IN_APP: 'Synlig i appen',
    WEEKLY_MENU: 'Ukentlig meny',
    SHOW_BUTTON: 'Vis ukemeny på appens startside. Oppdater teksten ukentlig slik at brukerne kan se menyen',
    UPLOAD_PROGRESS: 'Laster opp',
    GALLERY_SHARED: 'Dele bilder!',
    USE_IMAGE: 'Bruk bilde',
    UPDATING_IMAGE: 'Vent ... vi oppdaterer bildet ditt snart',
    COMPRESSING: 'Komprimerer',
    HELP: 'Hjelp',
    IN_STOCK: 'På lager',
    OUT_OF_STOCK: 'Slutt',
    CATEGORIES: 'Kategorier',
    FOOD: 'Mat',
    PRODUCTS: 'Produkter',
    ADD: 'Legge til',
    UPDATE: 'Oppdater',
    CONFIRM: 'Ok',
    PRODUCT_CATEGORIES: 'Produkt kategorier',
    BONG_CATEGORIES: 'Bong-kategorier',
    NEW_PRODUCT_CATEGORY: 'Ny produktkategori',
    REMOVE_PRODUCT_CATEGORY: 'Slett produktkategori!',
    EDIT_PRODUCT_CATEGORY: 'Endre produktkategori',
    NEW_BONG_CATEGORY: 'Ny Bong-kategori',
    REMOVE_BONG_CATEGORY: 'Fjern bongkategori!',
    EDIT_BONG_CATEGORY: 'Endre Bong-kategori',
    TIMELINE: 'Tidslinje',
    NEW_POST: 'Nytt innlegg',
    SHARE: 'Dele',
    CHOOSE_IMAGE: 'Velg bilde ...',
    TITLE: 'Tittel',
    TEXT: 'Tekst',
    OFFER: 'Tilbud',
    PRODUCT: 'Produkt',
    URL: 'Url',
    STATIC: 'Statisk',
    APP_TAB: 'Kategorien i appen produktet ligger under',
    OFFER_INFO: 'Info om tilbud',
    WARNING: 'Advarsel',
    ALREADY_EDITING: 'Du redigerer allerede en annen oppføring. Er du sikker på at du vil lukke uten å lagre?',
    ABORT_Q: 'Avbryt?',
    CONFIRM_CHANGES: 'Er du sikker på at du vil avbryte? Endringer vil gå tapt',
    FILL_ALL_FIELDS: 'Du må fylle ut alle felt unntatt tittel og tekst',
    SAVE_NEWS: 'Lagrer nyheter',
    MAIN_TEXT: 'Hovedtekst',
    NEW_TITLE: 'Ny tittel',
    REMOVE_POST: 'Slette innlegg?',
    REMOVE_POST_CONFIRM: 'Er du sikker på at du vil slette innlegget?',
    REMOVE_FOR_OTHERS: 'Vil du også slette innlegget hvis det finnes på andre restauranter?',
    TIMELINE_SHARED: 'Delt tidslinje!',
    TYPE: 'Type',
    SERVING_METHOD: 'Serveringsmetode',
    APPLIES_FOR: 'Gjelder for',
    PRODUCT_TYPES: 'Produkttyper',
    DISCOUNT_PERCENTAGE: 'Prosentrabatt',
    AUTOMATIC_ACTIVATION: 'Aktiver automatisk for brukere',
    CREATING_OFFER: 'Oppretter tilbudet',
    OFFER_CREATED: 'Tilbud opprettet!',
    UPDATING_OFFER: 'Oppdaterer tilbudet',
    OFFER_UPDATED: 'Oppdaterte tilbudet',
    REMOVING: 'Fjern',
    OFFER_REMOVED: 'Fjernet tilbudet',
    NEW_BUTTON: 'Ny tast',
    CREATE_NEW_BUTTON: 'Opprett ny tast',
    CONNECT_BUTTON: 'Hva skal tasten kobles til?',
    CHOOSE_BG_IMAGE: 'Velg bakgrunn ...',
    BUTTON_LAYER: 'Tast gruppe',
    FREE_TEXT: 'Fri tekst',
    ACTIVATE_IN_POS: 'AKTIVER PÅ KASSEN',
    ALLERGY_INFO_SAVED: 'Allergiinfo lagret og synlig i appen',
    MINIMUM_BONUS_SAVED: 'Minimum bonus lagret',
    PAYMENT_OPTIONS_SAVED: 'Betalingsmåter lagret',
    DELIVERY_OPTIONS_SAVED: 'Visningsinnstillinger lagret',
    NOTIFICATION_SAVED: 'Merknad lagret',
    INSTAGRAM_SAVED: 'Instagram lagret',
    FACEBOOK_SAVED: 'Facebook lagret',
    MENU_STYLE_SAVED: 'Menyutseende lagret',
    FOOD_SETTINGS_SAVED: 'Matinnstillinger lagret',
    DRINK_SETTINGS_SAVED: 'Drikkeinnstillinger lagret',
    DISCOUNT_SETTINGS_SAVED: 'Rabattinnstillinger lagret',
    COUPON_NAME: 'Navnet på kupongen',
    PERCENTAGE_IN_DISOUNT: 'Antall prosent i rabatt',
    TAKEAWAY: 'Ta med',
    TABLE_SERVING: 'Bordservering',
    COLLECT_IN_BAR: 'Hentes i restauranten',
    DELIVERY: 'Kjører ut',
    SENDING_PUSH: 'Sender pushvasel',
    PUSH_SENT: 'Push varsel sendt!',
    GENERAL: 'Generelt',
    GENERATE_REPORT: 'Generer rapport',
    PUSH_NOTIFICATIONS: 'Pushvarsel',
    OFFERS: 'Tilbud',
    DELIVERY_OPTIONS: 'Serveringsalternativer',
    PAYMENT_OPTIONS: 'Betalingsalternativer',
    USE_SAVE_BUTTON: 'Endringer på denne siden påvirker mobilappen, og Lagre-knappene må brukes for at endringene skal tre i kraft',
    OPEN_FOR_SALE: 'Åpent for salg',
    MESSAGE_WHEN_CLOSED: 'Merknad til kunden når fanen er lukket',
    FOOD_TAB: 'Matfane',
    DRINK_TAB: 'Drikke fanen',
    NOTIFICATION_IN_APP: 'Legg merke til i appen',
    SHOW_NOTIFICATION: 'Vis varsel',
    NOTIFICATION_TEXT: 'Tekst for varsel',
    ALLERGY_INFO: 'Allergi info',
    PHONE_NUMBER: 'Telefonnummer',
    OPENING: 'Åpent klokke',
    CLOSING: 'Stengt klokka',
    SHOW_FACEBOOK: 'Vis Facebook-link på hjemmesiden',
    INSTAGRAM: 'Instagram',
    SHOW_INSTAGRAM: 'Vis Instagram-link på hjemmesiden',
    WHICH_ACTIVE: 'Hvilken skal aktiveres i appen?',
    SWISH: 'Swish',
    TAB: 'Bordbestilling',
    TABLENUMBER_FROM: 'Bord nr fra',
    TABLENUMBER_TO: 'Bord nr til',
    AREA_1: 'Område 1',
    AREA_2: 'Område 2',
    AREA_3: 'Område 3',
    AREA_4: 'Område 4',
    DELIVERY_RADIUS: 'Utkjøringsområde',
    DELIVERY_RADIUS_COST: 'Pris for utkjøring',
    BONUSSYSTEM: 'Bonussystem',
    LEAST_AMOUNT_BONUS: 'Minimum antall poeng for bruk av loyalitetspong',
    GLOBAL_OFFERS: 'Globale tilbud',
    APPLIES_TO_ALL: 'Gjelder alle i appen',
    ONETIME: 'Engang',
    USER_GROUPS: 'Brukergrupper',
    ADD_NEW: 'Legg til en ny',
    GROUP_NAME: 'Gruppenavn',
    DISCOUNT_CODES: 'Rabattkoder',
    DISCOUNTPERCENT: 'Rabattprosent',
    APPLY_AUTOMATICALLY: 'Bruk rabattkoden automatisk for kunder i appen',
    SEND_PUSH: 'Send push',
    SEND_PUSH_TEXT: 'Meldingen sendes umiddelbart til alle lokale brukere',
    SEND: 'Sende',
    ALTERNATIVES: 'Alternativ',
    HEADER_EX: 'Overskrift (f.eks. Grad av steking)',
    OPTION_TYPE: 'Legg til tilvalg',
    CHECKBOX: 'Merk av',
    MULTIPLE: 'Flere valg',
    PRICE: 'Pris',
    OPTIONS: 'Valgfri',
    COPY_FROM: 'Kopier fra',
    USING_STEP_BY_STEP: 'Bruk steg for steg',
    HEADER: 'Overskrift',
    SUBHEADER: 'Underoverskrift',
    LINK_WITH: 'Koblet sammen med',
    EDIT_EXCEPTION: 'Endre unntak',
    REASON: 'Grunnen til',
    REASON_DETAILS: 'Årsak',
    ACCOUNTING: 'Regnskap',
    SEARCH_PRODUCT: 'Søk etter produkt',
    DELIVERY_TYPES: 'Leveringsmåte',
    REMOVE_EXCEPTION_CONFIRM: 'Vil du fjerne unntaket?',
    REMOVE_EXCEPTION: 'Fjern unntak!',
    SAVE_WITHOUT_ACCOUNTING: 'Du kan ikke lagre produkter uten regnskapsinformasjon og produkttype',
    REMOVE_PRODUCT_RESTURANTS: 'Vil du fjerne produktet på flere restauranter?',
    PRODUCT_SHARED: 'Delt produkt!',
    PRODUCT_INFORMATION: 'Produktinformasjon',
    BASIC_INFO: 'Grunnleggende informasjon',
    ACTIVE: 'Aktiv',
    PRODUCT_ACTIVE: 'Produktet er aktivt',
    PRICE_KR: 'Pris i',
    BONUS_CONVERT: 'Prosent som konverteres til bonuspoeng når du kjøper et produkt',
    BONUS_CONVERT_WALLET: 'Prosent som konverteres til bonuspoeng når du kjøper mobile wallet',
    NOT_ALLOWED_TO_BY_WITH_BONUS: 'Får inte köpas för bonuspoäng',
    DESCRIPTION_SHORT: 'Beskrivelse (kort)',
    DESCRIPTION_LONG: 'Beskrivelse (lang)',
    CATEGORY_BONG_INFO: 'Kategori og bonginformasjon',
    NAME_ON_BONG: 'Navn på bong (valgfritt)',
    PRODUCT_TYPE: 'Produkttype',
    PRODUCT_CATEGORY: 'Produktkategori',
    BONG_CATEGORY: 'Bong-kategori',
    ACCOUNTING_VAT: 'Regnskap og merverdiavgift',
    EXCEPTION: 'Unntak',
    CONTAINS_ALCOHOL: 'Produktet inneholder alkohol',
    AGE_LIMIT: '18 års aldersgrense',
    PURCHASE: 'Kjøp',
    COST: 'Kostnad',
    MARGIN: 'Margin',
    TAGS: 'Merker',
    NEW_TAG: 'Ny tagg',
    EXTERNAL_ID: 'Ekstern ID',
    STOCK_BALANCE: 'Lagersaldo',
    COUNT: 'Antall',
    ACOUNTINGS: 'Kontering',
    VAT_RATE: 'Moms',
    VAT_RATES: 'Moms satser',
    ACCOUNTING_ACCOUNT: 'Regnskapskonto',
    NO_ACCOUNT: 'Ingen konto',
    ACCOUNTING_CREATED: 'Kontering opprettet',
    PAYMENT_TYPES: 'Betalingsmetode',
    ACTIVATED_PAYMENT_TYPES: 'Aktiverte betalingsmåter',
    ADITIONAL_PAYMENT_TYPES: 'Flere betalingsmåter',
    NO_PAYMENT_TYPES: 'har ingen betalingsmåter aktivert. Trykk på Flere betalingsmåter for å aktivere noen',
    ADDED: 'Lagt til',
    PAYMENT_FEES: 'Noen betalingsmåter er knyttet til gebyrer. Her velger du kontoer for disse',
    VAT: 'Mva',
    HOME: 'Hjem',
    REPORTS: 'Rapporter',
    ADMIN: 'Administrasjon',
    NEWS: 'Nyhetsfeed',
    APP: 'App',
    POS: 'KASSE',
    STAFF_CARD: 'Personalkort',
    MISC: 'Diverse',
    SETTINGS: 'Innstillinger',
    RESTURANT_INFO: 'Restaurantinformasjon',
    USERS: 'Bruker',
    SEE_ALL: 'Se alle ...',
    STATISTICS: 'Statistikk',
    DARK_MODE: 'Mørkt modus',
    SELECT_UNIT: 'Velg enhet',
    OPENING_H_SAVED: 'Åpningstider lagret',
    OPENING_H: 'Åpningstider',
    CLOSED: 'Lukket',
    OPENS: 'Åpner',
    CLOSES: 'Stenger',
    DEVIATIONS: 'Avvik',
    SELECT_LOGO: 'Velg logo ..',
    ORG_NO: 'Organisasjonsnummer',
    ADRESS: 'Adresse',
    ZIP_CODE: 'Postnummer',
    POST_ADR: 'postadresse',
    PHONE: 'Telefon',
    EMAIL: 'E-post',
    ABOUT_US: 'Om oss',
    ADD_USER: 'Legg til brukere',
    ADD_DOMAIN_AUTO: 'Legg til brukerdomener som automatisk legges til denne brukergruppen ved registrering',
    DOMAIN_EXAMPLE: '(f.eks. bare pubq.se ikke @ pubq.se)',
    LOAD: 'Laster',

    CHOOSE: 'Velg ...',
    LINK_TO_PRODUCT: 'Link til produkt eller nettsted',
    START_WITH_HTTPS: 'må starte med https: //',
    WHERE_IS_PRODUCT: 'Hvor er produktet?',
    OFFER_INFO_PLACEHODLER: 'Informasjonstekst for tilbudet...',
    OPTION_DESCRIPTION: 'Beskrivelse av tilbudet',
    SELECT: 'Velg',
    DISCOUNT_PERCENTAGE_PLACEHOLDER: 'Antall prosent brukeren får rabatt',
    WRITE: 'Skriv...',
    SEARCH_PRODUCT_OR_TAG: 'Søk produkt eller tagg',
    STEP_PLACEHOLDER: "Fremgangsmåte for å bygge din høyre flyt",
    ABOUT_US_PLACEHOLDER: 'Om oss',
    DOMAIN_EX: 'Domenet f.eks. Pubq.se',
    TIME_CONTROL_MENU: 'Timer menyen',
    SET: 'Sett',
    SCHEDULE: 'Schedule',
    ACTIVATION_TIME: 'Aktiveringstid',
    ACTIVATE_MENU: 'Aktiver meny',
    WEEK: 'Uke',
    TIP_REPORT: 'Tipsrapport',
    DESC_GENERATE_REPORT: 'Rapporten genereres til en PDF-fil som lastes ned automatisk.',
    GENERATING_REPORT: 'Genererer rapporten',
    ERROR_TIP_REPORT: 'Noe gikk galt i generasjonen av tipsrapporten!',
    COMPANY_NAME: 'Selskapsnavn',
    EMAIL_NOT_VALID: "E-postadressen er ikke gyldig"
}