import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";

@Pipe({ name: 'displaybonus' })
export class DisplayBonusPipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {

    }
    transform(value: any): any {
        if (!value)
            return value = '';
        else
            return value + '%'
    }

}