import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './services/auth-guard/auth-guard';
import { ContextGuard } from './services/context-guard/context-guard';

import { UnitSelectionComponent } from './modules/unit-selection/unit-selection.component';

import { LoginPage } from './modules/login/login.page';


const routes: Routes = [
  {path: 'unit/:routeName', loadChildren:"./modules/home/home.module#HomePageModule", canActivate:[AuthGuard,ContextGuard]},
  { path: '', redirectTo: "login", pathMatch: "full" },
  { path: 'login', component: LoginPage },
 
  { path: 'unit-selection', component: UnitSelectionComponent, canActivate: [AuthGuard] },
 
  
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
