import { Component, Input, Output, EventEmitter } from "@angular/core";
import { IMenuItem } from "../../../services/menu.service";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { startWith, map } from "rxjs/operators";
import { LanguagePipe } from "../../../pipes/language.pipe";
import { LanguageProvider } from "../../../services/language/language.provider";


@Component({
    selector: "app-products-search",
    templateUrl: "productsSearch.component.html",
    styleUrls: ["productsSearch.component.scss"]
})
export class ProductsSearchComponent {
    @Input() products: IMenuItem[];
    @Input() currentProduct: IMenuItem | null;
    @Output() onSelect = new EventEmitter<IMenuItem>();
    @Input() preSelected: IMenuItem;
    @Input() label = this.languagePipe.transform("Sök produkt", 'SEARCH_PRODUCT', this.languageProvider.currentLanguage);
    filteredProducts: Observable<IMenuItem[]>;
    stateCtrl = new FormControl();

    constructor(private languagePipe: LanguagePipe, public languageProvider: LanguageProvider) {

    }
    ngOnInit() {
        this.filteredProducts = this.stateCtrl.valueChanges.pipe(
            startWith(""),
            map(value => this._filter(value))
        );
        if (this.preSelected)
            this.stateCtrl.setValue(this.preSelected);
    }
    private _filter(value: string): IMenuItem[] {
        if (typeof value !== "string" || !this.products) return;
        const filterValue = value.toLowerCase();

        let result = this.products.filter(option => (this.currentProduct ? option.key != this.currentProduct.key : true) &&
            option.Name.toLowerCase().includes(filterValue));

        return result;

    }
    displayFn(item?: IMenuItem): string | undefined {
        return item ? item.Name : undefined;
    }
    async onSearchResultSelected(event) {
        this.onSelect.next(event.option.value);
    }
}