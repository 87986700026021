import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  transform(items: any[], term: string): any {
    if (!items || !term)
      return items;
    return items.filter(item => {
      let result = item.Name.toLowerCase().includes(term.toLowerCase());
      return result;
    });
  }

}
@Pipe({
  name: 'filterByProp',
  pure: false
})
export class FilterPropertyPipe implements PipeTransform {

  transform(items: any[], filter: { property: string, term: string }): any {
    if (!items || !filter)
      return items;
    if (!filter.term || !filter.property || filter.term.length < 2)
      return items;
    let properties = filter.property.split(".");

    return items.filter(item => {
      let data;
      properties.forEach((x, index) => {
        if (index === 0)
          data = item[x];
        else
          data = data[x];
      })
      let result = data.toLowerCase().includes(filter.term.toLowerCase());
      return result;
    });
  }

}

