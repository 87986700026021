import { Injectable } from '@angular/core';
import { AngularFireList, AngularFireDatabase, AngularFireObject } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { OrderService } from './order.service';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class VatAccountingService {

  private vatAccountingsRef: AngularFireList<any>;
  private vatAccountingsObjectRef: AngularFireObject<any>;
  vatAccountings: Observable<VatAccounting[]>;


  constructor(private afDatabase: AngularFireDatabase, private userService: UserService) {
    this.userService.selectedUnitObs.subscribe(x => {
      if (!x) {
        this.vatAccountingsObjectRef = null;
        this.vatAccountingsRef = null;
        return;
      }
      this.vatAccountingsRef = this.afDatabase.list(
        `adminApp/clients/${x.routeName}/settings/vat/vatAccountings`
      );
      this.vatAccountingsObjectRef = this.afDatabase.object(
        `adminApp/clients/${x.routeName}/settings/vat/vatAccountings`
      );

      this.vatAccountings = this.vatAccountingsRef
        .snapshotChanges()
        .pipe(
          map(changes =>
            changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
          ));

    });
  }
  async saveVatAccountings(accounts: VatAccounting[]) {
    await this.vatAccountingsObjectRef.set(accounts);
    return true;
  }
  updateArticleAccounting(vatAccounting: VatAccounting) {
    this.vatAccountingsRef.update(vatAccounting.key, vatAccounting);
  }
  deleteArticleAccounting(vatAccounting: VatAccounting) {
    this.vatAccountingsRef.remove(vatAccounting.key);
  }
}
export class VatAccounting {
  key: string;
  vatPercent: number;
  accountNo: string;
  description:string;
}
