import { Component, OnInit } from '@angular/core';
import { VatAccounting, VatAccountingService } from '../../../services/vat-accounting.service';
import { ToastController } from '@ionic/angular';
import { LanguagePipe } from '../../../pipes/language.pipe';
import { LanguageProvider } from '../../../services/language/language.provider';

@Component({
  selector: 'app-vat-accounting',
  templateUrl: './vat-accounting.component.html',
  styleUrls: ['./vat-accounting.component.scss']
})
export class VatAccountingComponent implements OnInit {
  vatAccountings: VatAccounting[];
  loading;
  selectedSegment: string
  constructor(private vatAccountingService: VatAccountingService, private toastController: ToastController,
    private languagePipe:LanguagePipe, public languageProvider:LanguageProvider) {
    this.loading = true;
    this.vatAccountingService.vatAccountings.subscribe(x => {
      this.vatAccountings = x;
      this.loading = false;
    });
  }
  async saveVatAccountings() {
    await this.vatAccountingService.saveVatAccountings(this.vatAccountings);
    var toast = await this.toastController.create({
      color: "success",
      message: `\uD83D\uDC4D ${this.languagePipe.transform('Sparat','SAVED',this.languageProvider.currentLanguage)}`,
      duration: 500,
      position: "top"
    });
    toast.present();

  }
  onAccountSelected($event,vatAccount){
    vatAccount.accountNo = $event;
    this.saveVatAccountings();
  }
  segmentChanged($event) {

  }

  ngOnInit() {
    this.selectedSegment = "accounts";
  }

}
