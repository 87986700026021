import { IEnvironment } from "./models";


const database = {
  apiKey: "AIzaSyBmfb5lbJ29AdVQyp0Jk1Tug6pekx_fTwA",
  authDomain: "pq2-staging.firebaseapp.com",
  databaseURL: "https://pq2-staging.firebaseio.com",
  projectId: "pq2-staging",
  storageBucket: "pq2-staging.appspot.com",
  messagingSenderId: "438992683153"
};
export const environment: IEnvironment = {
  production: false,
  database: database,
  primeApiUrl: "https://us-central1-joboffice-prime-staging.cloudfunctions.net",
  pubQueueApiUrl: "https://us-central1-pq2-staging.cloudfunctions.net",
  adminAppUrl: "https://admin-staging.pubq.se",
  posUrl: "https://pos-staging.pubq.se",
  kdsUrl: "https://console.firebase.google.com/project/pq2-staging/database/pq2-staging/data"
};
