import { Component, Input, ViewChild } from "@angular/core";
import { IonDatetime, ModalController } from "@ionic/angular";
import * as moment from "moment";
import { LanguagePipe } from "../../../../../pipes/language.pipe";
import { LanguageProvider } from "../../../../../services/language/language.provider";
import { IMenu } from "../../../../../services/menu.service";

@Component({
    templateUrl: "time-menu.component.html",
    styleUrls: ["time-menu.component.scss"]
})

export class HandleTimeForMenuComponent {
    @ViewChild("picker") picker: IonDatetime;
    @Input() menu: IMenu;
    weekdays = [];
    pickerPlaceholder = `tt:mm`;
    selectedDate: string = '00:00'

    constructor(private modalController: ModalController,
        private languagePipe: LanguagePipe,
        public languageProvider: LanguageProvider
    ) {
    }

    ngOnInit() {

        if (this.menu.timeSchedule != null) {
            this.weekdays = JSON.parse(JSON.stringify(this.menu.timeSchedule.weekdays));
            this.selectedDate = JSON.parse(JSON.stringify(this.menu.timeSchedule.activationTime));
            this.pickerPlaceholder = this.menu.timeSchedule.activationTime;
        } else {
            this.weekdays = this.getWeekDays().map((day) => {
                return { name: day.text, checked: false, type: day.type }
            });
        }
    }

    getWeekDays() {
        return [
            {
                type: "monday",
                text: this.languagePipe.transform("Måndag", 'MONDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "tuesday",
                text: this.languagePipe.transform("Tisdag", 'TUSEDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "wednesday",
                text: this.languagePipe.transform("Onsdag", 'WEDNESDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "thursday",
                text: this.languagePipe.transform("Torsdag", 'THURSDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "friday",
                text: this.languagePipe.transform("Fredag", 'FRIDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "saturday",
                text: this.languagePipe.transform("Lördag", 'SATURDAY', this.languageProvider.currentLanguage),
            },
            {
                type: "sunday",
                text: this.languagePipe.transform("Söndag", 'SUNDAY', this.languageProvider.currentLanguage),
            },
        ]
    }

    getDayString(day) {
        switch (day.type) {
          case "monday": return this.languagePipe.transform("Måndag", 'MONDAY', this.languageProvider.currentLanguage);
          case "tuesday": return this.languagePipe.transform("Tisdag", 'TUESDAY', this.languageProvider.currentLanguage);
          case "wednesday": return this.languagePipe.transform("Onsdag", 'WEDNESDAY', this.languageProvider.currentLanguage);
          case "thursday": return this.languagePipe.transform("Torsdag", 'THURSDAY', this.languageProvider.currentLanguage);
          case "friday": return this.languagePipe.transform("Fredag", 'FRIDAY', this.languageProvider.currentLanguage);
          case "saturday": return this.languagePipe.transform("Lördag", 'SATURDAY', this.languageProvider.currentLanguage);
          case "sunday": return this.languagePipe.transform("Söndag", 'SUNDAY', this.languageProvider.currentLanguage);
        }
      }

    isChecked(day: any) {
        return day.checked == true;
    }

    selectDay(day: any) {
        day.checked = !day.checked;
    }

    setDatePickerDate(ev) {
        if (!ev.detail.value) {
            this.selectedDate = '00:00';
            this.picker.value = "";
            return;
        }
        else {
            let date = moment(ev.detail.value);
            this.selectedDate = date.format('HH:mm');;
        }
    }

    confirm() {
        this.menu.timeSchedule = { weekdays: this.weekdays, activationTime: this.selectedDate, active: (this.menu.timeSchedule ? this.menu.timeSchedule.active : false) }
        this.modalController.dismiss(null, "confirm");
    }
    dismiss() {
        this.modalController.dismiss(null, "cancel");
    }
}