import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { PosUser } from "../../../services/settings.service";

@Component({
    templateUrl: "edit-pos-user.component.html",
    styleUrls: ["edit-pos-user.component.scss"]
})

export class EditPosUserComponent {
    @Input() user: PosUser;
    editedUser: PosUser;
    editedProductTypeId: string;
    @Input() editMode = false;

    constructor(private modalController: ModalController
    ) {

    }

    ngOnInit() {
        this.editedUser = JSON.parse(JSON.stringify(this.user));
    }
    delete() {
        this.modalController.dismiss(this.editedUser, "delete");
    }
    confirm() {
        this.modalController.dismiss(this.editedUser, "confirm");
    }
    dismiss() {
        this.modalController.dismiss(null, "cancel");
    }
}