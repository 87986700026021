import { environment } from '../../../../environments/environment';
import { LoadingController, AlertController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { IMenuItem, MenuService } from '../../../services/menu.service';
import { AngularFireDatabase } from '@angular/fire/database';
import { Component, Input, Inject, InjectionToken } from '@angular/core';
import { Addons } from '../addons';
import { LanguageProvider } from '../../../services/language/language.provider';
import { LanguagePipe } from '../../../pipes/language.pipe';

@Component({
  selector: 'eskassa-component',
  templateUrl: './eskassa.component.html',
  styleUrls: ['./eskassa.component.scss'],
})
export class EsKassaIntegrationComponent {
  public addon;
  private key = "es_kassa";
  private integratorId = "HELLO";
  private machineKey = "MR MACHINE";
  private machineId = "NUMBER 2";
  private employeeId = "WITH EMPLOYEE JOHN";

  constructor(
    addons: Addons,
    private loadingController: LoadingController,
    private afDatabase: AngularFireDatabase,
    private alertController: AlertController,
    private menuService: MenuService,
    private http: HttpClient,
    public languageProvider:LanguageProvider,
    private languagePipe:LanguagePipe) {

    this.addon = addons.getAddon(this.key);
  }

  importInventory() {
    this.importEsKassaInventory();
  }
  private async importEsKassaInventory() {
    let loading = await this.loadingController.create({
      duration: 20000,
      message: "Hämtar produkter",
      spinner: "crescent"
    });
    loading.present();
    this.http.post(`${environment.primeApiUrl}/getexternalinventory`, { integratorId: this.integratorId, machineKey: this.machineKey, machineId: this.machineId }).subscribe(async (x: { data: { inventoryItems: EsKassaInventoryItem[], categories: EsKassaCategory[] } }) => {
      console.log(`${x.data.inventoryItems.length} produkter hittade`);
      let items = x.data.inventoryItems.map(async (item) => {
        let type = this.tryToDetermineTypeAutomatically(item);
        let product: IMenuItem = {
          Name: item.Description,
          NameInternal: item.Description,
          Cost: item.PriceIncludingVat * 100,
          Description: "",
          DescriptionLong: "",
          ImageUrl: "",
          Volume: 0,
          key: this.afDatabase.createPushId(),
          externalOrigin: { key: this.key, externalId: item.Id, vatPercentage: item.VatPercentage },
          vatInfo: type.vatInfo,
          type: type.type,
          useStockBalance:false,
          showStockBalance:false,
          enabled:true,
          disabledReason:"",
          stockBalance:0,
          tags: type.tags,
          isCombinedProduct:false,
          combinedVatInfo:null
        };
        return product;
      });

      let mappedItems = await Promise.all(items);
      loading.dismiss();
      let alert = await this.alertController.create({
        header: this.languagePipe.transform("Rensa existerande produkter?",'CLEAR_EXISTING_PROUCTS',this.languageProvider.currentLanguage),
        buttons: [
          {
            text: "Nej",
            role: "cancel"

          },
          {
            text: "Ja",
            role: "confirm"
          }
        ]
      });
      alert.present();

      let result = await alert.onDidDismiss();
      if (result.role === "confirm")
        var deleted = await this.menuService.deleteAllProducts();
      loading = await this.loadingController.create({ message: this.languagePipe.transform("Uppdaterar produkter",
      'UPDATING_PRODUCTS',this.languageProvider.currentLanguage), spinner: "crescent" });
      loading.present();
      let sub = this.menuService.products.subscribe(async products => {
        sub.unsubscribe();
        mappedItems.forEach(async importedProduct => {
          let existingProduct = products.filter(p => p.Name === importedProduct.Name)[0];
          if (existingProduct) {
            console.log(`updating ${existingProduct.Name}`);
            existingProduct = this.updateFields(existingProduct, importedProduct);
            await this.menuService.updateProduct(existingProduct);
          }
          else
            await this.menuService.addProduct(importedProduct, importedProduct.key);
        });
        loading.dismiss();
        let infoAlert = await this.alertController.create({
          header: this.languagePipe.transform(`${x.data.inventoryItems.length} produkter uppdaterade`,'CONFIRM',this.languageProvider.currentLanguage,[x.data.inventoryItems.length.toString()]),
          message: this.languagePipe.transform(`Gå till produktsidan för att verifiera`,'PRODUCT_PAGE_VERIFY',this.languageProvider.currentLanguage),
          buttons: [
            {
              text: this.languagePipe.transform("Ok",'CONFIRM',this.languageProvider.currentLanguage),
              role: "confirm"
            }
          ]
        });
        infoAlert.present();
      });
    });
  }
  private updateFields(oldProduct: IMenuItem, newProduct: IMenuItem) {
    oldProduct.tags = newProduct.tags;
    oldProduct.vatInfo = newProduct.vatInfo;
    oldProduct.Cost = newProduct.Cost;
    oldProduct.externalOrigin = newProduct.externalOrigin;
    return oldProduct;
  }
  private tryToDetermineTypeAutomatically(item: EsKassaInventoryItem) {
    let drinkStrings = this.languagePipe.transformList(["dricka", "dryck"],'DRINK_LIST',this.languageProvider.currentLanguage);
    let bongName = item.BongCategoryName ? item.BongCategoryName.toLowerCase() : item.CategoryName.toLowerCase();
    let productTypeHit = false;
    let productTypeId = 1;
    drinkStrings.forEach(x => {
      if (productTypeHit)
        return
      if (bongName.indexOf(x) > -1)
        productTypeHit = true;
    })
    if (productTypeHit)
      productTypeId = 2;
    let containsAlcohol = false;
    let alcoholHit = false;
    let alcoholStrings = this.languagePipe.transformList(["vin", "shot", "drink", "sprit", "cognac", "öl", "cider", "whisk", "whiskey", "whisky", "rom", "likör", "vodka", "limoncello", "alkoläsk"],
    'ALCOHOL_STRINGS',this.languageProvider.currentLanguage);
    let categoryName = item.CategoryName.toLowerCase();
    alcoholStrings.forEach(x => {
      if (alcoholHit)
        return;
      if (categoryName.indexOf(x) > -1)
        alcoholHit = true;
    });
    if (alcoholHit)
      containsAlcohol = true;

    let articleAccountId = "external";
    let tags = [bongName, categoryName];
    return { type: { productTypeId, containsAlcohol }, vatInfo: { articleAccountId }, tags };
  }
}

export class EsKassaInventoryItem {
  BarCode: string
  BarCode2: string
  BarCode3: string
  BarCode4: string
  BarCode5: string
  BongCategoryName: string
  ButtonColorHex: string
  CategoryId: string
  CategoryName: string
  CreatedDateTime: string
  Description: string
  DictionaryTable: number
  ExternalProductCode: string
  ExtraCategoryIds: number[]
  Id: string
  LastModifiedDateTime: string
  PickupPriceIncludingVat: number
  PriceIncludingVat: number
  PriceUnit?: string
  ProductCode: string
  QuantityInStockCurrent: number
  SortingWeight: number
  SpecialFunctionTypeId: number
  SupplierName: string
  VatPercentage: number
  VisibleOnSalesMenu: boolean
}
export class EsKassaCategory {
  AccountNumber: string
  CreatedDateTime: string
  DefaultVatPercentage: number
  Id: string
  LastModifiedDateTime: string
  Name: string
  OnlyAllowCategories: boolean
  ParentCategoryId: string
  SortingWeight: number
}