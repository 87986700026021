import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { startWith, map } from 'rxjs/operators';
import { ArticleAccountingService } from '../services/article-accounting.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-accounts-searchbar',
  templateUrl: './accounts-searchbar.component.html',
  styleUrls: ['./accounts-searchbar.component.scss']
})
export class AccountsSearchbarComponent implements OnInit {
  filteredAccounts: Observable<any>;
  accounts = [];
  @Input() preSelect;
  @Input() showClear = true;
  @Output() itemSelected = new EventEmitter<any>();
  @Output() onBlur = new EventEmitter<boolean>();
  @Output() itemCleared = new EventEmitter<boolean>();
  public stateCtrl = new FormControl();
  constructor(private articleAccountService: ArticleAccountingService) {
    this.articleAccountService.accounts.subscribe(x => {
      this.accounts = x;
    })
  }

  ngOnInit() {

    this.filteredAccounts = this.stateCtrl.valueChanges.pipe(
      startWith(""),
      map(value => this._filter(value))
    );
  }
  clearInput() {
    this.itemCleared.emit(true);
  }
  onInputBlur(event) {
    
    let val = event.srcElement.value;
    this.onBlur.emit(val);
  }
  onItemSelected(event) {
    this.itemSelected.emit(event.option.value);
  }
  displayFn(item, asd): string | undefined {
    return item ? item : this.preSelect;
  }
  private _filter(value: string) {
    if (typeof value !== "string") return;
    const filterValue = value.toLowerCase();

    return this.accounts.filter(option =>
      option.description.toLowerCase().includes(filterValue) || option.no.toLowerCase().includes(filterValue)
    );
  }
}
