export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'CARD_ADDED_FOR': return `'Kort oprettet til`;
            case 'REMOVE_NAME': return `'Fjern ${parameters[0]} ?`;
            case 'REMOVED_IMAGE': return `'${parameters[0]} fjernet`;
            case 'PRODUCTS_UPDATED': return `'${parameters[0]} produkter opdateret`;
            case 'SAVING_MENU': return `'Gemmer menu til ${parameters[0]}`;
            case 'REMOVE_MENU': return `'Fjern ${parameters[0]} ?`;
            case 'SAVING_GALLERY': return `'Gemmer galleri til ${parameters[0]}`;
            case 'REMOVED_IMAGE': return `'${parameters[0]} fjernet`;
            case 'NUMBER_ARTICLES': return `'${parameters[0]} artikler`;
            case 'UPDATE_ITEM': return `'Opdateret ${parameters[0]}`;
            case 'PRODUCT_ADDED': return `'${parameters[0]} lagt til`;
            case 'PRODUCT_REMOVED': return `'${parameters[0]} fjernet`;
            case 'REMOVE_PRODUCT_CATEGORY': return `'Vil du fjerne ${parameters[0]} ?`;
            case 'REMOVE_BONG_CATEGORY': return `'Vil du fjerne ${parameters[0]} ?`;
            case 'SHARE_NEWS_WITH': return `'Deler nyheden med ${parameters[0]}`;
            case 'NEWS_SHARED': return `'Delte ${parameters[0]}`;
            case 'TIMELINE_SAVED_FOR': return `'Gemmer tidslinjen for ${parameters[0]}`;
            case 'SHARING_OFFER_WITH': return `'Deler tilbuddet med ${parameters[0]}`;
            case 'OFFER_SHARED': return `'Delte ${parameters[0]}`;
            case 'REMOVE_COUPON': return `'Fjern ${parameters[0]} ?`;
            case 'LINKED_PRODUCT': return `'Tilknyttet ${parameters[0]}`;
            case 'COPY_FROM': return `'Kopiere fra ${parameters[0]} ?`;
            case 'REMOVE': return `'Fjern ${parameters[0]} ?`;
            case 'REMOVING': return `'Fjerner ${parameters[0]}`;
            case 'ADDED': return `'Føjer til ${parameters[0]}`;
            case 'PRODUCT_SAVED': return `'${parameters[0]} er gemt`;
            case 'SAVING_PRODUCT': return `'Gemmer produkt for ${parameters[0]}`;
            case 'CONFIRM_REMOVE_ACCOUNTING': return `'Er du sikker på at du vil slette ?`;
            case 'REMOVE_ACCOUNTING': return `'${parameters[0]} fjernet`;
            case 'SAVED': return `'Gemte ${parameters[0]}`;
            case 'RECEIPTS_AMOUNT': return `Totalt: ${parameters[0]} kvitton & ${parameters[1]}`;//translate
            default: return '';
        }
    },
    ALCOHOL_STRINGS: ["vin", "shot", "drink", "sprit", "cognac", "öl", "cider", "whisk", "whiskey", "whisky", "rom", "likör", "vodka", "limoncello", "alkoläsk"],
    AMOUNT_MIN_MOBILE_WALLET : 'Minimum',
    AMOUNT_1_MOBILE_WALLET : 'Beløb 1',
    AMOUNT_2_MOBILE_WALLET : 'Beløb 2',
    STRINGS: ["Öl", "Vin", "Alkoholfritt", "Mat", "Cider", "Drink", "Sprit", "Dricks", "Presentkort"],
    DRINK_LIST: ["dricka", "dryck"],
    WEEL_LIST: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    CHOSE_PRINTER: 'Vælg printer',
    SAVE_AS_PDF: 'Gem som PDF',
    MY_ACCOUNT: 'Min konto',
    USER_ID: 'BrugerID',
    LOG_OUT: 'Log ud',
    HANDLE_PERSONNELCARD: 'Hent personalekort',
    CREATE_NEW_CARD: 'Opret nyt kort',
    PERSONNELCARD: 'Personalekort',
    NAME: 'Navn',
    TEXT_ON_CARD: 'Tekst på kort',
    AVAILABLE_THROUGH: 'Gyldig til',
    URL_TO_BACKGROUND: 'URL til baggrund',
    REMOVE: 'Fjern',
    SAVE: 'Gem',
    SEARCH: 'Søg',
    USER_WITH_CARD: 'Brugere med kort',
    ID: 'ID',
    CARD: 'Kort',
    JANUARY: 'Januar',
    FEBRUARY: 'Februar',
    MARS: 'Marts',
    APRIL: 'April',
    MAY: 'Mej',
    JUNE: 'Juni',
    JULY: 'Juli',
    AUGUST: 'August',
    SEPTEMBER: 'September',
    OCTOBER: 'Oktober',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    JAN3: 'Jan',
    FEB3: 'Feb',
    MAR3: 'Mar',
    APR3: ' Apr',
    MAY3: ' Maj',
    JUN3: ' Jun',
    JUL3: ' Jul',
    AUG3: ' Aug',
    SEP3: ' Sep',
    OCT3: ' Okt',
    NOV3: ' Nov',
    DEC3: ' Dec',
    OK: 'Ok',
    CANCEL: 'Afbryd',
    SORTED_ON_PRODUCT: 'Sorteret på produkt',
    DAY: 'Dag',
    TOTAL: 'Total',
    ORDERS_TODAY: 'Ordrer i dag',
    ORDERS_THIS_MONTH: 'Ordrer denne måned',
    ACTIVITY: 'Aktivitet',
    COUPONS_IN_APP: 'Kuponer brugt i appen',
    PER_PRODUCT: 'Pr. produkt',
    USERS_WITH_CODES: 'Brugere med rabatkoder',
    RECEIPTNO: 'Kvitteringsnr',
    TIME: 'Tid',
    CODE: 'Kode',
    ORDERCODE: 'Ordrekode',
    NAVIGATE_HERE: 'Naviger her eller benyt menuen oppe til venstre',
    UPLOADING: 'Lader op',
    UPLOAD: 'Oplad',
    GALLERY: 'Galleri',
    ARE_YOU_SURE: 'Er du sikker ?',
    UPDATING_IMAGE_QUICK: 'Vent … vi opdaterer dit billede',
    SYNC_PRODUCTS: 'Synkroniser produkter',
    CLEAR_EXISTING_PROUCTS: 'Ryd eksisterende produkter ?',
    UPDATING_PRODUCTS: 'Opdaterer produkter',
    PRODUCT_PAGE_VERIFY: 'Gå til produktsiden for at verificere',
    FROM: 'Fra',
    TO: 'Til',
    ORDERVALUE_COUPON: 'Ordresum med kuponkode',
    SUM_RECEPITS_COUPON: 'Summen af ​​kvitteringer, hvor gæsten handlede med en kuponkode',
    CODE_CAPS: 'KODE',
    DISCOUNT_CAPS: 'RABAT',
    NETTO_CAPS: 'NETTO',
    VAT_CAPS: 'MOMS',
    TOTAL_CAPS: 'TOTAL',
    TODAY_CAPS: 'I DAG',
    CLOSE_CAPS: 'LUK',
    RESET_FORTNOX: 'Gendan Fortnox-forbindelsen?',
    RESET_FORTNOX_TEXT: 'Er du sikker på, at du vil fjerne Fortnox-forbindelsen og gendanne det, så der kan oprettes en ny forbindelse ?',
    NO: 'Nej',
    YES: 'Ja',
    AUTHORIZATIONCODE: 'Autorisationskode',
    AUTHORIZED: 'Godkendt',
    VOUCHER_SERIES: 'Verifikationsserie',
    ROUNDING_ACCOUNT: 'Konto for øreafrunding',
    ACTIVATE: 'Aktivere',
    INTEGRATIONS: 'integrationer',
    PASSWORD: 'Kodeord',
    FORWARD: 'Videre',
    FORGOT_PASSWORD: 'Glemt kodeord ?',
    RESET_EMAIL_HEAD: 'Email til nulstilling',
    RESET_EMAIL_TXT: 'Udfyld med din email adresse',
    NEW_MENU: 'Ny menu',
    COPY: 'kopier',
    SURE: 'Er du sikker ?',
    MENU_SHARED: 'Del menuen !',
    SOMETHING_WRONG: 'Der skete en fejl !',
    ACTIVATE_BEFORE_REMOVE: 'Du skal aktivere en anden menu før du kan fjerne denne',
    REMOVE_FOR_ALL: 'Fjern for alle ?',
    REMOVE_EVEN_OTHERS: 'Vil du også slette oplægget, hvis det findes på andre restaurantenheder? ',
    REMOVED_FROM_ALL: 'Fjern fra alle restauranter',
    MONDAY: 'Mandag',
    TUESDAY: 'Tirsdag',
    WEDNESDAY: 'Onsdag',
    THURSDAY: 'Torsdag',
    FRIDAY: 'Fredag',
    SATURDAY: 'Lørdag',
    SUNDAY: 'Søndag',
    FAILED: 'Fejlet',
    SAVED: 'Gemt !',
    MENUS: 'Menuer',
    VISIBLE_IN_APP: 'Synlig i appen',
    WEEKLY_MENU: 'Ugemenu',
    SHOW_BUTTON: 'Vis knappen Ugentlig på appens startside. Opdater teksten ugentligt, så brugerne kan se, hvad der serveres ',
    UPLOAD_PROGRESS: 'Lader op',
    GALLERY_SHARED: 'Delt galleriet !',
    USE_IMAGE: 'Brug billede',
    UPDATING_IMAGE: 'Vent … vi opdaterer dit billede',
    COMPRESSING: 'Komprimerer',
    HELP: 'Hjælp',
    IN_STOCK: 'På lager',
    OUT_OF_STOCK: 'Slut',
    CATEGORIES: 'Kategorier',
    FOOD: 'Mad',
    PRODUCTS: 'Produkter',
    ADD: 'Tilføj',
    UPDATE: 'Opdaterer',
    CONFIRM: 'OK',
    PRODUCT_CATEGORIES: 'Produktkategorier',
    BONG_CATEGORIES: 'Bonkategorier',
    NEW_PRODUCT_CATEGORY: 'Ny produktkategori',
    REMOVE_PRODUCT_CATEGORY: 'Fjern produktkategori',
    EDIT_PRODUCT_CATEGORY: 'Ændre produktkategori',
    NEW_BONG_CATEGORY: 'Ny bonkategorier',
    REMOVE_BONG_CATEGORY: 'Fjern bonkategorier',
    EDIT_BONG_CATEGORY: 'Ændre bonkategorier',
    TIMELINE: 'Tidslinje',
    NEW_POST: 'Ny post',
    SHARE: 'Del',
    CHOOSE_IMAGE: 'Vælg billede…',
    TITLE: 'Titel',
    TEXT: 'Tekst',
    OFFER: 'Tilbud',
    PRODUCT: 'Produkt',
    URL: 'URL',
    STATIC: 'Statisk',
    APP_TAB: 'Fane i appen som produktet ligger under',
    OFFER_INFO: 'Info om tilbud',
    WARNING: 'Advarsel',
    ALREADY_EDITING: 'Du redigerer allerede en anden post. Er du sikker på, at du vil miste data ?',
    ABORT_Q: 'Afbryd ?',
    CONFIRM_CHANGES: 'Er du sikker på at du vil afbryde ? Ændringer vil blive slettet',
    FILL_ALL_FIELDS: 'Du skal udfylde alle felter udover titel og tekst',
    SAVE_NEWS: 'Gemmer nyheder',
    MAIN_TEXT: 'Hovedtekst',
    NEW_TITLE: 'Ny titel',
    REMOVE_POST: 'Fjern indlæg ?',
    REMOVE_POST_CONFIRM: 'Er du sikker på at du vil fjerne indlægget ?',
    REMOVE_FOR_OTHERS: 'Vil du fjerne indlægget selvom det eksisterer på andre restauranter ?',
    TIMELINE_SHARED: 'Delte tidslinjen !',
    TYPE: 'Type',
    SERVING_METHOD: 'Serveringsmåde',
    APPLIES_FOR: 'Gælder for',
    PRODUCT_TYPES: 'Produkttyper',
    DISCOUNT_PERCENTAGE: 'Procent i rabat',
    AUTOMATIC_ACTIVATION: 'Aktivér automatisk for brugere',
    CREATING_OFFER: 'Opret tilbud',
    OFFER_CREATED: 'Tilbud oprettet !',
    UPDATING_OFFER: 'Opdaterer tilbuddet',
    OFFER_UPDATED: 'Tilbud opdateret',
    REMOVING: 'Fjerner',
    OFFER_REMOVED: 'Fjernet tilbuddet',
    NEW_BUTTON: 'Ny knap',
    CREATE_NEW_BUTTON: 'Opret ny knap',
    CONNECT_BUTTON: 'Hvad skal knappen knyttes til ?',
    CHOOSE_BG_IMAGE: 'Vælg baggrundsbillede…',
    BUTTON_LAYER: 'Knaplager',
    FREE_TEXT: 'Fritekst',
    ACTIVATE_IN_POS: 'AKTIVÉR PÅ KASSEN',
    ALLERGY_INFO_SAVED: 'Allergi-info gemt og kan ses i appen',
    MINIMUM_BONUS_SAVED: 'Minimum bonus er gemt',
    PAYMENT_OPTIONS_SAVED: 'Betalingsalternativ er gemt',
    DELIVERY_OPTIONS_SAVED: 'Serveringsindstillinger er gemt',
    NOTIFICATION_SAVED: 'Meddelelse gemt',
    INSTAGRAM_SAVED: 'Instagram gemt',
    FACEBOOK_SAVED: 'Facebook gemt',
    MENU_STYLE_SAVED: 'Menu layout gemt',
    FOOD_SETTINGS_SAVED: 'Mad indstillinger er gemt',
    DRINK_SETTINGS_SAVED: 'Drik indstillinger er gemt',
    DISCOUNT_SETTINGS_SAVED: 'Rabat indstillinger er gemt',
    COUPON_NAME: 'Kuponens navn',
    PERCENTAGE_IN_DISOUNT: 'Antal procent i rabat',
    TAKEAWAY: 'Takeaway',
    TABLE_SERVING: 'Bordservering',
    COLLECT_IN_BAR: 'Hent i baren',
    DELIVERY: 'Levering',
    SENDING_PUSH: 'Sender pushbeskeder',
    PUSH_SENT: 'Pushbesked sendt !',
    GENERAL: 'Generelt',
    GENERATE_REPORT: 'Generér rapport',
    PUSH_NOTIFICATIONS: 'Pushbeskeder',
    OFFERS: 'Tilbud',
    DELIVERY_OPTIONS: 'Serveringsalternativ',
    PAYMENT_OPTIONS: 'Betalingsalternativ',
    USE_SAVE_BUTTON: 'Ændringer på denne side påvirker mobilappen, og Gem-knapperne skal bruges, før ændringerne træder i kraft ',
    OPEN_FOR_SALE: 'Åben for salg',
    MESSAGE_WHEN_CLOSED: 'Meddelelse til kunden når fanen er lukket ',
    FOOD_TAB: 'Mad fane',
    DRINK_TAB: 'Drik fane',
    NOTIFICATION_IN_APP: 'Meddelelse i appen',
    SHOW_NOTIFICATION: 'Vis meddelelse',
    NOTIFICATION_TEXT: 'Tekst til meddelelse',
    ALLERGY_INFO: 'Allergi-info',
    PHONE_NUMBER: ' Telefonnummer',
    OPENING: 'Åbner klokken',
    CLOSING: 'Lukker klokken',
    SHOW_FACEBOOK: 'Vis Facebook-kort på startsiden',
    INSTAGRAM: ' Instagram',
    SHOW_INSTAGRAM: 'Vis Instagram-kort på startsiden',
    WHICH_ACTIVE: 'Hvilke skal være aktiveret i appen ?',
    SWISH: 'Swish',
    TAB: 'Bord bemærkning',
    TABLENUMBER_FROM: 'Bord nummer fra',
    TABLENUMBER_TO: 'Bord nummer til',
    AREA_1: 'Område 1',
    AREA_2: 'Område 2',
    AREA_3: 'Område 3',
    AREA_4: 'Område 4',
    DELIVERY_RADIUS: 'Leveringsradius i meter',
    DELIVERY_RADIUS_COST: 'Pris for leveringsradius',
    EDIT_LOCATIONS: 'Rediger pladser',
    MINIMUM_FEE: 'Minimumafgift',
    BONUSSYSTEM: 'Bonussystem',
    LEAST_AMOUNT_BONUS: 'Mindste antal point for at handle med point',
    GLOBAL_OFFERS: 'Globale tilbud',
    APPLIES_TO_ALL: 'Gælder for alle i appen',
    ONETIME: 'Engangs',
    USER_GROUPS: 'Brugergrupper',
    ADD_NEW: 'Opret ny',
    GROUP_NAME: 'Gruppenavn',
    DISCOUNT_CODES: 'Rabatkoder',
    DISCOUNTPERCENT: 'Rabatprocent',
    APPLY_AUTOMATICALLY: 'Anvend rabatkode automatisk for kunder i appen',
    SEND_PUSH: 'Send pushbesked',
    SEND_PUSH_TEXT: 'Meddelelse sendes øjeblikkeligt ud til alle lokale brugere',
    SEND: 'Send',
    ALTERNATIVES: 'Alternativ',
    HEADER_EX: 'Rubrik (fx grad af stegning)',
    OPTION_TYPE: 'Type af tilvalg',
    CHECKBOX: 'Afkrydsningsfelt',
    MULTIPLE: 'Flere muligheder',
    PRICE: 'Pris',
    OPTIONS: 'Tilvalg',
    COPY_FROM: 'Kopiér fra',
    USING_STEP_BY_STEP: 'Bruger trin-for-trin flow',
    HEADER: 'Rubrik',
    SUBHEADER: 'Underrubrik',
    LINK_WITH: 'Koblet med',
    EDIT_EXCEPTION: 'Skift undtagelse',
    REASON: 'Anledning',
    REASON_DETAILS: 'Anledningsdetaljer',
    ACCOUNTING: 'Kontering',
    SEARCH_PRODUCT: 'Søg produkt',
    DELIVERY_TYPES: 'Leveringmåde',
    REMOVE_EXCEPTION_CONFIRM: 'Vil du fjerne undtagelsen ?',
    REMOVE_EXCEPTION: 'Fjern undtagelse !',
    SAVE_WITHOUT_ACCOUNTING: 'Du kan ikke gemme produkter uden bogføringsoplysninger og produkttype',
    REMOVE_PRODUCT_RESTURANTS: 'Vil du fjerne produktet fra flere restauranter',
    PRODUCT_SHARED: 'Del produktet !',
    PRODUCT_INFORMATION: ' Produktinformation',
    BASIC_INFO: 'Basisinformation',
    ACTIVE: 'Aktiv',
    PRODUCT_ACTIVE: 'Produkt aktiv',
    PRICE_KR: 'Pris i',
    BONUS_CONVERT: 'Procent som konverteres til bonuspoint ved køb af produkt',
    ONUS_CONVERT_WALLET:'Procent som konverteres til bonuspoint ved køb af mobile wallet',
    NOT_ALLOWED_TO_BY_WITH_BONUS: 'Får inte köpas för bonuspoäng',
    DESCRIPTION_SHORT: 'Beskrivelse (kort)',
    DESCRIPTION_LONG: 'Beskrivelse (lang)',
    CATEGORY_BONG_INFO: 'Kategori- og bon-information',
    NAME_ON_BONG: 'Navn på bon (valgfri)',
    PRODUCT_TYPE: 'Produkttype',
    PRODUCT_CATEGORY: 'Produktkategori',
    BONG_CATEGORY: 'Bonkategori',
    ACCOUNTING_VAT: 'Bogføring og moms',
    EXCEPTION: 'Undtagelse',
    CONTAINS_ALCOHOL: 'Produktet indeholder alkohol',
    AGE_LIMIT: 'Aldersgrænse 18+',
    PURCHASE: 'Indkøb',
    COST: 'Kostpris',
    MARGIN: 'Margen',
    TAGS: 'Mærker',
    NEW_TAG: 'Nyt mærke',
    EXTERNAL_ID: 'Eksternt ID',
    STOCK_BALANCE: 'Lagersaldo',
    COUNT: 'Antal',
    ACOUNTINGS: 'Konteringer',
    VAT_RATE: 'Momssats',
    VAT_RATES: 'Momssatser',
    ACCOUNTING_ACCOUNT: 'Bogføringskonto',
    NO_ACCOUNT: 'Ingen konto',
    ACCOUNTING_CREATED: 'Kontering oprettet',
    PAYMENT_TYPES: 'Betalingsmetode',
    ACTIVATED_PAYMENT_TYPES: 'Aktiveret betalingsmetode',
    ADITIONAL_PAYMENT_TYPES: 'Flere betalingsmetoder',
    NO_PAYMENT_TYPES: 'har ingen betalingsmetoder aktiveret. Tryk på Flere betalingsmetoder for at aktivere en',
    ADDED: 'Tilføjet',
    PAYMENT_FEES: 'Der er betalingsmetoder er forbundet med gebyrer. Her vælger du konti til disse ',
    VAT: 'Moms',
    HOME: 'Hjem',
    REPORTS: 'Rapporter',
    ADMIN: ' Administration',
    NEWS: 'Nyhedsstrøm',
    APP: 'App',
    POS: 'KASSE',
    STAFF_CARD: 'Personalekort',
    MISC: 'Misc.',
    SETTINGS: 'Indstillinger',
    RESTURANT_INFO: 'Restaurantinformation',
    USERS: 'Brugere',
    SEE_ALL: 'Se alle…',
    STATISTICS: 'Statistik',
    DARK_MODE: 'Mørk tilstand',
    SELECT_UNIT: 'Vælg enhed',
    OPENING_H_SAVED: 'Åbningstider gemt',
    OPENING_H: 'Åbningstider',
    CLOSED: 'Lukket',
    OPENS: 'Åbner',
    CLOSES: 'Lukker',
    DEVIATIONS: 'Afvigelser',
    SELECT_LOGO: 'Vælg logo..',
    ORG_NO: 'CVR-nr.',
    ADRESS: 'Adresse',
    ZIP_CODE: 'Postnummer',
    POST_ADR: 'By',
    PHONE: 'Telefon',
    EMAIL: 'Email',
    ABOUT_US: 'Om os',
    ADD_USER: 'Tilføj brugere',
    ADD_DOMAIN_AUTO: 'Tilføj brugerdomæner, der automatisk føjes til denne brugergruppe ved registrering ',
    DOMAIN_EXAMPLE: '(fx kun foodandco.dk og ikke @foodandco.dk',
    LOAD: 'Lad',
    CHOOSE: 'Vælg ...',
    LINK_TO_PRODUCT: 'Link til produkt eller hjemmeside',
    START_WITH_HTTPS: 'skal starte med https: //',
    WHERE_IS_PRODUCT: 'Hvor er produktet?',
    OFFER_INFO_PLACEHODLER: 'Informationstekst for tilbuddet ...',
    OPTION_DESCRIPTION: 'Beskrivelse af tilbuddet',
    SELECT: 'Vælg',
    DISCOUNT_PERCENTAGE_PLACEHOLDER: 'Antal procent, brugeren får i rabat',
    WRITE: 'Skriv...',
    SEARCH_PRODUCT_OR_TAG: 'Søg produkt eller tag',
    STEP_PLACEHOLDER: 'Trin til at opbygge dit højre flow',
    ABOUT_US_PLACEHOLDER: 'Om oss',
    DOMAIN_EX: 'Domæne f.eks. Pubq.se',
    TIME_CONTROL_MENU: 'Timer menuen',
    SET: 'Indstill',
    SCHEDULE: 'Skema',
    ACTIVATION_TIME: 'Aktiveringstid',
    ACTIVATE_MENU: 'Aktiver menu',
    WEEK: 'Uge',
    SELECT_Z_REPORT: 'Vælg Z-rapporter',
    FROM_TO: 'Fra - Til',
    SEND_END_TO_FORTNOX: 'Sende denne dagsafslutning til Fortnox ?',
    IMPORT_SUCCESSFUL: 'Import gennemført',
    IMPORT_FAILED: 'Import fejlede',
    WHICH_EMAIL: 'Hvilken mailadresse skal filen sendes til ?',
    SUMD_Z_REPORTS: 'Samlet Z-rapporter',
    Z_REPORT: 'Z-rapporter',
    MAIL: 'Mail',
    PREVIOUS_MONTH: 'Foregående måned',
    COMPILATION: 'Sammenfatning',
    JOURNALNR: 'Journal nr.',
    CREATED: 'Oprettet',
    RECEIPTNO_FROM_TO: 'Kvittering fra - til',
    SUM: 'Sum',
    INTEGRATIONS_HEADER: 'Integrationer',
    BUY_REBUY: 'Køb og genkøb',
    WRONG_HIT: 'Forkert indtastning',
    CANCELD: 'Afbrudt',
    PROFORMA: 'Proforma',
    CREATING_ENDOFDAY: 'Opretter dagsafslutning',
    NOT_ENOUGH: 'Du har ikke omsat tilstrækkeligt',
    END_OF_DAY_100: 'Dagsafslutning skal indeholde mere end 100',
    Z_ENDOFDAY: 'Z-dagsafslutning',
    PREL_X_REPORT: 'Foreløbig X-rapport',
    PAID: 'Betalt',
    SUM_PAYMENTS: 'Summen af alle betalingsmåder',
    CHANGE_IN: 'Byttepenge IND',
    CHANGE_OUT: 'Byttepenge UD',
    ACCOUNTING_REPORTS: 'Bogføringsrapport til regnskab',
    STARTED: 'Startet',
    FINISHED: 'Afsluttet',
    ACCOUNTS: 'Konti',
    ROWS: 'Rækker',
    SALES: 'Salg',
    PAYMENTS: 'Betalingsmåde',
    RECEIPTS: 'Kvittering',
    ACCOUNTING_ACCOUNTS: 'Bogføringskonti',
    TODAY:'I dag',
    YESTERDAY:'I går',
    LAST_WEEK:'Sidste uge',
    THIS_MONTH:'Denne måned',
    LAST_MONTH:'Sidste måned',
    USER:'Bruger',
    ORDERS:'Ordrer',
    END_OF_DAY:'End of day',
    HISTORY:'Historie',
    TIP_REPORT: 'Rapport over tip',
    DESC_GENERATE_REPORT: 'Rapporten genereres til en PDF-fil, der automatisk hentes.',
    GENERATING_REPORT: 'Genererer rapporten',
    ERROR_TIP_REPORT: 'Noget gik galt i generationen af tip-rapporten!',
    COMPANY_NAME: 'Firmanavn',
    EMAIL_NOT_VALID: "E-postadressen er ikke gyldig"
}