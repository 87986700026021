import { Injectable } from '@angular/core';
import * as swedish from "./languages/language.se";
import * as english from "./languages/language.en";
import * as danish from "./languages/language.dk";
import * as norwegian from "./languages/language.no";
//import { AppConfiguration } from 'src/app/app.configuration';
@Injectable({
    providedIn: "root"
})
export class LanguageProvider {
    availableLanguages = [
        {lang:"Svenska", code:"se"},
        {lang:"English", code:"en"},
        {lang:"Dansk", code:"dk"},
        {lang:"Norsk",code:"no"}];

    constructor() {

    }
    currentLanguage = "se";
    getAvailableLanguages() {
        return this.availableLanguages;
    }
    setLanguage(languageCode: string) {
        this.currentLanguage=languageCode;
    }
    getString(translationProperty: string, languageCode: string, parameters?: string[]) {
        switch (languageCode) {
            case "se": {
                if (parameters) return swedish.lang.get(translationProperty, parameters);
                else return swedish.lang[translationProperty]
            }
            case "en": {
                if (parameters) return english.lang.get(translationProperty, parameters);
                return english.lang[translationProperty];
            }
            case "dk": {
                if (parameters) return danish.lang.get(translationProperty, parameters);
                return danish.lang[translationProperty];
            }
            case "no": {
                if (parameters) return norwegian.lang.get(translationProperty, parameters);
                return norwegian.lang[translationProperty];
            }
            default: {
                return null;
            }
        }
    }
}