import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'loading-overlay',
    template: `
    <div style="height:100%; width:100%; position:absolute; background: rgba(25,25,25,0.8); top:0; z-index: 999; display: flex; flex-direction:column;justify-content: center; align-items: center;">
     
          <ion-spinner color="light" style="height:68px; width:68px;"></ion-spinner>
          <br>
          <h3 class="loader-text">Loggar in...</h3>
     
  </div>
    `,
    styleUrls: ["./loading-overlay.component.scss"]
})
export class LoadingOverlayComponent implements OnInit {
    constructor() { }

    ngOnInit(): void { }
}
