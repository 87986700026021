import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";

@Pipe({ name: 'formatcurrency' })
export class FormatCurrencyPipe implements PipeTransform {

    constructor() {

    }
    transform(value: any): any {
        if (value === NaN || value === "" || !value)
            return "0";
        try {
            value = parseFloat(value) / 100;
            return value;
        }
        catch { return "0"; }

    }
    convertCurrencyIfNeeded(value) {
        if (value === "")
            return null;
        return parseFloat(value) * 100;
    }
}