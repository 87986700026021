import { Component, Input } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { LanguagePipe } from "../../../pipes/language.pipe";
import { LanguageProvider } from "../../../services/language/language.provider";
import { ProductTypeChild } from "../../../services/settings.service";
import { toggleFlags } from "../../../services/unit.service";
@Component({
    templateUrl: "edit-category.component.html",
    styleUrls: ["edit-category.component.scss"]
})

export class EditCategoryComponent {
    @Input() category: ProductTypeChild;
    @Input() header: string;
    editedCategory: ProductTypeChild;
    editedProductTypeId: string;
    flags:any = toggleFlags;
    externalIdName="";

    constructor(private modalController: ModalController,
        private languagePipe:LanguagePipe,
        public languageProvider:LanguageProvider
    ) {

    }

    ngOnInit() {
        this.editedCategory = JSON.parse(JSON.stringify(this.category));
        if (!this.editedCategory.productTypeId) this.editedCategory.productTypeId = "1";
        this.externalIdName=this.languagePipe.transform("Externt Id",'EXTERNAL_ID',this.languageProvider.currentLanguage);
        if(this.flags.compassNoConfig)this.externalIdName="Main Itemgroup Id"
        
    }

    confirm() {
        this.modalController.dismiss(this.editedCategory, "confirm");
    }
    dismiss() {
        this.modalController.dismiss(null, "cancel");
    }
}