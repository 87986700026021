import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/storage';
import { UserService } from '../../services/user.service';
import { finalize, map } from 'rxjs/operators';
import { AngularFireDatabase } from '@angular/fire/database';
import { ModalController, ActionSheetController, ToastController, LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { MenuService } from '../../services/menu.service';
import { LanguagePipe } from '../../pipes/language.pipe';
import { LanguageProvider } from '../../services/language/language.provider';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})
export class ImageGalleryComponent implements OnInit {
  @Input() asModal = false;
  routeName;
  files = [];
  filesListRef;
  compressing = false;
  uploading = false;
  public uploadPercent = null;

  constructor(private actionSheetController: ActionSheetController, private loadingController: LoadingController,
    private toastController: ToastController, private http: HttpClient, private storage: AngularFireStorage,
    private afDatabase: AngularFireDatabase, private userService: UserService, private menuService: MenuService,
    private modalController: ModalController, public languageProvider: LanguageProvider,
    private languagePipe: LanguagePipe) {
    this.userService.selectedUnitObs.subscribe(x => {
      if (!x) return;
      this.routeName = x.routeName;
      this.downloadGallery(x.routeName);
    });

  }
  async shareGallery() {
    let units = await this.userService.showUnitsSelectAlert(this.userService.user.value.units);
    if (units.length > 0) {
      let loading = await this.loadingController.create({
        spinner: "bubbles",
        message: ``
      });
      loading.present();
      try {
        for (let index = 0; index < units.length; index++) {
          const element = units[index];
          loading.message = this.languagePipe.transform(`Sparar galleri för ${element.info.name}`,'SAVING_GALLERY',this.languageProvider.currentLanguage,element.info.name);
          this.files.forEach(async x => {
            await this.afDatabase.database.ref(
              `adminApp/clients/${element.routeName}/settings/gallery/0/files/${x.key}`
            ).set(x);
          });
        }
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "success",
          message: this.languagePipe.transform("Delade galleriet!",'GALLERY_SHARED',this.languageProvider.currentLanguage),
          duration: 2500,
          position: "top"
        });
        toast.present();
      } catch (error) {
        alert(JSON.stringify(error));
        loading.dismiss();
        let toast = await this.toastController.create({
          color: "danger",
          message: "Något gick fel",
          duration: 1500,
          position: "top"
        });
        toast.present();
      }
    }
  }
  async showActionSheet(file) {
    let buttons = [
      {
        text: this.languagePipe.transform("Ta bort",'REMOVE',this.languageProvider.currentLanguage),
        cssClass: "color-danger",
        role: "destructive",
        icon: "trash",
        handler: () => {
          if (confirm(this.languagePipe.transform(`Är du säker?`,'ARE_YOU_SURE',this.languageProvider.currentLanguage))) {
            return true;
          } else return false;
        }
      },
      {
        text: this.languagePipe.transform(`Använd bild`,'USE_IMAGE',this.languageProvider.currentLanguage),
        role: "confirm",
        icon: "image"
      },
      {
        text: this.languagePipe.transform(`Avbryt`,'CANCEL',this.languageProvider.currentLanguage),
        role: "cancel",
        icon: "close"
      }
    ]
    if (!this.asModal)
      buttons.splice(1, 1);
    const actionSheet = await this.actionSheetController.create({
      header: `${file.name}`,
      buttons: buttons
    });
    await actionSheet.present();
    let result = await actionSheet.onDidDismiss();
    switch (result.role) {
      case "confirm": {
        this.selectFile(file);
        break;
      }
      case "destructive": {
        this.deleteFile(file);
        break;
      }
      default: {

      }
    }
  }
  getBase64String(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }
  goBack() {
    this.modalController.dismiss(null, "cancel");
  }
  async uploadFile(event) {
    this.compressing = true;
    const file = event.target.files[0];
    for (let index = 0; index < event.target.files.length; index++) {
      const file = event.target.files[index];
      this.uploadFileCore(file);
    }
  }
  async uploadFileCore(file) {
    var base = await this.getBase64String(file);
    this.http.post(`${environment.primeApiUrl}/resizeImage`, base, { headers: {} }).subscribe((x: any) => {
      this.compressing = false;
      this.uploading = true;
      let fileName = file.name;
      let fileId = `${fileName}_${Date.now() / 1000}`;
      var ref = this.storage.ref(`${this.routeName}/${fileId}`);
      const task = ref.putString(x.data, "base64", { contentType: file.type, customMetadata: { timestamp: Date.now().toString() } });

      task.percentageChanges().subscribe(x => {
        this.uploadPercent = x.toFixed(0) + "%";
      });
      task.snapshotChanges().pipe(
        finalize(async () => {
          let fileMeta =
          {
            name: file.name,
            id: fileId,
            imageUrl: await ref.getDownloadURL().toPromise(),
            blurHash: ""
          }
          console.log("encoding...")
          let blurHash = await this.encodeImageToBlurHash(fileMeta.imageUrl);
          fileMeta.blurHash = blurHash;
          this.uploading = false;
          this.filesListRef.push(fileMeta);
        })).subscribe(x => {
          console.log("done");
        });
    });
  }
  async encodeImageToBlurHash(imageUrl) {
    return await this.menuService.encodeImageToBlurhash(imageUrl);
  }
  ngOnInit() {
  }
  downloadGallery(routeName: string) {
    this.filesListRef = this.afDatabase.list<any>(
      `adminApp/clients/${routeName}/settings/gallery/0/files`
    );
    this.filesListRef
      .snapshotChanges()
      .pipe(
        map<any, any>(changes => changes.map(c => (
          { key: c.payload.key, ...c.payload.val() }
        ))
        ))
      .subscribe(x => {
        this.files = x;
      });
  }
  async selectFile(file) {
    if (!file.blurHash) {
      let loading = await this.loadingController.create({
        spinner: "crescent",
        message: this.languagePipe.transform(`Avbryt`,'CANCEL',this.languageProvider.currentLanguage)
      });
      loading.present();
      let blurHash = await this.encodeImageToBlurHash(file.imageUrl);
      file.blurHash = blurHash;
      await this.filesListRef.query.ref.child(`${file.key}/blurHash`).set(blurHash);
      loading.dismiss();
    }
    this.modalController.dismiss({ imageUrl: file.imageUrl, blurHash: file.blurHash });
  }
  async deleteFile(file) {
    await this.filesListRef.remove(file.key);
    let toast = await this.toastController.create({
      color: "success",
      position: "top",
      animated: true,
      message: this.languagePipe.transform(`\uD83D\uDE04 Tog bort ${file.name}`,'REMOVED_IMAGE',this.languageProvider.currentLanguage,file.name),
      duration: 2000
    });
    toast.present();
    console.log("removed");
  }

}
