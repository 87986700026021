import { Component, OnInit } from "@angular/core";
import { UserService, User } from "../services/user.service";
import { MenuController, PopoverController } from "@ionic/angular";
import { AccountMenuComponent } from "../modules/account/account-menu/account-menu.component";

@Component({
  selector: "app-main-header",
  templateUrl: "./main-header.component.html",
  styleUrls: ["./main-header.component.scss"]
})
export class MainHeaderComponent implements OnInit {
  currentUser: User;
  constructor(
    private userService: UserService,
    private popoverController: PopoverController,
    private menuController: MenuController
  ) {
    this.userService.user.subscribe(x => {
      this.currentUser = x;
    });
  }

  ngOnInit() { }
  toggle() {
    this.menuController.toggle("left");
  }
  async openAccountMenu($event) {
    const popover = await this.popoverController.create({
      component: AccountMenuComponent,
      event: $event,
      translucent: true,
      animated: true
    });
    popover.present();
  }
}
