import { Component } from "@angular/core";


import { ModalController, ToastController, AlertController } from "@ionic/angular";
import { ArticleAccounting, ArticleAccountingService } from "../../../services/article-accounting.service";
import { VatAccountingService, VatAccounting } from "../../../services/vat-accounting.service";
import { toggleFlags } from "../../../services/unit.service"
import { LanguagePipe } from "../../../pipes/language.pipe";
import { LanguageProvider } from "../../../services/language/language.provider";

@Component({
  selector: "jo-article-accounting",
  templateUrl: "./article-accounting.component.html",
  styleUrls: ["./article-accounting.component.scss"]
})
export class ArticleAccountingComponent {
  flags:any = toggleFlags;
  articleAccountings: ArticleAccounting[] = [];
  vatAccountings: VatAccounting[];
  loading;
  selectedSegment: string;
  constructor(private articleAccountingService: ArticleAccountingService, private alertController: AlertController, private vatAccountingService: VatAccountingService, private toastController: ToastController, private modalController: ModalController,
    private languagePipe:LanguagePipe, public languageProvider:LanguageProvider) {
    this.loading = true;
    this.articleAccountingService.articleAccountings.subscribe(x => {

      if (this.articleAccountings.length === 0){
        this.articleAccountings = x;
      }
      else {
        x.forEach(artAcc => {         
          let hitIndex = -1;
          var result = this.articleAccountings.filter((p, index) => {
            if (p.key === artAcc.key) {
              hitIndex = index;
              return true;
            }
            return false;
          })[0];
          if (result) {
            this.articleAccountings[hitIndex] = result;
          }
        });
      }
      this.loading = false;
    });
    this.vatAccountingService.vatAccountings.subscribe(x => {
      this.vatAccountings = x;
    });
  }
  ngOnInit() {
    this.selectedSegment = "accounts";
  }

  segmentChanged(e) {

  }
  validateAcc(acc) {
    setTimeout(() => {
      if (!acc.accountNumber || acc.accountNumber === "0000")
        acc.accountNumber = null;
    }, 100)
  }
  async saveArticleAccountings() {
    this.articleAccountingService.saveAccounts(this.articleAccountings);
    var toast = await this.toastController.create({
      color: "success",
      message: `\uD83D\uDC4D ${this.languagePipe.transform('Sparat','SAVED',this.languageProvider.currentLanguage)}`,
      duration: 500,
      position: "top"
    });
    toast.present();
  }
  onAccountSelected(event, account) {
    account.accountNumber = event;
    this.saveArticleAccountings();
  }
  onAccountCleared(event, account) {
    account.accountNumber = null;
    this.saveArticleAccountings();
  }
  onSelectedVatAccount(vat, index) {
    //Somethings bugged in the ion-select directive if i pass 0 it will just output an empty string
    if (vat.detail.value === -1)
      this.articleAccountings[index].tax = 0;
    else
      this.articleAccountings[index].tax = vat.detail.value;

    this.saveArticleAccountings();
  }
  setDefaultAccountNumber(acc) {
    acc.accountNumber = "0000";
  }
  updateArticleAccounting(articleAccounting: ArticleAccounting) {
    articleAccounting.tax = parseFloat(<any>articleAccounting.tax);
    this.articleAccountingService.updateArticleAccounting(articleAccounting);

  }
  async deleteArticleAccount(art: ArticleAccounting, index) {
    if (await this.showConfirm(this.languagePipe.transform( "Är du säker på att du vill ta bort " + art.name + "?",'CONFIRM_REMOVE_ACCOUNTING',this.languageProvider.currentLanguage,[art.name]))) {
      this.articleAccountings.splice(index, 1);
      this.articleAccountingService.deleteArticleAccounting(art);

      this.showToast(this.languagePipe.transform(`${art.name} borttagen`,'REMOVE_ACCOUNTING',this.languageProvider.currentLanguage), "danger");
    }

  }
  addArticleAccounting() {
    var articleAccounting = this.articleAccountingService.addArticleAccounting(articleAccounting);
    this.articleAccountings.push(articleAccounting);
    this.showToast(this.languagePipe.transform(`Kontering skapad`,'ACCOUNTING_CREATED',this.languageProvider.currentLanguage), "success");
  }
  async showConfirm(message) {
    var alert = await this.alertController.create({
      buttons: [{ text: this.languagePipe.transform("Avbryt",'CANCEL',this.languageProvider.currentLanguage), role: "cancel" }, 
      { text: this.languagePipe.transform("Ok",'OK',this.languageProvider.currentLanguage), role: "confirm" }],
      message: message
    });
    alert.present();
    var result = await alert.onDidDismiss();
    if (result.role === "confirm")
      return true;
    return false;
  }
  async showToast(message, color) {
    var toast = await this.toastController.create({
      color: color,
      message: message,
      duration: 1500,
      position: "top"
    });
    toast.present();
  }
  shown(name, $index) {
    return this[name + $index] === true;
  }
  toggle(name, $index) {
    const property = name + $index;
    this[property] = !this[property];
  }
  toggleArtAccName(name, $index) {
    let property = `${name}_name${$index}`;
    this[property] = !this[property];
  }
  shownArtAccName(name, $index) {
    let property = `${name}_name${$index}`;
    return this[property] === true;
  }
  expand(name, $index) {
    const property = name + $index;
    this[property] = !this[property];
  }
  isExpanded(name, $index) {
    return this[name + $index];
  }
  createArticleAccounting() {

  }
}


