import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { LanguageProvider } from "../../services/language/language.provider";

import { UserService, Unit, User } from "../../services/user.service";

@Component({
  selector: "unit-selection",
  templateUrl: "./unit-selection.component.html",
  styleUrls: ["./unit-selection.component.scss"]
})
export class UnitSelectionComponent implements OnInit {
  user: User;
  loading = true;
  searchTerm = { term: "", property: "info.name" };
  constructor(private userService: UserService, private router: Router, private languageProvider: LanguageProvider) {
    this.userService.user.subscribe(x => {
      this.user = x;
      if (this.user && this.user.units) {
        if (this.user.units.length === 1) {
          setTimeout(() => {
            this.goto(this.user.units[0]);
          }, 200);

        }
        setTimeout(() => {
          this.loading = false;
        }, 500);
      }
    });
  }
  ionViewDidEnter() {
    this.userService.hideSidebar = true;
  }
  goto(unit: Unit) {
    this.userService.selectedUnitObs.next(unit);
    this.router.navigateByUrl(`unit/${unit.routeName}`);
  }
  ngOnInit(): void { }
}
