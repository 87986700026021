import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NewsService {

  constructor(private afDatabase: AngularFireDatabase) {

  }
  getKey() {
    return this.afDatabase.createPushId();
  }
  getNews(routeName) {
    return this.afDatabase.list<any>(`adminApp/clients/${routeName}/news`).snapshotChanges()
      .pipe(
        map(changes => changes.map(c => (
          { key: c.payload.key, editMode: false, ...c.payload.val() }
        ))
        ));
  }
  addNews(routeName, news, key?) {
    if (key)
      return this.afDatabase.database.ref(`adminApp/clients/${routeName}/news/${key}`).set(news);
    else
      return this.afDatabase.list(`adminApp/clients/${routeName}/news`).push(news);
  }
  saveNews(routeName, news) {
    return this.afDatabase.list(`adminApp/clients/${routeName}/news`).update(news.key, news);
  }
  removeNews(routeName, news) {
    return this.afDatabase.list(`adminApp/clients/${routeName}/news`).remove(news.key);
  }
  async saveArray(routeName, newsArray) {
    try {
      await newsArray.forEach(async (element,index) => {
        element.editMode = false;
        element.index = index;
        await this.afDatabase.database.ref(`adminApp/clients/${routeName}/news/${element.key}`).set(element);
      });
      return true;
    } catch (error) {
      throw error;
    }

  }


}
