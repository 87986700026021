import { NgModule, LOCALE_ID } from "@angular/core"
import { BrowserModule } from "@angular/platform-browser"
import { RouteReuseStrategy } from "@angular/router"
import { ServiceWorkerModule } from "@angular/service-worker"
import { IonicModule, IonicRouteStrategy } from "@ionic/angular"
import { SplashScreen } from "@ionic-native/splash-screen/ngx"
import { StatusBar } from "@ionic-native/status-bar/ngx"

import { AppComponent } from "./app.component"
import { AppRoutingModule } from "./app-routing.module"
import { UnitSelectionComponent } from "./modules/unit-selection/unit-selection.component"
import { BUCKET  } from "@angular/fire/storage"
import { HttpClientModule } from "@angular/common/http"
import { AngularFireAuthModule } from "@angular/fire/auth"
import { AngularFireModule } from "@angular/fire"
import { AngularFireDatabaseModule } from "@angular/fire/database"
import { FormsModule } from "@angular/forms"
import { DndModule } from "ngx-drag-drop"
import { AuthGuard } from "./services/auth-guard/auth-guard"
import { ContextGuard } from "./services/context-guard/context-guard"
import { UserService } from "./services/user.service"
import { MenuService } from "./services/menu.service"
import { LoginService } from "./services/login.service"
import { PosSettingsService } from "./services/pos-settings.service"
import { ArticleAccountingService } from "./services/article-accounting.service"
import { APP_BASE_HREF } from "@angular/common"
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"
import { OrderService } from "./services/order.service"
import { LoginPageModule } from "./modules/login/login.module"
import { SettlementsService } from "./services/settlements.service"
import { registerLocaleData } from "@angular/common"
import locale from "@angular/common/locales/se"
import localeExtra from "@angular/common/locales/extra/se"
import { environment } from "../environments/environment"
import { SharedPosComponentsModule } from "./modules/sharedPosComponents.module"
import { ChoosePrinterComponent } from "./choose-printer/choose-printer.component"
import { AngularFirestoreModule } from "@angular/fire/firestore"
import { EsKassaIntegrationComponent } from "./modules/integrations/eskassa/eskassa.component"
import { FortnoxIntegrationComponent } from "./modules/integrations/fortnox/fortnox.component"
import { HandleTimeForMenuComponent } from "./modules/menu/menu-list/single-menu/time-menu/time-menu.component"
import { EditCategoryComponent } from './modules/misc/categoryModal/edit-category.component'
import { SettingService } from "./services/settings.service"
import { ExceptiopnComponent } from "./modules/products/exception/exception.component"
import { EditPosUserComponent } from "./modules/pos-settings/pos-users/edit-pos-user.component"
import { CreateProductPopoverComponent } from "./modules/products/create-product-popover/create-product-popover.component"

registerLocaleData(locale, localeExtra)
registerLocaleData(locale, 'se-SE')

@NgModule({
    declarations: [
        AppComponent,
        UnitSelectionComponent,
        ChoosePrinterComponent,
        HandleTimeForMenuComponent,
        CreateProductPopoverComponent,
        EditCategoryComponent,
        EditPosUserComponent,
        ExceptiopnComponent,
        EsKassaIntegrationComponent,
        FortnoxIntegrationComponent,
    ],
    entryComponents: [
        ChoosePrinterComponent,
        HandleTimeForMenuComponent,
        CreateProductPopoverComponent,
        EditCategoryComponent,
        EditPosUserComponent,
        ExceptiopnComponent,
        EsKassaIntegrationComponent,
        FortnoxIntegrationComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularFireAuthModule,
        AngularFireModule.initializeApp(environment.database),
        AngularFireDatabaseModule,
        AngularFirestoreModule,
        FormsModule,
        DndModule,
        IonicModule.forRoot(
            { mode: "md" }
        ),
        AppRoutingModule,
        LoginPageModule,
        SharedPosComponentsModule,
        ServiceWorkerModule.register("ngsw-worker.js"),
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        AuthGuard,
        ContextGuard,
        UserService,
        SettingService,
        MenuService,
        LoginService,
        OrderService,
        PosSettingsService,
        ArticleAccountingService,
        SettlementsService,
        { provide: APP_BASE_HREF, useValue: "/" },
        { provide: LOCALE_ID, useValue: "se-SE" },
        {
            provide: BUCKET,
            useValue: environment.database.storageBucket,
        },
    ],

    bootstrap: [AppComponent],
})
export class AppModule { }
