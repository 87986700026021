
import { NgModule, Inject, LOCALE_ID } from "@angular/core";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IonicModule } from "@ionic/angular";
import { CommonModule } from "@angular/common";
import { LoadingOverlayComponent } from "./loading-overlay/loading-overlay.component";
import { FormatCurrencyPipe } from "../pipes/format-currency.pipe";
import { DisplayCurrencyPipe } from "../pipes/display-currency.pipe";
import { VatAccountingComponent } from './settings/vat-accounting/vat-accounting.component';
import { PaymentOptionAccountingComponent } from './settings/payment-option-accounting/payment-option-accounting.component';
import { ArticleAccountingComponent } from "./settings/article-accounting/article-accounting.component";
import { SelectAllDirective } from "../directives/selectall.directive";
import { ImageGalleryComponent } from './image-gallery/image-gallery.component';
import { AccountsSearchbarComponent } from "../accounts-searchbar/accounts-searchbar.component";
import { MatFormFieldModule, MatAutocompleteModule, MatInputModule } from "@angular/material";
import { MainHeaderComponent } from "../main-header/main-header.component";
import { FilterPipe, FilterPropertyPipe } from "../pipes/filter.pipe";
import { SidebarComponent } from "./sidebar/sidebar.component";
import { HeaderMenuComponent } from "./header-menu/header-menu.component"
import { NewsStreamComponent } from './news-stream/news-stream.component';
import { ReversePipe } from "../pipes/reverse.pipe";
import { AccountMenuComponent } from "./account/account-menu/account-menu.component";
import { ProductsSearchComponent } from "./products/products-search/productsSearch.component";
import { LanguagePipe } from "../pipes/language.pipe";
import { CurrencyPipe } from '../pipes/currency.pipe'
import { MiscComponent } from "./misc/misc.component";
import { DisplayBonusPipe } from "../pipes/display-bonus.pipe";




@NgModule({
  declarations: [
    FilterPipe,
    FilterPropertyPipe,
    ReversePipe,
    LoadingOverlayComponent,
    FormatCurrencyPipe,
    DisplayCurrencyPipe,
    LanguagePipe,
    CurrencyPipe,
    DisplayBonusPipe,
    AccountsSearchbarComponent,
    VatAccountingComponent,
    PaymentOptionAccountingComponent,
    ArticleAccountingComponent,
    MiscComponent,
    SelectAllDirective,
    ImageGalleryComponent,
    MainHeaderComponent,
    SidebarComponent,
    HeaderMenuComponent,
    NewsStreamComponent,
    AccountMenuComponent,
    ProductsSearchComponent
  ],
  entryComponents: [
    ImageGalleryComponent,
    AccountMenuComponent
  ],
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [
    FormatCurrencyPipe,
    LanguagePipe,
    CurrencyPipe
  ],
  bootstrap: [],
  exports: [
    LoadingOverlayComponent,
    FormatCurrencyPipe,
    DisplayCurrencyPipe,
    CurrencyPipe,
    LanguagePipe,
    AccountsSearchbarComponent,
    SelectAllDirective,
    ImageGalleryComponent,
    MainHeaderComponent,
    FilterPipe,
    SidebarComponent,
    HeaderMenuComponent,
    ProductsSearchComponent,
    DisplayBonusPipe,
    FilterPropertyPipe
  ],
})
export class SharedPosComponentsModule { }

export class PosButton {
  text: string;
  command: string;
  itemName?: string;
  buttons?: PosButton[];
  color: string;
  size: string;
}

