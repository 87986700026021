import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Subscription } from 'rxjs';
import { UserService } from '../user.service';

@Injectable({
    providedIn: "root"
})
export class CurrencyProvider {

    currentCurrency = "SEK";
    subscriptions = new Subscription();

    // ISO 4217 Valutakoder https://www.iban.se/currency-codes
    availableCurrency = [
        { currency: "SEK", description: "Svenska kronor", symbol: "kr", infront: false, space: ' ' }, // Example 10 kr
        { currency: "NOK", description: "Norska kronor", symbol: "kr", infront: false, space: ' ' }, // Example 10 kr
        { currency: "DKK", description: "Dansk Krone", symbol: "kr", infront: false, space: ' ' }, // Example 10 kr
        { currency: "EUR", description: "Euro", symbol: "€", infront: true, space: '' }]; // Example €10
    currentRouteName: string;

    constructor(private afDatabase: AngularFireDatabase, private userService: UserService) {
        this.subscriptions.add(this.userService.selectedUnitObs.subscribe(async (x) => {
            if (!x) return null;
            this.currentRouteName = x.routeName;
            this.currentCurrency = (await this.afDatabase
                .database.ref(`Clients/${this.currentRouteName}/AppInfo/Context/currencyToUse`)
                .once("value")).val();
        }))
    }

    ngOnInit() {

    }

    ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    getAvailableCurrencies() {
        return this.availableCurrency;
    }

    setCurrency(currencyCode: string) {
        this.currentCurrency = currencyCode;
    }

    getCurrency(currencyCode: string) {
        let currencySettings = this.availableCurrency.filter(x => x.currency == currencyCode);

        if (currencySettings.length > 0)
            return currencySettings[0];
        else
            return this.availableCurrency[0];
    }
}