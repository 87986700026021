import { Component } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'create-product-popover',
  templateUrl: 'create-product-popover.component.html',
  styleUrls: ['create-product-popover.component.scss']
})
export class CreateProductPopoverComponent {
  constructor(private popoverController: PopoverController) {}
  
  select(choice){
    this.popoverController.dismiss(choice, 'selected');
  }
  dismiss(){
    this.popoverController.dismiss(null,'cancel');
  }
}