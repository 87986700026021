import { Injectable } from "@angular/core";
import { AngularFireList, AngularFireDatabase, AngularFireObject } from "@angular/fire/database";
import { Observable } from "rxjs";

@Injectable()
export class PosSettingsService {
  private buttonLayoutRef: AngularFireList<PosButton>;
  private useUsersRef: AngularFireObject<boolean>;
  private useUsersTypeRef: AngularFireObject<string>;
  posButtons: Observable<PosButton[]>;
  userPosUsers: Observable<boolean>;
  userPosUsersType: Observable<string>;
  constructor(private afDatabase: AngularFireDatabase) {}
  getButtonLayoutForRoute(routeName: string): Observable<PosButton[]> {
    this.buttonLayoutRef = this.afDatabase.list<PosButton>(
      `adminApp/clients/${routeName}/settings/pos/buttons`
    );
    this.posButtons = this.buttonLayoutRef.valueChanges();
    return this.posButtons;
  }
  saveButtonLayout(routeName: string, posButtons: PosButton[]) {
    this.afDatabase
      .object(`adminApp/clients/${routeName}/settings/pos/buttons`)
      .set(posButtons);
  }
  getUsePosLogin(routeName: string): Observable<boolean> {
    this.useUsersRef = this.afDatabase.object<boolean>(
      `adminApp/clients/${routeName}/settings/pos/useUsers`
    );
    this.userPosUsers = this.useUsersRef.valueChanges();
    return this.userPosUsers;
  }
  getUsePosLoginType(routeName: string): Observable<string> {
    this.useUsersTypeRef = this.afDatabase.object<string>(
      `adminApp/clients/${routeName}/settings/pos/useUsersType`
    );
    this.userPosUsersType = this.useUsersTypeRef.valueChanges();
    return this.userPosUsersType;
  }
  saveUsePosLogin(routeName: string, userUsers: boolean) {
    this.afDatabase
      .object(`adminApp/clients/${routeName}/settings/pos/useUsers`)
      .set(userUsers);
  }
  saveUsePosLoginType(routeName: string, type: string) {
    this.afDatabase
      .object(`adminApp/clients/${routeName}/settings/pos/useUsersType`)
      .set(type);
  }
  
}

export class PosButton {
  text: string;
  command: string;
  prefilled_freeText?:string;
  itemName?: string;
  itemKey?:string;
  buttons?: PosButton[];
  color: string;
  size: string;
  imageUrl?:string;
  blurHash?: string;
}
