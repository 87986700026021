import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../environments/environment';
import { AngularFireDatabase } from '@angular/fire/database';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SettlementsService {

  constructor(private http: HttpClient, private afAuth: AngularFireAuth, private afDatabase: AngularFireDatabase) {

  }

  async getCurrentSettlement(unitRouteName: string): Promise<Settlement> {
    var token = await (await this.afAuth.currentUser).getIdToken();
    var result = await this.http.get<Settlement>(`${environment.primeApiUrl}/getCurrentSettlement?unit=${unitRouteName}`, { headers: { Authorization: `Bearer ${token}` } }).toPromise();
    return result;
  }
  async closeCurrentSettlement(unitRouteName: string): Promise<Settlement> {
    var token = await (await this.afAuth.currentUser).getIdToken();
    var result = await this.http.get<Settlement>(`${environment.primeApiUrl}/closeCurrentSettlement?unit=${unitRouteName}`, { headers: { Authorization: `Bearer ${token}` } }).toPromise();
    return result;
  }
  async getClosedSettlements(unitRouteName: string, timestampStart, timestampStop, page: number, pageSize: number): Promise<{ data: Settlement[] }> {
    var token = await (await this.afAuth.currentUser).getIdToken();
    var result = await this.http.get<{ data: Settlement[] }>(`${environment.primeApiUrl}/getClosedSettlements?unit=${unitRouteName}&timestampStart=${timestampStart}&timestampStop=${timestampStop}&page=${page}&pageSize=${pageSize}`, { headers: { Authorization: `Bearer ${token}` } }).toPromise();
    return result;
  }
  async emailSettlement(unitRouteName: string, fromSettlementNo, toSettlementNo, emailAddress) {
    let data = {
      unitRouteName: unitRouteName,
      fromSettlementNo: fromSettlementNo,
      toSettlementNo: toSettlementNo,
      emailAddress: emailAddress
    };
    var result = await this.http.post<any>(`${environment.primeApiUrl}/emailSettlement`, data).toPromise();
    return result;
  }
  async getChange(routeName, dayString) {
    return (await this.afDatabase.database.ref(
      `adminApp/clients/${routeName}/settings/pos/cashChange/${dayString}`
    ).once("value")).val();
  }
  getRefunds(routeName) {
    return this.afDatabase.list<any>(`Clients/${routeName}/refundQueue_v2`).snapshotChanges().pipe(
      map(changes => changes.map(c => (
        { key: c.payload.key, ...c.payload.val() }
      ))
      ));
  }
}

export interface Client {
  id: string;
  vatNo: string;
  name: string;
  address1: string;
  address2: string;
  postcode: string;
  city: string;
  country: string;
  corporateNo: string;
}

export interface SalesLocation {
  id: string;
  name: string;
  address: string;
  postalCode: string;
  city: string;
  country: string;
  countryCode: string;
  phone: string;
  createdUtc: string;
  modifiedUtc: string;
}

export interface Line {
  category?: any;
  description: string;
  quantity: number;
  unitPrice: number;
  discountPercent: number;
  discountAmount: number;
  netAmount: number;
  vatAmount: number;
  vatPercent: number;
  vatAccountNo: string;
  totalAmount: number;
  salesAccountNo: string;
  isService: boolean;
}

export interface Payment {
  description: string;
  amount: number;
  accountNo: string;
}

export interface VatSpecification {
  vatPercent: number;
  accountNo: string;
  amount: number;
}

export interface Accounting {
  no: string;
  amount: number;
}

export interface Settlement {
  id: string;
  settlementNo: number;
  header: string;
  client: Client;
  salesLocation: SalesLocation;
  timestamp: number;
  timestampStart: number;
  timestampStop: number;
  receiptNoStart: number;
  receiptNoStop: number;
  discountAmount: number;
  discountCount: number;
  noOfInterruptedReceipts: number;
  noOfCorrectionReceipts: number;
  netAmount: number;
  vatAmount: number;
  totalAmount: number;
  noOfSoldGoods: number;
  noOfSoldServices: number;
  noOfReceipts: number;
  noOfProforma: number;
  accountingBalance: number;
  grandRefundTotalAmount: number;
  grandSalesNetAmount: number;
  grandSalesTotalAmount: number;
  lines: Line[];
  payments: Payment[];
  vatSpecifications: VatSpecification[];
  accountings: Accounting[];
}
