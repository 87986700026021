import { Component, OnInit } from "@angular/core";
import {
  PaymentOptionAccounting,
  PaymentOptionAccountingService
} from "../../../services/payment-option-accounting.service";
import { ToastController, AlertController } from "@ionic/angular";
import { LanguagePipe } from "../../../pipes/language.pipe";
import { LanguageProvider } from "../../../services/language/language.provider";

@Component({
  selector: "app-payment-option-accounting",
  templateUrl: "./payment-option-accounting.component.html",
  styleUrls: ["./payment-option-accounting.component.scss"]
})
export class PaymentOptionAccountingComponent implements OnInit {
  paymentOptionAccountings: PaymentOptionAccounting[];
  loading;
  selectedSegment: string;
  availablePaymentOptions: PaymentOptionAccounting[];
  constructor(
    private paymentOptionAccountingService: PaymentOptionAccountingService,
    private toastController: ToastController,
    private alertController: AlertController,
    private languagePipe:LanguagePipe,
    public languageProvider:LanguageProvider
  ) {
    this.loading = true;
    this.paymentOptionAccountingService.paymentOptionAccoutings.subscribe(x => {
      this.paymentOptionAccountings = x;
      this.loading = false;
    });
    this.paymentOptionAccountingService
      .getAvailablePaymentOptions()
      .subscribe(x => {
        this.availablePaymentOptions = x;
      });
  }
  async removePaymentOption(paymentOption: PaymentOptionAccounting) {
    let alert = await this.alertController.create({
      header: this.languagePipe.transform(`Ta bort ${paymentOption.name}?`,'REMOVE',this.languageProvider.currentLanguage,[paymentOption.name]),
      message: this.languagePipe.transform("Är du säker?",'ARE_YOU_SURE',this.languageProvider.currentLanguage),
      buttons: [
        {
          role: "cancel",
          text: this.languagePipe.transform("Avbryt",'CANCEL',this.languageProvider.currentLanguage)
        },
        { role: "confirm", text: this.languagePipe.transform("Ok",'OK',this.languageProvider.currentLanguage) }
      ]
    });
    alert.present();

    let result = await alert.onDidDismiss();
    if (result.role === "confirm") {
      let existingPaymentOption = this.paymentOptionAccountings.filter(x => x.name === paymentOption.name)[0];
      this.paymentOptionAccountingService.removePaymentOption(existingPaymentOption);
    }
  }
  async addPaymentOption(paymentOption: PaymentOptionAccounting) {
    await this.paymentOptionAccountingService.addPaymentOption(paymentOption);
    var toast = await this.toastController.create({
      color: "success",
      message: `\uD83D\uDC4D ${this.languagePipe.transform(`Lade till ${paymentOption.name}`,'ADDED',this.languageProvider.currentLanguage,[paymentOption.name]) }`,
      duration: 1000,
      position: "top"
    });
    toast.present();
  }
  async saveAccount(paymentOption: PaymentOptionAccounting) {
    this.paymentOptionAccountingService.updateArticleAccounting(paymentOption);
    var toast = await this.toastController.create({
      color: "success",
      message: `\uD83D\uDC4D ${this.languagePipe.transform('Sparat','SAVED',this.languageProvider.currentLanguage)}`,
      duration: 500,
      position: "top"
    });
    toast.present();  
  }
  existsInAccount(paymentOption: PaymentOptionAccounting) {
    let exists = this.paymentOptionAccountings.filter(
      x => x.name === paymentOption.name
    );
    return exists.length > 0;
  }
  onAccountSelected($event, account) {
    if($event === account.accountNo) return;
    account.accountNo = $event;
    this.saveAccount(account);
  }
  onAccountFeeSelected($event,account){
    if($event === account.accountNo) return;
    account.fee.accountNo = $event;
    this.saveAccount(account);
  }
  segmentChanged($event) { }

  ngOnInit() {
    this.selectedSegment = "paymentOptions";
  }
}
