import { Pipe, PipeTransform, Inject, LOCALE_ID } from "@angular/core";

@Pipe({ name: 'displaycurrency' })
export class DisplayCurrencyPipe implements PipeTransform {

    constructor(@Inject(LOCALE_ID) private locale: string) {

    }
    transform(value: any): any {
        if (!value)
            value = 0;
        if (typeof value === "string")
            value = parseFloat(value);
        if (value !== 0)
            value = value.toFixed(2);
        switch (this.locale) {
            case "se-SE":
                return `${value} kr`
            default:
                return value;
        }
    }

}