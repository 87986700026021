import { Directive, ElementRef, Inject, HostListener, Renderer2 } from '@angular/core';
@Directive({
    selector: '[jo-selectall]'
})
export class SelectAllDirective {
    constructor(@Inject(ElementRef) private element: ElementRef, private renderer: Renderer2) {

    }
    ngOnInit() {
        // this.renderer.invokeElementMethod(this.element.nativeElement, 'focus', []);
        // setTimeout(() => {
        //     this.renderer.invokeElementMethod(this.element.nativeElement, 'select', []);
        // }, 0)

    }
    @HostListener('click', ['$event']) onClick(e) {
        e.path[0].select();
    }

}