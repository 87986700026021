export const lang = {
    get: (string, parameters?) => {
        switch (string) {
            case 'CARD_ADDED_FOR': return `Kort tillagt hos ${parameters[0]}`;
            case 'REMOVE_NAME': return `Ta bort ${parameters[0]}?`;
            case 'REMOVED_IMAGE': return `Tog bort ${parameters[0]}`
            case 'PRODUCTS_UPDATED': return `${parameters[0]} produkter uppdaterade`
            case 'SAVING_MENU': return `Sparar meny för ${parameters[0]}`
            case 'REMOVE_MENU': return `Ta bort ${parameters[0]}?`;
            case 'SAVING_GALLERY': return `Sparar galleri för ${parameters[0]}`;
            case 'REMOVED_IMAGE': return `\uD83D\uDE04 Tog bort ${parameters[0]}`;
            case 'NUMBER_ARTICLES': return `${parameters[0]}  artiklar`;
            case 'UPDATE_ITEM': return `Uppdaterade ${parameters[0]}`;
            case 'PRODUCT_ADDED': return `\uD83D\uDE04 ${parameters[0]} tillagd`;
            case 'PRODUCT_REMOVED': return `\uD83D\uDE04 ${parameters[0]} borttagen`;
            case 'REMOVE_PRODUCT_CATEGORY': return `Vill du ta bort <strong>${parameters[0]}</strong>?`;
            case 'REMOVE_BONG_CATEGORY': return `Vill du ta bort <strong>${parameters[0]}</strong>?`;
            case 'SHARE_NEWS_WITH': return `Delar nyheten med ${parameters[0]}`;
            case 'NEWS_SHARED': return `Delade ${parameters[0] ? parameters[0] : 'nyheten'}`
            case 'TIMELINE_SAVED_FOR': return `Sparar tidslinjen för ${parameters[0]}`;
            case 'SHARING_OFFER_WITH': return `Delar erbjudandet med ${parameters[0]}`;
            case 'OFFER_SHARED': return `Delade ${parameters[0]}`;
            case 'REMOVE_COUPON': return `Ta bort ${parameters[0]}?`;
            case 'LINKED_PRODUCT': return `Länkade ${parameters[0]}`;
            case 'COPY_FROM': return `Kopiera från ${parameters[0]}?`
            case 'REMOVE': return `Ta bort ${parameters[0]}?`;
            case 'REMOVING': return `Tar bort ${parameters[0]}?`;
            case 'ADDED': return `Lade till ${parameters[0]}`;
            case 'PRODUCT_SAVED': return `${parameters[0]} sparades`;
            case 'SAVING_PRODUCT': return `Sparar produkt för ${parameters[0]}`;
            case 'CONFIRM_REMOVE_ACCOUNTING': return `Är du säker på att du vill ta bort ${parameters[0]}?`;
            case 'REMOVE_ACCOUNTING': return `${parameters[0]} borttagen`;
            case 'SAVED': return `Sparade ${parameters[0]}`;
            case 'RECEIPTS_AMOUNT': return `Totalt: ${parameters[0]} kvitton & ${parameters[1]}`;
            default: return '';
        }
    },
    ALCOHOL_STRINGS: ["vin", "shot", "drink", "sprit", "cognac", "öl", "cider", "whisk", "whiskey", "whisky", "rom", "likör", "vodka", "limoncello", "alkoläsk"],
    AMOUNT_MIN_MOBILE_WALLET : 'Minimibelopp',
    AMOUNT_1_MOBILE_WALLET : 'Belopp 1',
    AMOUNT_2_MOBILE_WALLET : 'Belopp 2',
    STRINGS: ["Öl", "Vin", "Alkoholfritt", "Mat", "Cider", "Drink", "Sprit", "Dricks", "Presentkort"],
    DRINK_LIST: ["dricka", "dryck"],
    WEEL_LIST: ["Sön", "Mån", "Tis", "Ons", "Tors", "Fre", "Lör"],
    CHOSE_PRINTER: 'Välj skrivare',
    SAVE_AS_PDF: 'Spara som PDF',
    MY_ACCOUNT: 'Mitt konto',
    USER_ID: 'Användareid',
    LOG_OUT: 'Logga ut',
    HANDLE_PERSONNELCARD: 'Hantera personalkort',
    CREATE_NEW_CARD: 'Skapa nytt kort',
    PERSONNELCARD: 'Personalkort',
    NAME: 'Namn',
    TEXT_ON_CARD: 'Text på kort',
    AVAILABLE_THROUGH: 'Giltigt till',
    URL_TO_BACKGROUND: 'URL till bakgrund',
    REMOVE: 'Ta bort',
    SAVE: 'Spara',
    SEARCH: 'Sök',
    USER_WITH_CARD: 'Användare med kort',
    ID: 'Id',
    CARD: 'Kort',
    JANUARY: 'Januari',
    FEBRUARY: 'Februari',
    MARS: 'Mars',
    APRIL: 'April',
    MAY: 'Maj',
    JUNE: 'Juni',
    JULY: 'Juli',
    AUGUST: 'Augusti',
    SEPTEMBER: 'September',
    OCTOBER: 'Oktober',
    NOVEMBER: 'November',
    DECEMBER: 'December',
    JAN3: 'Jan',
    FEB3: 'Feb',
    MAR3: 'Mar',
    APR3: 'Apr',
    MAY3: 'Maj',
    JUN3: 'Jun',
    JUL3: 'Jul',
    AUG3: 'Aug',
    SEP3: 'Sep',
    OCT3: 'Okt',
    NOV3: 'Nov',
    DEC3: 'Dec',
    OK: 'OK',
    CANCEL: 'Avbryt',
    SORTED_ON_PRODUCT: 'Sorterat på produkt',
    DAY: 'Dag',
    TOTAL: 'Totalt',
    ORDERS_TODAY: 'Ordrar idag',
    ORDERS_THIS_MONTH: 'Ordrar denna månad',
    ACTIVITY: 'Aktivitet',
    COUPONS_IN_APP: 'Kuponger använda i appen',
    PER_PRODUCT: 'Per produkt',
    USERS_WITH_CODES: 'Användare med rabattkoder',
    RECEIPTNO: 'Kvittonr',
    TIME: 'Tid',
    CODE: 'Kod',
    ORDERCODE: 'Orderkod',
    NAVIGATE_HERE: 'Navigera här eller använd menyn i ovankant',
    UPLOADING: 'Laddar upp',
    UPLOAD: 'Ladda upp...',
    GALLERY: 'Galleri',
    ARE_YOU_SURE: 'Är du säker?',
    UPDATING_IMAGE_QUICK: 'Vänta... vi uppdaterar din bild en snabbis',
    SYNC_PRODUCTS: 'Synka produkter',
    CLEAR_EXISTING_PROUCTS: 'Rensa existerande produkter?',
    UPDATING_PRODUCTS: 'Uppdaterar produkter',
    PRODUCT_PAGE_VERIFY: 'Gå till produktsidan för att verifiera',
    FROM: 'Från',
    TO: 'Till',
    ORDERVALUE_COUPON: 'Ordervärde med kupongkod',
    SUM_RECEPITS_COUPON: 'Summan av kvitton där gästen handlat med kupongkod',
    CODE_CAPS: 'KOD',
    DISCOUNT_CAPS: 'RABATT',
    NETTO_CAPS: 'NETTO',
    VAT_CAPS: 'MOMS',
    TOTAL_CAPS: 'TOTALT',
    TODAY_CAPS: 'IDAG',
    CLOSE_CAPS: 'STÄNG',
    RESET_FORTNOX: 'Återställ Fortnox-koppling?',
    RESET_FORTNOX_TEXT: 'Är du säker att du vill ta bort Fortnox-kopplingen och återställa så att det går att skapa en ny koppling?',
    NO: 'Nej',
    YES: 'Ja',
    AUTHORIZATIONCODE: 'Auktoriseringskod',
    AUTHORIZED: 'AUKTORISERAD',
    VOUCHER_SERIES: 'Verifikationsserie',
    ROUNDING_ACCOUNT: 'Konto för öresutjämning',
    ACTIVATE: 'Aktivera',
    INTEGRATIONS: 'Integrationer',
    PASSWORD: 'Lösenord',
    FORWARD: 'Vidare',
    FORGOT_PASSWORD: 'Glömt lösenord?',
    RESET_EMAIL_HEAD: 'Email för återställande',
    RESET_EMAIL_TXT: 'Fyll i din email',
    NEW_MENU: 'Ny meny',
    COPY: 'kopia',
    SURE: 'Är du säker?',
    MENU_SHARED: 'Delade menyn!',
    SOMETHING_WRONG: 'Något gick fel!',
    ACTIVATE_BEFORE_REMOVE: 'Du måste aktivera en annan meny innan du kan ta bort denna',
    REMOVE_FOR_ALL: 'Ta bort för alla?',
    REMOVE_EVEN_OTHERS: 'Vill du även ta bort inlägget om det existerar på andra restaurangenheter?',
    REMOVED_FROM_ALL: 'Tog bort från alla restauranger',
    MONDAY: 'Måndag',
    TUESDAY: 'Tisdag',
    WEDNESDAY: 'Onsdag',
    THURSDAY: 'Torsdag',
    FRIDAY: 'Fredag',
    SATURDAY: 'Lördag',
    SUNDAY: 'Söndag',
    FAILED: 'Misslyckades',
    SAVED: 'Sparat!',
    MENUS: 'Menyer',
    VISIBLE_IN_APP: 'Synlig i appen',
    WEEKLY_MENU: 'Veckomeny',
    SHOW_BUTTON: "Visa knappen 'Veckomeny' på appens startsida. Uppdatera texten varje vecka så att användare ser vad som serveras",
    UPLOAD_PROGRESS: 'Laddar upp:',
    GALLERY_SHARED: 'Delade galleriet!',
    USE_IMAGE: 'Använd bild',
    UPDATING_IMAGE: 'Vänta... vi uppdaterar din bild en snabbis',
    COMPRESSING: 'Komprimerar',
    HELP: 'Hjälp',
    IN_STOCK: 'I lager',
    OUT_OF_STOCK: 'Slut',
    CATEGORIES: 'Kategorier',
    FOOD: 'Mat',
    PRODUCTS: 'Produkter',
    ADD: 'Lägg till',
    UPDATE: 'Uppdatera',
    CONFIRM: 'Ok',
    PRODUCT_CATEGORIES: 'Produktkategorier',
    BONG_CATEGORIES: 'Bongkategorier',
    NEW_PRODUCT_CATEGORY: 'Ny Produktkategori',
    REMOVE_PRODUCT_CATEGORY: 'Ta bort produktkategori!',
    EDIT_PRODUCT_CATEGORY: 'Ändra Produktkategori',
    NEW_BONG_CATEGORY: 'Ny Bongkategori',
    REMOVE_BONG_CATEGORY: 'Ta bort bongkategori!',
    EDIT_BONG_CATEGORY: 'Ändra Bongkategori',
    TIMELINE: 'Tidslinje',
    NEW_POST: 'Ny post',
    SHARE: 'Dela',
    CHOOSE_IMAGE: 'Välj bild...',
    TITLE: 'Titel',
    TEXT: 'Text',
    OFFER: 'Erbjudande',
    PRODUCT: 'Produkt',
    URL: 'Url',
    STATIC: 'Statisk',
    APP_TAB: 'Tab i appen som produkten ligger under',
    OFFER_INFO: 'Info om erbjudande',
    WARNING: 'Varning',
    ALREADY_EDITING: 'Du redigerar redan en annan post. Är du säker på att du vill förlora data?',
    ABORT_Q: 'Avbryt?',
    CONFIRM_CHANGES: 'Är du säker på att du vill avbryta? Ändringar kommer att gå förlorade',
    FILL_ALL_FIELDS: 'Du måste fylla i alla fält förutom titel och text',
    SAVE_NEWS: 'Sparar nyheter',
    MAIN_TEXT: 'Huvudtext',
    NEW_TITLE: 'Ny titel',
    REMOVE_POST: 'Ta bort inlägg?',
    REMOVE_POST_CONFIRM: 'Är du säker på att du vill ta bort inlägget?',
    REMOVE_FOR_OTHERS: 'Vill du även ta bort inlägget om det existerar på andra restaurangenheter?',
    TIMELINE_SHARED: 'Delade tidslinjen!',
    TYPE: 'Typ',
    SERVING_METHOD: 'Serveringssätt',
    APPLIES_FOR: 'Gäller för',
    PRODUCT_TYPES: 'Produkttyper',
    DISCOUNT_PERCENTAGE: 'Procent i rabatt',
    AUTOMATIC_ACTIVATION: 'Aktivera automatiskt för användare',
    CREATING_OFFER: 'Skapar erbjudandet',
    OFFER_CREATED: 'Erbjudande skapat!',
    UPDATING_OFFER: 'Uppdaterar erbjudandet',
    OFFER_UPDATED: 'Uppdaterade erbjudandet',
    REMOVING: 'Tar bort',
    OFFER_REMOVED: 'Tog bort erbjudandet',
    NEW_BUTTON: 'Ny knapp',
    CREATE_NEW_BUTTON: 'Skapa ny knapp',
    CONNECT_BUTTON: 'Vad ska knappen vara kopplad till?',
    CHOOSE_BG_IMAGE: 'Välj bakgrundsbild...',
    BUTTON_LAYER: 'Knapplager',
    FREE_TEXT: 'Fritext',
    ACTIVATE_IN_POS: 'AKTIVERA I KASSAN',
    ALLERGY_INFO_SAVED: 'Allergi-info sparad och syns i appen',
    MINIMUM_BONUS_SAVED: 'Minimum bonus sparad',
    PAYMENT_OPTIONS_SAVED: 'Betalalternativ sparade',
    DELIVERY_OPTIONS_SAVED: 'Serveringsinställningar sparade',
    NOTIFICATION_SAVED: 'Notis sparad',
    INSTAGRAM_SAVED: 'Instagram sparat',
    FACEBOOK_SAVED: 'Facebook sparat',
    MENU_STYLE_SAVED: 'Menyutseende sparat',
    FOOD_SETTINGS_SAVED: 'Matinställningar sparade',
    DRINK_SETTINGS_SAVED: 'Dryckinställningar sparade',
    DISCOUNT_SETTINGS_SAVED: 'Rabattinställningar sparade',
    COUPON_NAME: 'Kupongens namn',
    PERCENTAGE_IN_DISOUNT: 'Antal procent i rabatt',
    TAKEAWAY: 'Takeaway',
    TABLE_SERVING: 'Bordsservering',
    COLLECT_IN_BAR: 'Hämta i baren',
    DELIVERY: 'Utkörning',
    SENDING_PUSH: 'Skickar pushnotis',
    PUSH_SENT: 'Push skickad!',
    GENERAL: 'Generellt',
    GENERATE_REPORT: 'Generera rapport',
    PUSH_NOTIFICATIONS: 'Pushnotiser',
    OFFERS: 'Erbjudanden',
    DELIVERY_OPTIONS: 'Serveringsalternativ',
    PAYMENT_OPTIONS: 'Betalalternativ',
    USE_SAVE_BUTTON: 'Ändringar på denna sida påverkar mobilappen och "Spara"-knapparna måste användas för att ändringarna ska slå igenom',
    OPEN_FOR_SALE: 'Öppen för försäljning',
    MESSAGE_WHEN_CLOSED: 'Meddelande till kund när tabben är stängd',
    FOOD_TAB: 'Mat-tabben',
    DRINK_TAB: 'Dryck-tabben',
    NOTIFICATION_IN_APP: 'Notis i appen',
    SHOW_NOTIFICATION: 'Visa notis',
    NOTIFICATION_TEXT: 'Text för notis',
    ALLERGY_INFO: 'Allergi-info',
    PHONE_NUMBER: 'Telefonnummer',
    OPENING: 'Öppnar klockan',
    CLOSING: 'Stänger klockan',
    SHOW_FACEBOOK: 'Visa Facebook-kort på startsidan',
    INSTAGRAM: 'Instagram',
    SHOW_INSTAGRAM: 'Visa Instagram-kort på startsidan',
    WHICH_ACTIVE: 'Vilka ska vara aktiverade i appen?',
    SWISH: 'Swish',
    TAB: 'Bordsnota',
    TABLENUMBER_FROM: 'Bordsnummer från',
    TABLENUMBER_TO: 'Bordsnummer till',
    AREA_1: 'Område 1',
    AREA_2: 'Område 2',
    AREA_3: 'Område 3',
    AREA_4: 'Område 4',
    DELIVERY_RADIUS: 'Utkörningsradie i meter',
    DELIVERY_RADIUS_COST: 'Pris för utkörningsradie',
    BONUSSYSTEM: 'Bonussystem',
    BONUS: 'Bonus',
    LEAST_AMOUNT_BONUS: 'Minsta antal poäng för att handla med poäng',
    GLOBAL_OFFERS: 'Globala erbjudanden',
    APPLIES_TO_ALL: 'Gäller för alla i appen',
    ONETIME: 'Engångs',
    USER_GROUPS: 'Användargrupper',
    ADD_NEW: 'Lägg till ny',
    GROUP_NAME: 'Gruppnamn',
    DISCOUNT_CODES: 'Rabattkoder',
    DISCOUNTPERCENT: 'Rabattprocent',
    APPLY_AUTOMATICALLY: 'Applicera rabattkod automatiskt för kunderna i appen',
    SEND_PUSH: 'Skicka push',
    SEND_PUSH_TEXT: 'Meddelandet går genast ut till alla lokala användare',
    SEND: 'Skicka',
    ALTERNATIVES: 'Alternativ',
    HEADER_EX: 'Rubrik (t.ex Stekgrad)',
    OPTION_TYPE: 'Typ av tillval',
    CHECKBOX: 'Checkruta',
    MULTIPLE: 'Flerval',
    PRICE: 'Pris',
    OPTIONS: 'Tillval',
    COPY_FROM: 'Kopiera från',
    USING_STEP_BY_STEP: 'Använder Steg-för-steg-flödet',
    HEADER: 'Rubrik',
    SUBHEADER: 'Underrubrik',
    LINK_WITH: 'Länkad med',
    EDIT_EXCEPTION: 'Ändra undantag',
    REASON: 'Anledning',
    REASON_DETAILS: 'Anledningsdetaljer',
    ACCOUNTING: 'Kontering',
    SEARCH_PRODUCT: 'Sök produkt',
    DELIVERY_TYPES: 'Leveranssätt',
    REMOVE_EXCEPTION_CONFIRM: 'Vill du ta bort undantaget?',
    REMOVE_EXCEPTION: 'Ta bort undantag!',
    SAVE_WITHOUT_ACCOUNTING: 'Du kan inte spara produkter utan bokföringsinformation och produkttyp',
    REMOVE_PRODUCT_RESTURANTS: 'Vill du ta bort produkten på fler restauranger?',
    PRODUCT_SHARED: 'Delade produkten!',
    PRODUCT_INFORMATION: 'Produktinformation',
    BASIC_INFO: 'Grundinformation',
    ACTIVE: 'Aktiv',
    PRODUCT_ACTIVE: 'Produkt Aktiv',
    PRICE_KR: 'Pris i',
    BONUS_CONVERT: 'Procent som konverteras till bonuspoäng vid köp av produkt',
    BONUS_CONVERT_WALLET:'Procent som konverteras till bonuspoäng vid påfyllning av mobile wallet',
    NOT_ALLOWED_TO_BY_WITH_BONUS: 'Får inte köpas för bonuspoäng',
    DESCRIPTION_SHORT: 'Beskrivning (kort)',
    DESCRIPTION_LONG: 'Beskrivning (lång)',
    CATEGORY_BONG_INFO: 'Kategori- & bonginformation',
    NAME_ON_BONG: 'Namn på bång (valfri)',
    PRODUCT_TYPE: 'Produkttyp',
    PRODUCT_CATEGORY: 'Produktkategori',
    BONG_CATEGORY: 'Bongkategori',
    ACCOUNTING_VAT: 'Bokföring & moms',
    EXCEPTION: 'Undantag',
    CONTAINS_ALCOHOL: 'Produkten innehåller alkohol',
    AGE_LIMIT: '18+ åldersgräns',
    PURCHASE: 'Inköp',
    COST: 'Kostnad',
    MARGIN: 'Marginal',
    TAGS: 'Taggar',
    NEW_TAG: 'Ny tagg',
    EXTERNAL_ID: 'Externt ID',
    STOCK_BALANCE: 'Lagersaldo',
    COUNT: 'Antal',
    ACOUNTINGS: 'Konteringar',
    VAT_RATE: 'Momssats',
    VAT_RATES: 'Momssatser',
    ACCOUNTING_ACCOUNT: 'Bokföringskonto',
    NO_ACCOUNT: 'Inget konto',
    ACCOUNTING_CREATED: 'Kontering skapad',
    PAYMENT_TYPES: 'Betalsätt',
    ACTIVATED_PAYMENT_TYPES: 'Aktiverade betalsätt',
    ADITIONAL_PAYMENT_TYPES: 'Fler betalsätt',
    NO_PAYMENT_TYPES: "har inga betalsätt aktiverade. Tryck på 'Fler betalsätt' för att aktivera några",
    ADDED: 'Tillagd',
    PAYMENT_FEES: 'En del betalsätt är förknippade med avgifter. Här väljer du konton för dessa',
    VAT: 'Moms',
    HOME: 'Hem',
    REPORTS: 'Rapporter',
    ADMIN: 'Administration',
    NEWS: 'Nyhetsflöde',
    APP: 'App',
    POS: 'Kassa',
    STAFF_CARD: 'Personalkort',
    MISC: 'Misc.',
    SETTINGS: 'Inställningar',
    RESTURANT_INFO: 'Restauranginformation',
    USERS: 'Användare',
    SEE_ALL: 'Se alla...',
    STATISTICS: 'Statistik',
    DARK_MODE: 'Mörkt läge',
    SELECT_UNIT: 'Välj enhet',
    OPENING_H_SAVED: 'Öppettider sparade',
    OPENING_H: 'Öppettider',
    CLOSED: 'Stängt',
    OPENS: 'Öppnar',
    CLOSES: 'Stänger',
    DEVIATIONS: 'Avvikelser',
    SELECT_LOGO: 'Välj logga..',
    ORG_NO: 'Organisationsnummer',
    ADRESS: 'Adress',
    ZIP_CODE: 'Postnummer',
    POST_ADR: 'Postadress',
    PHONE: 'Telefon',
    EMAIL: 'Email',
    ABOUT_US: 'Om oss',
    ADD_USER: 'Lägg till användare',
    ADD_DOMAIN_AUTO: 'Lägg till användardomäner som automatiskt läggs till i denna användargrupp vid registrering',
    DOMAIN_EXAMPLE: "(t.ex endast 'pubq.se' inte '@pubq.se' )",
    LOAD: 'Ladda',
    CHOOSE:'Välj...',
    LINK_TO_PRODUCT:'Länka till produkt eller hemsida',
    START_WITH_HTTPS:'måste börja med https://',
    WHERE_IS_PRODUCT:'Var finns produkten?',
    OFFER_INFO_PLACEHODLER:'Erbjudandets informationstext...',
    OPTION_DESCRIPTION:'Beskrivning av erbjudandet',
    SELECT:'Välj',
    DISCOUNT_PERCENTAGE_PLACEHOLDER:'Antal procent användaren får i rabatt',
    WRITE:'Skriv...',
    SEARCH_PRODUCT_OR_TAG:'Sök produkt eller tagg',
    STEP_PLACEHOLDER:'Steg i bygg din rätt-flödet',
    ABOUT_US_PLACEHOLDER:'Om oss',
    DOMAIN_EX:'Domän t.ex pubq.se',
    TIME_CONTROL_MENU:'Tidsstyr meny',
    SET:'Ställ in',
    SCHEDULE:'Schema',
    ACTIVATION_TIME:'Aktiveringstid',
    ACTIVATE_MENU:'Aktivera meny',
    WEEK:'Vecka',
    SELECT_Z_REPORT:'Välj Z-rapporter',
    FROM_TO:'Från - Till',
    SEND_END_TO_FORTNOX:"Skicka detta dagsavslut till Fortnox?",
    IMPORT_SUCCESSFUL:'Importen lyckades',
    IMPORT_FAILED:'Importen misslyckades',
    WHICH_EMAIL:'Vilken emailadress ska filen skickas till?',
    SUMD_Z_REPORTS:'Summerade Z-rapporter',
    Z_REPORT:'Z-Rapporter',
    MAIL:'Maila',
    PREVIOUS_MONTH:'Föregående månad',
    COMPILATION:'Sammanställning',
    JOURNALNR:'Journalnr',
    CREATED:'Skapat',
    RECEIPTNO_FROM_TO:'Kvittonr från-till',
    SUM:'Summa',
    INTEGRATIONS_HEADER:'Integrationer',
    BUY_REBUY:'Köp & återköp',
    WRONG_HIT:'Felslag',
    CANCELD:'Avbrutna',
    PROFORMA:'Proforma',
    CREATING_ENDOFDAY:'Skapar dagsavslut',
    NOT_ENOUGH:'Du har inte omsatt tillräckligt mycket',
    END_OF_DAY_100:'Dagsavslutet måste innehålla mer än 100',
    Z_ENDOFDAY:'Z Dagsavslut',
    PREL_X_REPORT:'Preliminär X rapport',
    PAID:'Betalt',
    SUM_PAYMENTS:'Summa alla betalsätt',
    CHANGE_IN:'Växel IN',
    CHANGE_OUT:'Växel UT',
    ACCOUNTING_REPORTS:'Kontering till redovisningen',
    STARTED:'Påbörjad',
    FINISHED:'Avslutad',
    ACCOUNTS:'Konton',
    ROWS:'Rader',
    SALES:'Försäljning',
    PAYMENTS:'Betalsätt',
    RECEIPTS:'Kvitton',
    ACCOUNTING_ACCOUNTS:'Bokföringskonton',
    TODAY:'Idag',
    YESTERDAY:'Igår',
    LAST_WEEK:'Förra veckan',
    THIS_MONTH:'Denna månad',
    LAST_MONTH:'Förra månaden',
    ORDERS:'Ordrar',
    END_OF_DAY:'Dagsavslut',
    HISTORY:'Historik',
    NAME_AND_COUPON:'Du måste fylla i namn och välja kupong',
    DELIVERY_SET_LOCATIONS:'Utkörning till leveransplats',
    NEW_DELIVERY_LOCATION:'Ny utkörningsplats',
    DELIVERY_LOCATIONS:'Utkörningsplatser',
    DISCOUNT_CODE:'Rabattkod',
    MINIMUM_FEE:'Minimiavgift',
    EDIT_LOCATIONS:'Editera platser',
    EDIT:'Editera',
    TIP_REPORT: 'Dricksrapport',
    DESC_GENERATE_REPORT: 'Rapporten genereras till en pdf-fil som automatiskt kommer att laddas ner.',
    GENERATING_REPORT: 'Genererar rapporten',
    ERROR_TIP_REPORT: 'Något gick fel vid genereringen av dricksrapporten! Prova igen.',
    COMPANY_NAME: 'Bolagsnamn',
    COST_CENTER: "Kostnadsställe",
    OVERWRITE_POS_BUTTONS: "Du kommer att ta bort din gamla kassaknappar. Vill du fortsätta?",
    EMAIL_NOT_VALID: "Emailadressen är inte giltig",
    THIS_YEAR: "Detta år",
    THIS_WEEK: "Denna vecka"    
}