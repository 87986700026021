
import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { LanguageProvider } from '../../../services/language/language.provider';
import { UserService, User } from '../../../services/user.service';
import { appVersion } from "../../../../environments/models";

@Component({
    selector: 'account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss']
})
export class AccountMenuComponent implements OnInit {
    currentUser: User
    selectedLanguage: any;
    appVersion = appVersion;
    languages: { lang: string; code: string; }[];
    darkModeSetting = false;
    constructor(private userService: UserService,
        private router: Router,
        private popoverController: PopoverController,
        public languageProvider: LanguageProvider) {

        this.languages = this.languageProvider.getAvailableLanguages();
        this.selectedLanguage = this.languages.find(l => l.code == this.languageProvider.currentLanguage);
        this.userService.user.subscribe(x => {
            this.currentUser = x;
        })
    }
    toggleDarkTheme(ev?) {
        document.body.classList.toggle("theme-dark");
        if (ev)
            window.localStorage.setItem("theme-dark", ev.detail.checked.toString())
    }

    setLanguage(ev: any) {
        this.selectedLanguage = this.languages.find(l => l.code == ev.value);
        this.languageProvider.setLanguage(ev.value);
        this.userService.updateLanguage(ev.value);
    }

    async logout() {
        this.popoverController.dismiss();
        var result = await this.userService.signOut();
        this.router.navigateByUrl("login");
    }
    ngOnInit(): void {
        let wantsDark = window.localStorage.getItem("theme-dark");
        if (wantsDark === "true") {
            this.darkModeSetting = true;
        }
    }
}
