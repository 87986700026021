import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { AngularFireDatabase, AngularFireList, AngularFireObject } from '@angular/fire/database';
import { UserService } from './user.service';
import { map } from 'rxjs/operators';
import { OrderService } from './order.service';

@Injectable()
export class ArticleAccountingService {
    private articleAccountingsRef: AngularFireList<any>;
    private articleVatCategoriesRef: AngularFireList<any>;
    private accountsRef: AngularFireList<any>;
    articleAccountings: Observable<ArticleAccounting[]>;
    articleVatCategories: Observable<ArticleVatCategory[]>;
    accounts: Observable<any>;
    constructor(private afDatabase: AngularFireDatabase, private userService: UserService) {
        this.userService.selectedUnitObs.subscribe(x => {
            if (!x) {
                this.articleAccountingsRef = null;
                this.articleVatCategoriesRef = null;
                return;
            }
            this.accountsRef = this.afDatabase.list(
                `adminApp/clients/${x.routeName}/settings/accountingTemplates/accounts`
            );
            this.accounts = this.accountsRef.valueChanges();

            this.articleAccountingsRef = this.afDatabase.list(
                `adminApp/clients/${x.routeName}/settings/vat/articleAccountings`
            );
            this.articleVatCategoriesRef = this.afDatabase.list(
                `Categories`
            );
            this.articleAccountings = this.articleAccountingsRef
                .snapshotChanges()
                .pipe(
                    map(changes =>
                        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
                    ));
            this.articleVatCategories = this.articleVatCategoriesRef
                .snapshotChanges()
                .pipe(
                    map(changes =>
                        changes.map(c => ({
                            key: c.payload.key,
                            vatPercent: c.payload.val().moms,
                            name: c.payload.val().name,
                            hasAlcohol: c.payload.val().hasAlcohol,
                            translationId: ""
                        }))
                    ));

        });        
    }
    async saveAccounts(accounts) {
        accounts.forEach(element => {
            this.updateArticleAccounting(element);
        });
        return true;
    }
    updateArticleAccounting(articleAccounting: ArticleAccounting) {
        return this.articleAccountingsRef.update(articleAccounting.key, articleAccounting);
    }
    deleteArticleAccounting(articleAccounting: ArticleAccounting) {
        return this.articleAccountingsRef.remove(articleAccounting.key);
    }
    addArticleAccounting(articleAccounting: ArticleAccounting) {
        var obj: ArticleAccounting = {
            name: "Ny kontering",
            tax: 0,
            type: "wares",
            isUserCreated: true,
            accounts: [
                { name: "Momspliktig", accountNumber: "", property: "taxDuty" },
                { name: "Momsfri", accountNumber: "", property: "taxFree" }
            ],
            externalId:""
        };

        let key = this.articleAccountingsRef.push(obj).key;
        obj.key = key;
        return obj;
    }
}
export class ArticleAccounting {
    key?: string;
    name: string;
    tax: number;
    isUserCreated?: boolean;
    type: string;
    accounts: Account[];
    externalId:string;
}
export class Account {
    name: string;
    property: string;
    accountNumber: string;
}
export class ArticleVatCategory {
    key: string;
    hasAlcohol: boolean;
    vatPercent: number;
    name: string;
    translationId: string;
}