import { Injectable } from "@angular/core";
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot
} from "@angular/router";
import { UserService } from "../user.service";
import { MenuService } from "../menu.service";
import { UnitService } from "../unit.service";

@Injectable()
export class ContextGuard implements CanActivate {
  constructor(
    private router: Router,
    private userService: UserService,
    private unitService: UnitService,
    private menuService: MenuService
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    var promise = new Promise<boolean>((resolve, reject) => {
      if (route.params && route.params["routeName"]) {
        var routeName = route.params["routeName"];
        this.userService.user.subscribe(x => {
          if (!x) return;
          var unit = x.units.find(x => x.routeName === routeName);
          if (!this.menuService.refsSet())
            this.menuService.setupDbRefsForRoute(unit.routeName);
          if (
            !this.userService.selectedUnit ||
            (this.userService.selectedUnit &&
              this.userService.selectedUnit.routeName !== unit.routeName)
          ) {
            if (!unit.info)
              unit.info = {
                name: "",
                address: "",
                city: "",
                orgNo: "",
                zipCode: "",
                logoUrl: ""
              };
            this.userService.selectedUnit = unit;
            this.userService.selectedUnitObs.next(unit);
            this.menuService.setupDbRefsForRoute(unit.routeName);
          }

          resolve(true);
        });
      }
    });
    try {
      window["Intercom"]("update");
    }
    catch (error) {
      console.log(error);
    }
    return promise;
  }
}
