import { Injectable } from "@angular/core"
import { AngularFireAuth } from "@angular/fire/auth"
import { BehaviorSubject } from "rxjs"
import { AngularFireDatabase } from "@angular/fire/database"
import * as moment from "moment"
import { AlertController } from "@ionic/angular"
import { environment } from "../../environments/environment"
import { HttpClient } from "@angular/common/http"
import { LanguageProvider } from "./language/language.provider"

@Injectable()
export class UserService {
    user = new BehaviorSubject<User>(undefined)
    selectedUnit: Unit
    public selectedUnitObs = new BehaviorSubject<Unit>(undefined)
    userClientGroupId = "";
    hideSidebar = false;
    constructor(
        private afAuth: AngularFireAuth,
        private afDatabase: AngularFireDatabase,
        private alertController: AlertController,
        private http: HttpClient,
        private languageProvider:LanguageProvider
    ) {
        let sub = this.afAuth.user.subscribe(async (x) => {
            if (!x) this.user.next(null)
            else {
                try {
                    let units = await this.getUnits(x.uid)
                    let languageCode = await this.getLanguage(x.uid)
                    this.languageProvider.setLanguage(languageCode ? languageCode : 'en')
                    this.user.next({
                        email: x.email,
                        id: x.uid,
                        name: x.displayName,
                        units: units,
                        languageCode: languageCode ? languageCode : 'se'
                    })
                } catch (error) {
                    this.user.next(null)
                }
                window["Intercom"]("boot", {
                    app_id: "hm8td4yv",
                    name: x.displayName, // Full name
                    email: x.email, // Email address
                    created_at: moment(x.metadata.creationTime).unix(), // Signup date as a Unix timestamp
                })

                sub.unsubscribe()
            }
        })
    }


    async getUserGroups(routeName, userId){

        let body = {
            userId,
            routeName
        }

        try{
            let result = await this.http
                    .post<any>(`${environment.pubQueueApiUrl}/api/v1/userGroupsForUserId`, body)
                    .toPromise();
            return result;
        }catch(err){
            console.dir(err);
            return null;
        }
    }

    async getPurchaseHistoryItem(
        userId: string,
        key: string
      ): Promise<any> {
        let receipt = await this.afDatabase.database
          .ref(`usersPurchaseHistory_v2/${userId}/${key}`)
          .once("value");
        return receipt.val();
      }

    async getUnits(userId: string) {
        return this.afDatabase
            .object<ClientGroup>(`usersAdminApp/${userId}/clientGroups/0`)
            .query.once("value")
            .then((snapshot) => {
                var clientGroup = snapshot.val()
                this.userClientGroupId = clientGroup.id
                return clientGroup.units
            })
    }
    async getLanguage(userId: string) {
        return this.afDatabase
            .object<string>(`usersAdminApp/${userId}/languageCode`)
            .query.once("value")
            .then((snapshot) => {
                var languageCode = snapshot.val();
                return languageCode;
            })
    }
    updateUnit(unit: Unit) {
        var index = this.user.value.units.indexOf(unit)
        return this.afDatabase
            .object<Unit>(
                `usersAdminApp/${this.user.value.id}/clientGroups/0/units/${index}`
            )
            .update({
                info: unit.info,
            })
    }
    updateLanguage(languageCode: string) {
        this.afDatabase
            .object<string>(`usersAdminApp/${this.user.value.id}/languageCode`).set(languageCode)
    }

    signOut() {
        return this.afAuth.signOut()
    }
    async showUnitsSelectAlert(units: Unit[]) {
        let selectedUnit = this.selectedUnitObs.value;
        let inputs = [];
        units.forEach(x => {
            if (x.routeName === selectedUnit.routeName) return;
            inputs.push({
                name: x.routeName,
                type: "checkbox",
                label: x.info.name,
                value: x,
                checked: false
            });
        });
        const alertCheck = await this.alertController.create({
            header: 'Välj enheter',
            cssClass: "large-alert",
            backdropDismiss: false,
            inputs: inputs,
            buttons: [
                {
                    text: 'Avbryt',
                    role: 'cancel',
                    cssClass: 'secondary',
                    handler: () => {
                        console.log('Confirm Cancel');
                    }
                }, {
                    text: 'Ok',
                    role: "confirm",
                    handler: (a) => {
                        console.log('Confirm Ok');
                    }
                }
            ]
        });

        await alertCheck.present();
        let result = await alertCheck.onDidDismiss();
        if (result.role === "confirm")
            return result.data.values;
        return [];
    }
    async searchUser(value: string) {
        let type = value.startsWith("+") ? "phone" : "email";
        let searchResult = [];
        let data = {
            authType: type,
            searchValue: value
        };
        try {
            let result = await this.http
                .post<any>(`${environment.pubQueueApiUrl}/searchUser`, data)
                .toPromise();
            console.log(result);
            if (result) {
                let user = (await this.afDatabase.database.ref(`Users/${result.uid}`).once("value")).val();
                result.firstName = user.firstName;
                result.lastName = user.lastName;
                searchResult.push(result);
            }

            return searchResult;
        } catch (error) {
            throw error;
        }
    }
}
export class User {
    name: string
    id: string
    email: string
    units: Unit[]
    languageCode: string
}
export class Unit {
    routeName: string
    info: UnitInfo
    franchiseId?: string;
}
export class UnitInfo {
    name: string
    orgNo: string
    address: string
    zipCode: string
    city: string
    logoUrl: string
    blurHash?: string
}
export class ClientGroup {
    id: string
    units: Unit[]
}
